import { authedInstance, createVersionedInstance, instance } from ".";
import config from "../../config";
import i18n from "../../i18n";

export async function login(payload) {
  const res = await instance.post("/v1/auth/saisha/login", payload);
  console.log("Login res: ", res.data, instance.getUri());
  authedInstance.defaults.headers.Authorization =
    "Bearer " + res.data.access_token;
  return res.data;
}

export async function logout(refreshToken) {
  const res = await authedInstance.post("/v1/auth/saisha/logout", {
    refresh_token: refreshToken,
  });
  return res.data;
}

export async function uploadImageToS3(url, buffer) {
  try {
    const response = await instance.put(url, buffer, {
      headers: {
        "content-type": "",
      },
    });
    return response;
  } catch (e) {
    console.error("Failure to upload image to s3");
    console.error(e.message);
  }
}

export async function getPresignedURL(isPublic, imgFormat) {
  const response = await instance.post("v1/common/presignedUrl/", {
    category: "USEFUL_INFO",
    MIMEType: "image",
  });
  return response.data;
}

export async function register(payload) {
  const res = await instance.post("/v1/auth/saisha/register", payload);
  console.log("Register res: ", res.data, instance.getUri());
  return res.data;
}

export async function forgetPassword(payload) {
  const res = await instance.post("/v1/auth/saisha/forget-password", payload);
  console.log("forgetPassword res: ", res.data, instance.getUri());
  return res.data;
}

export async function resetPassword(payload, version = config.APPLICATION_VERSION) {
  const versionedInstance = createVersionedInstance(version);
  const res = await versionedInstance.post("/v1/auth/saisha/reset-password", payload);
  console.log("resetPassword res: ", res.data, instance.getUri());
  return res.data;
}

export async function genOtp(email) {
  const res = await instance.get(
    "/v1/user/getOtp?" + new URLSearchParams({ email }).toString()
  );
  console.log("genOtp res: ", res.data, instance.getUri());
  return res.data;
}

export async function verifyOtp(payload) {
  const res = await instance.post("/v1/user/verifiyEmailOtp", payload);
  console.log("verifyOtp res: ", res.data, instance.getUri());
  return res.data;
}

export async function refreshToken(payload) {
  const res = await instance.post("/v1/auth/saisha/login/refresh", payload);
  console.log("refreshToken res: ", res.data, instance.getUri());
  authedInstance.defaults.headers.Authorization =
    "Bearer " + res.data.access_token;
  return res.data;
}

// NOTE: If no further ambiguity found, can merge with refreshToken above
export async function refreshTokenUnderMultiAccount(payload, sessionId) {
  console.log("refreshTokenUnderMultiAccount: ", payload, sessionId);
  const res = await instance.post("/v1/auth/saisha/login/refresh", payload, { sessionId });
  console.log("refreshToken res: ", res.data, instance.getUri());
  authedInstance.defaults.headers.Authorization =
    "Bearer " + res.data.access_token;
  return res.data;
}

export async function fetchRefreshToken(payload) {
  const res = await instance.post("/v1/auth/saisha/login/refresh", payload);
  console.log("refreshToken res: ", res.data, instance.getUri());
  return res.data;
}

export async function kioskOtpLogin(otp) {
  const payload = {
    accountType: "email",
    otp: otp ?? "",
    lang: i18n.language ?? "en",
    kiosk_id: config.KIOSK_ID,
    platform: "kiosk",
  };
  const res = await instance.post("/v1/auth/saisha/login", payload);
  console.log("Login res: ", res.data, instance.getUri());
  authedInstance.defaults.headers.Authorization =
    "Bearer " + res.data.access_token;
  return res.data;
}

export async function kioskEmailLogin(email, password) {
  const payload = {
    accountType: "email",
    email: email ?? "",
    password: password ?? "",
    lang: i18n.language ?? "en",
    kiosk_id: config.KIOSK_ID,
    platform: "kiosk",
  };
  const res = await instance.post("/v1/auth/saisha/login", payload);
  console.log("Login res: ", res.data, instance.getUri());
  authedInstance.defaults.headers.Authorization =
    "Bearer " + res.data.access_token;
  return res.data;
}

export async function requestKioskOtp() {
  console.log("requestKioskOtp req: ", authedInstance.defaults.headers);
  const res = await authedInstance.get("/v1/app/kiosk/getAppKioskOtp");
  console.log("requestKioskOtp res: ", res.data, authedInstance.getUri());
  return res.data;
}

export async function validateSession() {
  const res = await authedInstance.get("/v1/app/session/validate");
  console.log("validateSession res: ", res.data, authedInstance.getUri());
  return res.data;
}

export async function validateSessionWithToken(access_token) {
  const res = await instance.get("/v1/app/session/validate", {
    headers: {
      Authorization: "Bearer " + access_token,
    },
  });
  console.log("validateSession res: ", res.data, instance.getUri());
  return res.data;
}

export const ChangePassword = async (payload) => {
  const res = await authedInstance.put(
    "/v1/auth/saisha/change-password",
    payload
  );
  console.log("validateSession res: ", res.data, authedInstance.getUri());
  return res.data;
};

export async function validateLoginInfo(payload) {
  const res = await authedInstance.post(`/v1/app/validate/login-info`, payload);
  console.log("validate login info res: ", res);
  return res.data;
}
