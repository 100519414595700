import Joi from "joi";

export type T_GolfListAPIDataArray = Array<T_GolfListAPIData>;

type T_GolfListAPIData = {
  name_en: string;
  status: string;
  [key: string]: any;
};

export enum E_GolfSeatType {
  Regular = "Regular",
  Deluxe = "Deluxe",
  Premium = "Premium",
  Open = "Open",
}

export interface I_ActiveGolfSeatConfig {
  Regular: boolean;
  Deluxe: boolean;
  Premium: boolean;
  Open: boolean;
}

// export type T_GolfSeatType =
//   | E_GolfSeatType.Regular
//   | E_GolfSeatType.Deluxe
//   | E_GolfSeatType.Premium
//   | E_GolfSeatType.Open;

export enum E_GolfBay {
  G01 = "G01 - Regular Bay",
  G02 = "G02 - Regular Bay",
  G03 = "G03 - Regular Bay",
  G04 = "G04 - Regular Bay",
  G05 = "G05 - Regular Bay",
  G06 = "G06 - Regular Bay",
  G07 = "G07 - Regular Bay",
  G08 = "G08 - Regular Bay",
  G09 = "G09 - Regular Bay",
  G10 = "G10 - Regular Bay",
  G11 = "G11 - Regular Bay",
  G12 = "G12 - Regular Bay",
  G13 = "G13 - Regular Bay",
  G14 = "G14 - Regular Bay",
  G15 = "G15 - Regular Bay",
  G16 = "G16 - Regular Bay",
  G17 = "G17 - Regular Bay",
  G18 = "G18 - Regular Bay",
  G19 = "G19 - Regular Bay",
  G20 = "G20 - Regular Bay",
  G21 = "G21 - Regular Bay",
  G22 = "G22 - Regular Bay",
  LG01 = "LG01 - Regular Bay",
  LG02 = "LG02 - Regular Bay",
  LG03 = "LG03 - Regular Bay",
  LG04 = "LG04 - Regular Bay",
  LG05 = "LG05 - Regular Bay",
  LG06 = "LG06 - Regular Bay",
  LG07 = "LG07 - Regular Bay",
  LG08 = "LG08 - Regular Bay",
  LG09 = "LG09 - Regular Bay",
  LG10 = "LG10 - Regular Bay",
  LG11 = "LG11 - Regular Bay",
  LG12 = "LG12 - Regular Bay",
  LG13 = "LG13 - Regular Bay",
  LG14 = "LG14 - Regular Bay",
  LG15 = "LG15 - Regular Bay",
  LG16 = "LG16 - Regular Bay",
  LG17 = "LG17 - Regular Bay",
  LG18 = "LG18 - Regular Bay",
  LG19 = "LG19 - Regular Bay",
  LG20 = "LG20 - Regular Bay",
  LG21 = "LG21 - Regular Bay",
  LG22 = "LG22 - Regular Bay",
  LG23 = "LG23 - Regular Bay",
  LG24 = "LG24 - Regular Bay",
  LG25 = "LG25 - Regular Bay",
  LG26 = "LG26 - Regular Bay",
  LG27 = "LG27 - Regular Bay",
  LG28 = "LG28 - Regular Bay",
  LG29 = "LG29 - Regular Bay",
  LG30 = "LG30 - Regular Bay",
  LG31 = "LG31 - Regular Bay",
  LG32 = "LG32 - Regular Bay",
  LG33 = "LG33 - Regular Bay",
  LG34 = "LG34 - Regular Bay",
  LG35 = "LG35 - Regular Bay",
  LG36 = "LG36 - Regular Bay",
  LG37 = "LG37 - Regular Bay",
  LG38 = "LG38 - Regular Bay",
  LG39 = "LG39 - Regular Bay",
  V01 = "V01 - Premium Bay",
  V02 = "V02 - Deluxe Bay",
  V03 = "V03 - Deluxe Bay",
  V04 = "V04 - Deluxe Bay",
  V05 = "V05 - Deluxe Bay",
  V06 = "V06 - Deluxe Bay",
  V07 = "V07 - Deluxe Bay",
  V08 = "V08 - Deluxe Bay",
  V09 = "V09 - Premium Bay",
  OB01 = "OB01 - Open Bay",
  OB02 = "OB02 - Open Bay",
  OB03 = "OB03 - Open Bay",
  OB04 = "OB04 - Open Bay",
  OB05 = "OB05 - Open Bay",
  OB06 = "OB06 - Open Bay",
  OB07 = "OB07 - Open Bay",
}

export const supportedGolfBayList: string[] = [
  "G01 - Regular Bay",
  "G02 - Regular Bay",
  "G03 - Regular Bay",
  "G04 - Regular Bay",
  "G05 - Regular Bay",
  "G06 - Regular Bay",
  "G07 - Regular Bay",
  "G08 - Regular Bay",
  "G09 - Regular Bay",
  "G10 - Regular Bay",
  "G11 - Regular Bay",
  "G12 - Regular Bay",
  "G13 - Regular Bay",
  "G14 - Regular Bay",
  "G15 - Regular Bay",
  "G16 - Regular Bay",
  "G17 - Regular Bay",
  "G18 - Regular Bay",
  "G19 - Regular Bay",
  "G20 - Regular Bay",
  "G21 - Regular Bay",
  "G22 - Regular Bay",
  "LG01 - Regular Bay",
  "LG02 - Regular Bay",
  "LG03 - Regular Bay",
  "LG04 - Regular Bay",
  "LG05 - Regular Bay",
  "LG06 - Regular Bay",
  "LG07 - Regular Bay",
  "LG08 - Regular Bay",
  "LG09 - Regular Bay",
  "LG10 - Regular Bay",
  "LG11 - Regular Bay",
  "LG12 - Regular Bay",
  "LG13 - Regular Bay",
  "LG14 - Regular Bay",
  "LG15 - Regular Bay",
  "LG16 - Regular Bay",
  "LG17 - Regular Bay",
  "LG18 - Regular Bay",
  "LG19 - Regular Bay",
  "LG20 - Regular Bay",
  "LG21 - Regular Bay",
  "LG22 - Regular Bay",
  "LG23 - Regular Bay",
  "LG24 - Regular Bay",
  "LG25 - Regular Bay",
  "LG26 - Regular Bay",
  "LG27 - Regular Bay",
  "LG28 - Regular Bay",
  "LG29 - Regular Bay",
  "LG30 - Regular Bay",
  "LG31 - Regular Bay",
  "LG32 - Regular Bay",
  "LG33 - Regular Bay",
  "LG34 - Regular Bay",
  "LG35 - Regular Bay",
  "LG36 - Regular Bay",
  "LG37 - Regular Bay",
  "LG38 - Regular Bay",
  "LG39 - Regular Bay",
  "V01 - Premium Bay",
  "V02 - Deluxe Bay",
  "V03 - Deluxe Bay",
  "V04 - Deluxe Bay",
  "V05 - Deluxe Bay",
  "V06 - Deluxe Bay",
  "V07 - Deluxe Bay",
  "V08 - Deluxe Bay",
  "V09 - Premium Bay",
  "OB01 - Open Bay",
  "OB02 - Open Bay",
  "OB03 - Open Bay",
  "OB04 - Open Bay",
  "OB05 - Open Bay",
  "OB06 - Open Bay",
  "OB07 - Open Bay",
];

export type T_GolfBay =
  | "G01 - Regular Bay"
  | "G02 - Regular Bay"
  | "G03 - Regular Bay"
  | "G04 - Regular Bay"
  | "G05 - Regular Bay"
  | "G06 - Regular Bay"
  | "G07 - Regular Bay"
  | "G08 - Regular Bay"
  | "G09 - Regular Bay"
  | "G10 - Regular Bay"
  | "G11 - Regular Bay"
  | "G12 - Regular Bay"
  | "G13 - Regular Bay"
  | "G14 - Regular Bay"
  | "G15 - Regular Bay"
  | "G16 - Regular Bay"
  | "G17 - Regular Bay"
  | "G18 - Regular Bay"
  | "G19 - Regular Bay"
  | "G20 - Regular Bay"
  | "G21 - Regular Bay"
  | "G22 - Regular Bay"
  | "LG01 - Regular Bay"
  | "LG02 - Regular Bay"
  | "LG03 - Regular Bay"
  | "LG04 - Regular Bay"
  | "LG05 - Regular Bay"
  | "LG06 - Regular Bay"
  | "LG07 - Regular Bay"
  | "LG08 - Regular Bay"
  | "LG09 - Regular Bay"
  | "LG10 - Regular Bay"
  | "LG11 - Regular Bay"
  | "LG12 - Regular Bay"
  | "LG13 - Regular Bay"
  | "LG14 - Regular Bay"
  | "LG15 - Regular Bay"
  | "LG16 - Regular Bay"
  | "LG17 - Regular Bay"
  | "LG18 - Regular Bay"
  | "LG19 - Regular Bay"
  | "LG20 - Regular Bay"
  | "LG21 - Regular Bay"
  | "LG22 - Regular Bay"
  | "LG23 - Regular Bay"
  | "LG24 - Regular Bay"
  | "LG25 - Regular Bay"
  | "LG26 - Regular Bay"
  | "LG27 - Regular Bay"
  | "LG28 - Regular Bay"
  | "LG29 - Regular Bay"
  | "LG30 - Regular Bay"
  | "LG31 - Regular Bay"
  | "LG32 - Regular Bay"
  | "LG33 - Regular Bay"
  | "LG34 - Regular Bay"
  | "LG35 - Regular Bay"
  | "LG36 - Regular Bay"
  | "LG37 - Regular Bay"
  | "LG38 - Regular Bay"
  | "LG39 - Regular Bay"
  | "V01 - Premium Bay"
  | "V02 - Deluxe Bay"
  | "V03 - Deluxe Bay"
  | "V04 - Deluxe Bay"
  | "V05 - Deluxe Bay"
  | "V06 - Deluxe Bay"
  | "V07 - Deluxe Bay"
  | "V08 - Deluxe Bay"
  | "V09 - Premium Bay"
  | "OB01 - Open Bay"
  | "OB02 - Open Bay"
  | "OB03 - Open Bay"
  | "OB04 - Open Bay"
  | "OB05 - Open Bay"
  | "OB06 - Open Bay"
  | "OB07 - Open Bay";

export type T_GolfSeatData = {
  [key in T_GolfBay]: {
    name: T_GolfBay;
    active: boolean;
    data?: any;
  };
};

interface I_GolfSeatParams {
  type: E_GolfSeatType;
  name: E_GolfBay;
  svgConfig: {
    path: string;
    activeColor?: string;
    activeStroke?: {
      color: string;
      width: string;
      lineJoin: string;
    };
    inactiveColor?: string;
    selectedColor?: string;
  }[];
}

interface I_DefaultGolfSvgConfig {
  Regular: {
    activeColor: string[];
    inactiveColor: string[];
    selectedColor: string[];
  };
  Deluxe: {
    activeColor: string[];
    inactiveColor: string[];
    selectedColor: string[];
  };
  Premium: {
    activeColor: string[];
    inactiveColor: string[];
    selectedColor: string[];
  };
  Open: {
    activeColor: string[];
    inactiveColor: string[];
    selectedColor: string[];
  };
  title: {
    activeColor: string;
    inactiveColor: string;
  };
}

export const defaultGolfSvgConfig: I_DefaultGolfSvgConfig = {
  Regular: {
    activeColor: ["#FFFFFF", "#000000"],
    inactiveColor: ["#A9A9A9", "#848484"],
    selectedColor: ["#FF7925", "#000000"],
  },
  Deluxe: {
    activeColor: ["#F0FFBF", "#000000"],
    inactiveColor: ["#A9A9A9", "#848484"],
    selectedColor: ["#FF7925", "#000000"],
  },
  Premium: {
    activeColor: ["#25A1FF", "#000000"],
    inactiveColor: ["#A9A9A9", "#848484"],
    selectedColor: ["#FF7925", "#000000"],
  },
  Open: {
    activeColor: ["#FFFFFF", "#000000", "#000000"],
    inactiveColor: ["#D5CDC0", "#848484", "#848484"],
    selectedColor: ["#FF7925", "#000000", "#000000"],
  },
  title: {
    activeColor: "#000000",
    inactiveColor: "#999998",
  },
};

const golfListAPISchema = Joi.array().items(
  Joi.object({
    name_en: Joi.string().required(),
    status: Joi.string().required(),
  }).unknown(true)
);

export const validateGolfListAPI = async (
  data: any
): Promise<{ result: boolean; err?: any }> => {
  try {
    await golfListAPISchema.validateAsync(data);

    const upsupportedGolfBayList: string[] = [];

    data.forEach((item: T_GolfListAPIData) => {
      if (!supportedGolfBayList.includes(item.name_en)) {
        upsupportedGolfBayList.push(item.name_en);
      }
    });

    if (upsupportedGolfBayList.length > 0) {
      return Promise.resolve({
        result: false,
        err: `Unsupported golf bay list: ${upsupportedGolfBayList.join(", ")}`,
      });
    } else {
      return Promise.resolve({ result: true });
    }
  } catch (err) {
    return Promise.resolve({ result: false, err });
  }
};

const golfLayoutConfig: I_GolfSeatParams[] = [
  {
    type: E_GolfSeatType.Regular,
    name: E_GolfBay.G01,
    svgConfig: [
      {
        path: "M1817.9 1483.89C1819.78 1480.32 1823.61 1478.23 1827.63 1478.58L1903.47 1485.23C1910.47 1485.84 1914.67 1493.29 1911.58 1499.59L1832 1661.88C1830.19 1665.57 1826.3 1667.78 1822.2 1667.44L1740.57 1660.74C1733.43 1660.15 1729.2 1652.46 1732.54 1646.12L1817.9 1483.89Z",
      },
      {
        path: "M1812.16 1593.48C1805.2 1593.48 1802 1587.04 1802 1579.28C1802 1571.24 1805.2 1565.04 1812.16 1565.04C1819.12 1565.04 1822.36 1571.24 1822.36 1579.28C1822.36 1587.28 1819.12 1593.48 1812.16 1593.48ZM1812.16 1590.16C1816.48 1590.16 1818.2 1585.6 1818.2 1579.28C1818.2 1572.92 1816.48 1568.36 1812.16 1568.36C1807.88 1568.36 1806.16 1572.92 1806.16 1579.28C1806.16 1585.6 1807.88 1590.16 1812.16 1590.16ZM1836.05 1593H1832.05V1574.24H1825.37V1571.32H1825.73C1830.21 1571.32 1832.49 1570 1832.69 1565.76L1832.73 1565.48H1836.05V1593Z",
      },
    ],
  },
  {
    type: E_GolfSeatType.Regular,
    name: E_GolfBay.G02,
    svgConfig: [
      {
        path: "M1929.98 1494.11C1931.8 1490.43 1935.7 1488.23 1939.8 1488.58L2017.65 1495.27C2024.6 1495.86 2028.82 1503.21 2025.83 1509.51L1948.98 1671.71C1947.19 1675.49 1943.23 1677.76 1939.07 1677.39L1858.07 1670.28C1851.06 1669.66 1846.87 1662.19 1849.98 1655.89L1929.98 1494.11Z",
      },
      {
        path: "M1925.16 1603.48C1918.2 1603.48 1915 1597.04 1915 1589.28C1915 1581.24 1918.2 1575.04 1925.16 1575.04C1932.12 1575.04 1935.36 1581.24 1935.36 1589.28C1935.36 1597.28 1932.12 1603.48 1925.16 1603.48ZM1925.16 1600.16C1929.48 1600.16 1931.2 1595.6 1931.2 1589.28C1931.2 1582.92 1929.48 1578.36 1925.16 1578.36C1920.88 1578.36 1919.16 1582.92 1919.16 1589.28C1919.16 1595.6 1920.88 1600.16 1925.16 1600.16ZM1957.69 1603H1939.45V1599.6L1942.49 1597.6C1948.89 1593.48 1953.49 1589.48 1953.49 1583.48C1953.49 1580.28 1951.81 1578.36 1948.65 1578.36C1945.61 1578.36 1943.53 1580.28 1943.53 1583.8C1943.53 1584.52 1943.57 1585.12 1943.65 1585.64L1939.61 1585.12C1939.57 1584.72 1939.53 1584.2 1939.53 1583.48C1939.53 1578.24 1943.41 1575.04 1948.69 1575.04C1954.25 1575.04 1957.69 1578 1957.69 1583.28C1957.69 1590.08 1952.69 1594.6 1947.09 1598.16L1944.81 1599.6V1599.64H1957.69V1603Z",
      },
    ],
  },
  {
    type: E_GolfSeatType.Regular,
    name: E_GolfBay.G03,
    svgConfig: [
      {
        path: "M2044.03 1504.3C2045.82 1500.52 2049.77 1498.24 2053.94 1498.6L2130.99 1505.29C2137.85 1505.88 2142.07 1513.05 2139.26 1519.33L2066.88 1681.07C2065.15 1684.92 2061.19 1687.28 2056.97 1686.95L1975.94 1680.63C1968.97 1680.08 1964.69 1672.73 1967.67 1666.4L2044.03 1504.3Z",
      },
      {
        path: "M2040.16 1613.48C2033.2 1613.48 2030 1607.04 2030 1599.28C2030 1591.24 2033.2 1585.04 2040.16 1585.04C2047.12 1585.04 2050.36 1591.24 2050.36 1599.28C2050.36 1607.28 2047.12 1613.48 2040.16 1613.48ZM2040.16 1610.16C2044.48 1610.16 2046.2 1605.6 2046.2 1599.28C2046.2 1592.92 2044.48 1588.36 2040.16 1588.36C2035.88 1588.36 2034.16 1592.92 2034.16 1599.28C2034.16 1605.6 2035.88 1610.16 2040.16 1610.16ZM2064.09 1613.48C2058.29 1613.48 2054.49 1610.24 2054.17 1604.84L2058.25 1604.2C2058.49 1608.08 2060.77 1610.16 2064.17 1610.16C2067.77 1610.16 2069.77 1608.08 2069.77 1605.12C2069.77 1602.32 2067.89 1600.36 2064.01 1600.36C2062.77 1600.36 2061.89 1600.48 2061.25 1600.52V1597C2061.89 1597.04 2062.73 1597.08 2063.93 1597.08C2067.01 1597.08 2069.09 1595.32 2069.09 1592.56C2069.09 1590.12 2067.33 1588.36 2064.29 1588.36C2060.93 1588.36 2058.85 1590.48 2058.65 1594.04L2054.61 1593.32C2054.85 1588.2 2058.77 1585.04 2064.33 1585.04C2069.57 1585.04 2073.25 1587.72 2073.25 1592.36C2073.25 1595.92 2070.93 1597.64 2068.77 1598.44V1598.52C2071.65 1599.24 2073.93 1601.6 2073.93 1605.16C2073.93 1610.2 2069.97 1613.48 2064.09 1613.48Z",
      },
    ],
  },
  {
    type: E_GolfSeatType.Regular,
    name: E_GolfBay.G04,
    svgConfig: [
      {
        path: "M2157.11 1514.54C2158.84 1510.63 2162.86 1508.25 2167.12 1508.62L2244.3 1515.31C2251.06 1515.9 2255.29 1522.91 2252.65 1529.17L2184.32 1690.82C2182.64 1694.81 2178.59 1697.26 2174.28 1696.9L2093.99 1690.17C2087.14 1689.6 2082.9 1682.45 2085.67 1676.16L2157.11 1514.54Z",
      },
      {
        path: "M2157.16 1623.48C2150.2 1623.48 2147 1617.04 2147 1609.28C2147 1601.24 2150.2 1595.04 2157.16 1595.04C2164.12 1595.04 2167.36 1601.24 2167.36 1609.28C2167.36 1617.28 2164.12 1623.48 2157.16 1623.48ZM2157.16 1620.16C2161.48 1620.16 2163.2 1615.6 2163.2 1609.28C2163.2 1602.92 2161.48 1598.36 2157.16 1598.36C2152.88 1598.36 2151.16 1602.92 2151.16 1609.28C2151.16 1615.6 2152.88 1620.16 2157.16 1620.16ZM2187.65 1613.48H2192.13V1616.96H2187.65V1623H2183.65V1616.96H2170.53V1613L2181.57 1595.48H2187.65V1613.48ZM2183.65 1598.76L2174.41 1613.48H2183.65V1598.76Z",
      },
    ],
  },
  {
    type: E_GolfSeatType.Regular,
    name: E_GolfBay.G05,
    svgConfig: [
      {
        path: "M2273.16 1524.69C2274.85 1520.7 2278.92 1518.26 2283.24 1518.64L2358.63 1525.32C2365.3 1525.91 2369.51 1532.75 2367.04 1538.97L2302.74 1700.61C2301.11 1704.7 2297 1707.25 2292.61 1706.88L2212.77 1700.16C2205.99 1699.59 2201.75 1692.55 2204.41 1686.28L2273.16 1524.69Z",
      },
      {
        path: "M2275.16 1633.48C2268.2 1633.48 2265 1627.04 2265 1619.28C2265 1611.24 2268.2 1605.04 2275.16 1605.04C2282.12 1605.04 2285.36 1611.24 2285.36 1619.28C2285.36 1627.28 2282.12 1633.48 2275.16 1633.48ZM2275.16 1630.16C2279.48 1630.16 2281.2 1625.6 2281.2 1619.28C2281.2 1612.92 2279.48 1608.36 2275.16 1608.36C2270.88 1608.36 2269.16 1612.92 2269.16 1619.28C2269.16 1625.6 2270.88 1630.16 2275.16 1630.16ZM2299.85 1614.36C2305.29 1614.36 2308.77 1618.28 2308.77 1623.64C2308.77 1629.28 2304.57 1633.48 2298.85 1633.48C2293.33 1633.48 2289.93 1630.24 2289.09 1625.68L2293.21 1624.88C2293.77 1628.04 2295.69 1630.16 2298.93 1630.16C2302.37 1630.16 2304.57 1627.56 2304.57 1623.72C2304.57 1620.2 2302.65 1617.68 2299.25 1617.68C2296.45 1617.68 2294.53 1619.68 2293.93 1621.24L2290.17 1620.52L2291.25 1605.48H2306.77V1609.08H2294.61L2294.05 1616.6H2294.09C2295.17 1615.48 2297.13 1614.36 2299.85 1614.36Z",
      },
    ],
  },
  {
    type: E_GolfSeatType.Regular,
    name: E_GolfBay.G06,
    svgConfig: [
      {
        path: "M2386.25 1534.91C2387.88 1530.81 2392.01 1528.26 2396.41 1528.65L2472.93 1535.36C2479.51 1535.93 2483.73 1542.61 2481.44 1548.8L2421.64 1709.88C2420.08 1714.09 2415.91 1716.74 2411.44 1716.37L2330.46 1709.62C2323.77 1709.06 2319.52 1702.19 2322.01 1695.95L2386.25 1534.91Z",
      },
      {
        path: "M2391.16 1642.48C2384.2 1642.48 2381 1636.04 2381 1628.28C2381 1620.24 2384.2 1614.04 2391.16 1614.04C2398.12 1614.04 2401.36 1620.24 2401.36 1628.28C2401.36 1636.28 2398.12 1642.48 2391.16 1642.48ZM2391.16 1639.16C2395.48 1639.16 2397.2 1634.6 2397.2 1628.28C2397.2 1621.92 2395.48 1617.36 2391.16 1617.36C2386.88 1617.36 2385.16 1621.92 2385.16 1628.28C2385.16 1634.6 2386.88 1639.16 2391.16 1639.16ZM2415.61 1642.48C2408.61 1642.48 2405.53 1636.68 2405.53 1628.84C2405.53 1619.24 2409.65 1614.04 2416.09 1614.04C2421.13 1614.04 2423.97 1617.08 2424.69 1621.04L2420.77 1621.8C2420.37 1619.08 2418.77 1617.28 2415.93 1617.28C2411.21 1617.28 2409.49 1622.2 2409.45 1628.04L2409.49 1628.08C2410.45 1626.2 2412.69 1624.32 2416.17 1624.32C2421.69 1624.32 2425.09 1628.16 2425.09 1633.24C2425.09 1638.56 2421.17 1642.48 2415.61 1642.48ZM2415.73 1639.16C2418.77 1639.16 2421.05 1636.96 2421.05 1633.32C2421.05 1629.56 2418.69 1627.64 2415.73 1627.64C2412.57 1627.64 2410.21 1630.08 2410.21 1633.28C2410.21 1636.76 2412.53 1639.16 2415.73 1639.16Z",
      },
    ],
  },
  {
    type: E_GolfSeatType.Regular,
    name: E_GolfBay.G07,
    svgConfig: [
      {
        path: "M2498.36 1544.16C2499.92 1539.93 2504.11 1537.27 2508.6 1537.66L2586.21 1544.39C2592.7 1544.95 2596.93 1551.48 2594.79 1557.63L2538.54 1719.67C2537.05 1723.98 2532.82 1726.73 2528.27 1726.36L2447.1 1719.59C2440.52 1719.04 2436.26 1712.37 2438.55 1706.16L2498.36 1544.16Z",
      },
      {
        path: "M2505.16 1652.48C2498.2 1652.48 2495 1646.04 2495 1638.28C2495 1630.24 2498.2 1624.04 2505.16 1624.04C2512.12 1624.04 2515.36 1630.24 2515.36 1638.28C2515.36 1646.28 2512.12 1652.48 2505.16 1652.48ZM2505.16 1649.16C2509.48 1649.16 2511.2 1644.6 2511.2 1638.28C2511.2 1631.92 2509.48 1627.36 2505.16 1627.36C2500.88 1627.36 2499.16 1631.92 2499.16 1638.28C2499.16 1644.6 2500.88 1649.16 2505.16 1649.16ZM2518.65 1631.76V1624.48H2538.37V1627.56C2533.81 1635.36 2530.13 1643.68 2528.33 1652H2523.97C2525.81 1643.92 2530.09 1634.8 2534.41 1627.96H2522.53V1631.76H2518.65Z",
      },
    ],
  },
  {
    type: E_GolfSeatType.Regular,
    name: E_GolfBay.G08,
    svgConfig: [
      {
        path: "M2612.45 1554.34C2613.95 1550.02 2618.19 1547.27 2622.75 1547.66L2702.44 1554.43C2708.86 1554.98 2713.09 1561.36 2711.1 1567.49L2658.44 1729.48C2657.01 1733.89 2652.74 1736.73 2648.12 1736.36L2564.87 1729.55C2558.35 1729.02 2554.09 1722.48 2556.23 1716.31L2612.45 1554.34Z",
      },
      {
        path: "M2622.16 1661.48C2615.2 1661.48 2612 1655.04 2612 1647.28C2612 1639.24 2615.2 1633.04 2622.16 1633.04C2629.12 1633.04 2632.36 1639.24 2632.36 1647.28C2632.36 1655.28 2629.12 1661.48 2622.16 1661.48ZM2622.16 1658.16C2626.48 1658.16 2628.2 1653.6 2628.2 1647.28C2628.2 1640.92 2626.48 1636.36 2622.16 1636.36C2617.88 1636.36 2616.16 1640.92 2616.16 1647.28C2616.16 1653.6 2617.88 1658.16 2622.16 1658.16ZM2646.33 1661.48C2640.53 1661.48 2636.45 1658.4 2636.45 1653.36C2636.45 1649.16 2639.25 1647.16 2641.53 1646.32V1646.28C2639.85 1645.64 2637.33 1643.64 2637.33 1640.36C2637.33 1635.88 2641.25 1633 2646.33 1633C2651.37 1633 2655.33 1635.88 2655.33 1640.36C2655.33 1643.64 2652.81 1645.64 2651.09 1646.28V1646.32C2653.37 1647.16 2656.17 1649.16 2656.17 1653.36C2656.17 1658.4 2652.13 1661.48 2646.33 1661.48ZM2646.33 1644.96C2649.21 1644.96 2651.25 1643.08 2651.25 1640.6C2651.25 1638.16 2649.29 1636.28 2646.33 1636.28C2643.33 1636.28 2641.37 1638.16 2641.37 1640.6C2641.37 1643.08 2643.45 1644.96 2646.33 1644.96ZM2646.33 1658.16C2649.81 1658.16 2652.09 1656.12 2652.09 1653.12C2652.09 1650.2 2649.81 1648.16 2646.33 1648.16C2642.85 1648.16 2640.53 1650.2 2640.53 1653.12C2640.53 1656.12 2642.85 1658.16 2646.33 1658.16Z",
      },
    ],
  },
  {
    type: E_GolfSeatType.Regular,
    name: E_GolfBay.G09,
    svgConfig: [
      {
        path: "M2727.54 1564.56C2728.98 1560.14 2733.29 1557.29 2737.92 1557.69L2814.79 1564.43C2821.1 1564.98 2825.31 1571.18 2823.51 1577.24L2775.32 1739.21C2773.97 1743.75 2769.62 1746.72 2764.9 1746.33L2683.58 1739.55C2677.16 1739.01 2672.91 1732.62 2674.9 1726.49L2727.54 1564.56Z",
      },
      {
        path: "M2739.16 1671.48C2732.2 1671.48 2729 1665.04 2729 1657.28C2729 1649.24 2732.2 1643.04 2739.16 1643.04C2746.12 1643.04 2749.36 1649.24 2749.36 1657.28C2749.36 1665.28 2746.12 1671.48 2739.16 1671.48ZM2739.16 1668.16C2743.48 1668.16 2745.2 1663.6 2745.2 1657.28C2745.2 1650.92 2743.48 1646.36 2739.16 1646.36C2734.88 1646.36 2733.16 1650.92 2733.16 1657.28C2733.16 1663.6 2734.88 1668.16 2739.16 1668.16ZM2762.57 1671.48C2757.49 1671.48 2754.65 1668.44 2753.97 1664.48L2757.89 1663.72C2758.29 1666.44 2759.89 1668.24 2762.73 1668.24C2767.45 1668.24 2769.13 1663.32 2769.21 1657.52L2769.17 1657.48C2768.17 1659.32 2765.97 1661.2 2762.49 1661.2C2756.97 1661.2 2753.57 1657.36 2753.57 1652.28C2753.57 1646.96 2757.45 1643.04 2763.05 1643.04C2770.05 1643.04 2773.13 1648.84 2773.13 1656.68C2773.13 1666.28 2769.01 1671.48 2762.57 1671.48ZM2762.93 1657.88C2766.05 1657.88 2768.45 1655.44 2768.45 1652.24C2768.45 1648.8 2766.13 1646.36 2762.93 1646.36C2759.89 1646.36 2757.57 1648.56 2757.57 1652.2C2757.57 1655.96 2759.93 1657.88 2762.93 1657.88Z",
      },
    ],
  },
  {
    type: E_GolfSeatType.Regular,
    name: E_GolfBay.G10,
    svgConfig: [
      {
        path: "M2841.65 1574.76C2843.02 1570.24 2847.37 1567.29 2852.08 1567.7L2930.03 1574.46C2936.26 1575 2940.47 1581.06 2938.81 1587.08L2894.2 1749.01C2892.92 1753.64 2888.53 1756.71 2883.74 1756.32L2801.34 1749.52C2794.99 1748.99 2790.74 1742.75 2792.59 1736.65L2841.65 1574.76Z",
      },
      {
        path: "M2855.56 1681H2851.56V1662.24H2844.88V1659.32H2845.24C2849.72 1659.32 2852 1658 2852.2 1653.76L2852.24 1653.48H2855.56V1681ZM2871.16 1681.48C2864.2 1681.48 2861 1675.04 2861 1667.28C2861 1659.24 2864.2 1653.04 2871.16 1653.04C2878.12 1653.04 2881.36 1659.24 2881.36 1667.28C2881.36 1675.28 2878.12 1681.48 2871.16 1681.48ZM2871.16 1678.16C2875.48 1678.16 2877.2 1673.6 2877.2 1667.28C2877.2 1660.92 2875.48 1656.36 2871.16 1656.36C2866.88 1656.36 2865.16 1660.92 2865.16 1667.28C2865.16 1673.6 2866.88 1678.16 2871.16 1678.16Z",
      },
    ],
  },
  {
    type: E_GolfSeatType.Regular,
    name: E_GolfBay.G11,
    svgConfig: [
      {
        path: "M2956.76 1584.98C2958.06 1580.35 2962.47 1577.3 2967.26 1577.72L3044.29 1584.47C3050.43 1585.01 3054.63 1590.92 3053.11 1596.89L3012.08 1758.79C3010.88 1763.53 3006.43 1766.7 3001.56 1766.3L2920.07 1759.51C2913.81 1758.98 2909.58 1752.88 2911.28 1746.84L2956.76 1584.98Z",
      },
      {
        path: "M2977.56 1691H2973.56V1672.24H2966.88V1669.32H2967.24C2971.72 1669.32 2974 1668 2974.2 1663.76L2974.24 1663.48H2977.56V1691ZM2992.56 1691H2988.56V1672.24H2981.88V1669.32H2982.24C2986.72 1669.32 2989 1668 2989.2 1663.76L2989.24 1663.48H2992.56V1691Z",
      },
    ],
  },
  {
    type: E_GolfSeatType.Regular,
    name: E_GolfBay.G12,
    svgConfig: [
      {
        path: "M3070.91 1595.24C3072.12 1590.49 3076.59 1587.31 3081.47 1587.73L3159.52 1594.5C3165.58 1595.03 3169.77 1600.8 3168.4 1606.72L3130.95 1768.57C3129.83 1773.41 3125.33 1776.69 3120.38 1776.28L3038.76 1769.48C3032.61 1768.97 3028.38 1763.05 3029.9 1757.06L3070.91 1595.24Z",
      },
      {
        path: "M3089.56 1701H3085.56V1682.24H3078.88V1679.32H3079.24C3083.72 1679.32 3086 1678 3086.2 1673.76L3086.24 1673.48H3089.56V1701ZM3113.2 1701H3094.96V1697.6L3098 1695.6C3104.4 1691.48 3109 1687.48 3109 1681.48C3109 1678.28 3107.32 1676.36 3104.16 1676.36C3101.12 1676.36 3099.04 1678.28 3099.04 1681.8C3099.04 1682.52 3099.08 1683.12 3099.16 1683.64L3095.12 1683.12C3095.08 1682.72 3095.04 1682.2 3095.04 1681.48C3095.04 1676.24 3098.92 1673.04 3104.2 1673.04C3109.76 1673.04 3113.2 1676 3113.2 1681.28C3113.2 1688.08 3108.2 1692.6 3102.6 1696.16L3100.32 1697.6V1697.64H3113.2V1701Z",
      },
    ],
  },
  {
    type: E_GolfSeatType.Regular,
    name: E_GolfBay.G13,
    svgConfig: [
      {
        path: "M3185.04 1605.45C3186.17 1600.6 3190.68 1597.32 3195.64 1597.74L3274.81 1604.54C3280.77 1605.05 3284.95 1610.64 3283.76 1616.5L3250.77 1778.31C3249.76 1783.27 3245.2 1786.69 3240.16 1786.28L3156.54 1779.44C3150.46 1778.95 3146.23 1773.17 3147.61 1767.22L3185.04 1605.45Z",
      },
      {
        path: "M3207.56 1711H3203.56V1692.24H3196.88V1689.32H3197.24C3201.72 1689.32 3204 1688 3204.2 1683.76L3204.24 1683.48H3207.56V1711ZM3222.6 1711.48C3216.8 1711.48 3213 1708.24 3212.68 1702.84L3216.76 1702.2C3217 1706.08 3219.28 1708.16 3222.68 1708.16C3226.28 1708.16 3228.28 1706.08 3228.28 1703.12C3228.28 1700.32 3226.4 1698.36 3222.52 1698.36C3221.28 1698.36 3220.4 1698.48 3219.76 1698.52V1695C3220.4 1695.04 3221.24 1695.08 3222.44 1695.08C3225.52 1695.08 3227.6 1693.32 3227.6 1690.56C3227.6 1688.12 3225.84 1686.36 3222.8 1686.36C3219.44 1686.36 3217.36 1688.48 3217.16 1692.04L3213.12 1691.32C3213.36 1686.2 3217.28 1683.04 3222.84 1683.04C3228.08 1683.04 3231.76 1685.72 3231.76 1690.36C3231.76 1693.92 3229.44 1695.64 3227.28 1696.44V1696.52C3230.16 1697.24 3232.44 1699.6 3232.44 1703.16C3232.44 1708.2 3228.48 1711.48 3222.6 1711.48Z",
      },
    ],
  },
  {
    type: E_GolfSeatType.Regular,
    name: E_GolfBay.G14,
    svgConfig: [
      {
        path: "M3299.22 1615.72C3300.24 1610.75 3304.81 1607.32 3309.87 1607.75L3389.98 1614.56C3395.88 1615.06 3400.05 1620.55 3398.96 1626.37L3368.66 1788.13C3367.72 1793.17 3363.12 1796.68 3358.01 1796.26L3275.23 1789.43C3269.25 1788.93 3265.05 1783.33 3266.25 1777.46L3299.22 1615.72Z",
      },
      {
        path: "M3323.56 1721H3319.56V1702.24H3312.88V1699.32H3313.24C3317.72 1699.32 3320 1698 3320.2 1693.76L3320.24 1693.48H3323.56V1721ZM3345.16 1711.48H3349.64V1714.96H3345.16V1721H3341.16V1714.96H3328.04V1711L3339.08 1693.48H3345.16V1711.48ZM3341.16 1696.76L3331.92 1711.48H3341.16V1696.76Z",
      },
    ],
  },
  {
    type: E_GolfSeatType.Regular,
    name: E_GolfBay.G15,
    svgConfig: [
      {
        path: "M3414.33 1625.9C3415.28 1620.85 3419.9 1617.33 3425.02 1617.77L3504.28 1624.58C3510.07 1625.08 3514.22 1630.38 3513.3 1636.12L3487.46 1797.86C3486.64 1803.02 3481.98 1806.67 3476.77 1806.25L3393.06 1799.4C3387.14 1798.92 3382.95 1793.43 3384.04 1787.6L3414.33 1625.9Z",
      },
      {
        path: "M3440.56 1731H3436.56V1712.24H3429.88V1709.32H3430.24C3434.72 1709.32 3437 1708 3437.2 1703.76L3437.24 1703.48H3440.56V1731ZM3456.36 1712.36C3461.8 1712.36 3465.28 1716.28 3465.28 1721.64C3465.28 1727.28 3461.08 1731.48 3455.36 1731.48C3449.84 1731.48 3446.44 1728.24 3445.6 1723.68L3449.72 1722.88C3450.28 1726.04 3452.2 1728.16 3455.44 1728.16C3458.88 1728.16 3461.08 1725.56 3461.08 1721.72C3461.08 1718.2 3459.16 1715.68 3455.76 1715.68C3452.96 1715.68 3451.04 1717.68 3450.44 1719.24L3446.68 1718.52L3447.76 1703.48H3463.28V1707.08H3451.12L3450.56 1714.6H3450.6C3451.68 1713.48 3453.64 1712.36 3456.36 1712.36Z",
      },
    ],
  },
  {
    type: E_GolfSeatType.Regular,
    name: E_GolfBay.G16,
    svgConfig: [
      {
        path: "M3529.49 1636.12C3530.34 1630.97 3535.01 1627.34 3540.21 1627.79L3619.51 1634.6C3625.22 1635.09 3629.34 1640.26 3628.56 1645.93L3606.29 1807.63C3605.57 1812.89 3600.86 1816.66 3595.57 1816.23L3511.82 1809.38C3505.99 1808.91 3501.82 1803.56 3502.77 1797.79L3529.49 1636.12Z",
      },
      {
        path: "M3556.56 1740H3552.56V1721.24H3545.88V1718.32H3546.24C3550.72 1718.32 3553 1717 3553.2 1712.76L3553.24 1712.48H3556.56V1740ZM3572.12 1740.48C3565.12 1740.48 3562.04 1734.68 3562.04 1726.84C3562.04 1717.24 3566.16 1712.04 3572.6 1712.04C3577.64 1712.04 3580.48 1715.08 3581.2 1719.04L3577.28 1719.8C3576.88 1717.08 3575.28 1715.28 3572.44 1715.28C3567.72 1715.28 3566 1720.2 3565.96 1726.04L3566 1726.08C3566.96 1724.2 3569.2 1722.32 3572.68 1722.32C3578.2 1722.32 3581.6 1726.16 3581.6 1731.24C3581.6 1736.56 3577.68 1740.48 3572.12 1740.48ZM3572.24 1737.16C3575.28 1737.16 3577.56 1734.96 3577.56 1731.32C3577.56 1727.56 3575.2 1725.64 3572.24 1725.64C3569.08 1725.64 3566.72 1728.08 3566.72 1731.28C3566.72 1734.76 3569.04 1737.16 3572.24 1737.16Z",
      },
    ],
  },
  {
    type: E_GolfSeatType.Regular,
    name: E_GolfBay.G17,
    svgConfig: [
      {
        path: "M3643.7 1646.4C3644.43 1641.13 3649.16 1637.35 3654.46 1637.8L3734.73 1644.63C3740.35 1645.1 3744.46 1650.14 3743.82 1655.74L3725.11 1817.39C3724.49 1822.75 3719.74 1826.65 3714.36 1826.21L3630.53 1819.36C3624.81 1818.89 3620.66 1813.72 3621.44 1808.03L3643.7 1646.4Z",
      },
      {
        path: "M3674.56 1750H3670.56V1731.24H3663.88V1728.32H3664.24C3668.72 1728.32 3671 1727 3671.2 1722.76L3671.24 1722.48H3674.56V1750ZM3679.16 1729.76V1722.48H3698.88V1725.56C3694.32 1733.36 3690.64 1741.68 3688.84 1750H3684.48C3686.32 1741.92 3690.6 1732.8 3694.92 1725.96H3683.04V1729.76H3679.16Z",
      },
    ],
  },
  {
    type: E_GolfSeatType.Regular,
    name: E_GolfBay.G18,
    svgConfig: [
      {
        path: "M3758.89 1656.64C3759.51 1651.27 3764.28 1647.36 3769.67 1647.82L3849.89 1654.64C3855.45 1655.11 3859.55 1660.04 3859 1665.59L3842.97 1827.21C3842.43 1832.64 3837.64 1836.64 3832.2 1836.19L3749.3 1829.35C3743.67 1828.89 3739.54 1823.85 3740.19 1818.23L3758.89 1656.64Z",
      },
      {
        path: "M3790.56 1760H3786.56V1741.24H3779.88V1738.32H3780.24C3784.72 1738.32 3787 1737 3787.2 1732.76L3787.24 1732.48H3790.56V1760ZM3805.84 1760.48C3800.04 1760.48 3795.96 1757.4 3795.96 1752.36C3795.96 1748.16 3798.76 1746.16 3801.04 1745.32V1745.28C3799.36 1744.64 3796.84 1742.64 3796.84 1739.36C3796.84 1734.88 3800.76 1732 3805.84 1732C3810.88 1732 3814.84 1734.88 3814.84 1739.36C3814.84 1742.64 3812.32 1744.64 3810.6 1745.28V1745.32C3812.88 1746.16 3815.68 1748.16 3815.68 1752.36C3815.68 1757.4 3811.64 1760.48 3805.84 1760.48ZM3805.84 1743.96C3808.72 1743.96 3810.76 1742.08 3810.76 1739.6C3810.76 1737.16 3808.8 1735.28 3805.84 1735.28C3802.84 1735.28 3800.88 1737.16 3800.88 1739.6C3800.88 1742.08 3802.96 1743.96 3805.84 1743.96ZM3805.84 1757.16C3809.32 1757.16 3811.6 1755.12 3811.6 1752.12C3811.6 1749.2 3809.32 1747.16 3805.84 1747.16C3802.36 1747.16 3800.04 1749.2 3800.04 1752.12C3800.04 1755.12 3802.36 1757.16 3805.84 1757.16Z",
      },
    ],
  },
  {
    type: E_GolfSeatType.Regular,
    name: E_GolfBay.G19,
    svgConfig: [
      {
        path: "M3875.03 1666.82C3875.57 1661.38 3880.38 1657.38 3885.83 1657.84L3965.18 1664.66C3970.62 1665.12 3974.69 1669.88 3974.3 1675.33L3962.72 1836.92C3962.32 1842.47 3957.48 1846.63 3951.93 1846.18L3868.14 1839.33C3862.57 1838.87 3858.45 1833.94 3859 1828.38L3875.03 1666.82Z",
      },
      {
        path: "M3909.56 1770H3905.56V1751.24H3898.88V1748.32H3899.24C3903.72 1748.32 3906 1747 3906.2 1742.76L3906.24 1742.48H3909.56V1770ZM3924.08 1770.48C3919 1770.48 3916.16 1767.44 3915.48 1763.48L3919.4 1762.72C3919.8 1765.44 3921.4 1767.24 3924.24 1767.24C3928.96 1767.24 3930.64 1762.32 3930.72 1756.52L3930.68 1756.48C3929.68 1758.32 3927.48 1760.2 3924 1760.2C3918.48 1760.2 3915.08 1756.36 3915.08 1751.28C3915.08 1745.96 3918.96 1742.04 3924.56 1742.04C3931.56 1742.04 3934.64 1747.84 3934.64 1755.68C3934.64 1765.28 3930.52 1770.48 3924.08 1770.48ZM3924.44 1756.88C3927.56 1756.88 3929.96 1754.44 3929.96 1751.24C3929.96 1747.8 3927.64 1745.36 3924.44 1745.36C3921.4 1745.36 3919.08 1747.56 3919.08 1751.2C3919.08 1754.96 3921.44 1756.88 3924.44 1756.88Z",
      },
    ],
  },
  {
    type: E_GolfSeatType.Regular,
    name: E_GolfBay.G20,
    svgConfig: [
      {
        path: "M3990.22 1677.05C3990.65 1671.51 3995.51 1667.38 4001.04 1667.85L4081.33 1674.68C4086.72 1675.14 4090.76 1679.79 4090.47 1685.19L4081.56 1846.75C4081.26 1852.37 4076.37 1856.62 4070.77 1856.16L3986.92 1849.31C3981.43 1848.86 3977.34 1844.06 3977.77 1838.58L3990.22 1677.05Z",
      },
      {
        path: "M4033.2 1780H4014.96V1776.6L4018 1774.6C4024.4 1770.48 4029 1766.48 4029 1760.48C4029 1757.28 4027.32 1755.36 4024.16 1755.36C4021.12 1755.36 4019.04 1757.28 4019.04 1760.8C4019.04 1761.52 4019.08 1762.12 4019.16 1762.64L4015.12 1762.12C4015.08 1761.72 4015.04 1761.2 4015.04 1760.48C4015.04 1755.24 4018.92 1752.04 4024.2 1752.04C4029.76 1752.04 4033.2 1755 4033.2 1760.28C4033.2 1767.08 4028.2 1771.6 4022.6 1775.16L4020.32 1776.6V1776.64H4033.2V1780ZM4047.5 1780.48C4040.54 1780.48 4037.34 1774.04 4037.34 1766.28C4037.34 1758.24 4040.54 1752.04 4047.5 1752.04C4054.46 1752.04 4057.7 1758.24 4057.7 1766.28C4057.7 1774.28 4054.46 1780.48 4047.5 1780.48ZM4047.5 1777.16C4051.82 1777.16 4053.54 1772.6 4053.54 1766.28C4053.54 1759.92 4051.82 1755.36 4047.5 1755.36C4043.22 1755.36 4041.5 1759.92 4041.5 1766.28C4041.5 1772.6 4043.22 1777.16 4047.5 1777.16Z",
      },
    ],
  },
  {
    type: E_GolfSeatType.Regular,
    name: E_GolfBay.G21,
    svgConfig: [
      {
        path: "M4105.43 1687.29C4105.74 1681.66 4110.65 1677.39 4116.27 1677.87L4196.54 1684.7C4201.84 1685.15 4205.86 1689.67 4205.69 1694.99L4200.35 1856.51C4200.16 1862.22 4195.23 1866.61 4189.54 1866.14L4105.71 1859.29C4100.3 1858.85 4096.24 1854.19 4096.54 1848.78L4105.43 1687.29Z",
      },
      {
        path: "M4153.2 1790H4134.96V1786.6L4138 1784.6C4144.4 1780.48 4149 1776.48 4149 1770.48C4149 1767.28 4147.32 1765.36 4144.16 1765.36C4141.12 1765.36 4139.04 1767.28 4139.04 1770.8C4139.04 1771.52 4139.08 1772.12 4139.16 1772.64L4135.12 1772.12C4135.08 1771.72 4135.04 1771.2 4135.04 1770.48C4135.04 1765.24 4138.92 1762.04 4144.2 1762.04C4149.76 1762.04 4153.2 1765 4153.2 1770.28C4153.2 1777.08 4148.2 1781.6 4142.6 1785.16L4140.32 1786.6V1786.64H4153.2V1790ZM4166.9 1790H4162.9V1771.24H4156.22V1768.32H4156.58C4161.06 1768.32 4163.34 1767 4163.54 1762.76L4163.58 1762.48H4166.9V1790Z",
      },
    ],
  },
  {
    type: E_GolfSeatType.Regular,
    name: E_GolfBay.G22,
    svgConfig: [
      {
        path: "M4220.71 1697.59C4220.87 1691.84 4225.82 1687.41 4231.55 1687.9L4311.75 1694.71C4316.96 1695.16 4320.96 1699.55 4320.9 1704.79L4319.12 1866.26C4319.05 1872.06 4314.08 1876.59 4308.3 1876.12L4225.43 1869.28C4220.14 1868.84 4216.11 1864.35 4216.26 1859.04L4220.71 1697.59Z",
        activeStroke: {
          color: "#8ED8F8",
          width: "10",
          lineJoin: "round",
        },
      },
      {
        path: "M4269.2 1800H4250.96V1796.6L4254 1794.6C4260.4 1790.48 4265 1786.48 4265 1780.48C4265 1777.28 4263.32 1775.36 4260.16 1775.36C4257.12 1775.36 4255.04 1777.28 4255.04 1780.8C4255.04 1781.52 4255.08 1782.12 4255.16 1782.64L4251.12 1782.12C4251.08 1781.72 4251.04 1781.2 4251.04 1780.48C4251.04 1775.24 4254.92 1772.04 4260.2 1772.04C4265.76 1772.04 4269.2 1775 4269.2 1780.28C4269.2 1787.08 4264.2 1791.6 4258.6 1795.16L4256.32 1796.6V1796.64H4269.2V1800ZM4291.54 1800H4273.3V1796.6L4276.34 1794.6C4282.74 1790.48 4287.34 1786.48 4287.34 1780.48C4287.34 1777.28 4285.66 1775.36 4282.5 1775.36C4279.46 1775.36 4277.38 1777.28 4277.38 1780.8C4277.38 1781.52 4277.42 1782.12 4277.5 1782.64L4273.46 1782.12C4273.42 1781.72 4273.38 1781.2 4273.38 1780.48C4273.38 1775.24 4277.26 1772.04 4282.54 1772.04C4288.1 1772.04 4291.54 1775 4291.54 1780.28C4291.54 1787.08 4286.54 1791.6 4280.94 1795.16L4278.66 1796.6V1796.64H4291.54V1800Z",
      },
    ],
  },
  {
    type: E_GolfSeatType.Regular,
    name: E_GolfBay.LG01,
    svgConfig: [
      {
        path: "M1832.41 1810.91C1834.28 1807.33 1838.12 1805.23 1842.14 1805.58L1918.92 1812.24C1925.93 1812.84 1930.14 1820.31 1927.03 1826.62L1844.98 1992.97C1843.18 1996.61 1839.35 1998.81 1835.29 1998.52L1753.21 1992.6C1746.02 1992.08 1741.73 1984.37 1745.06 1977.99L1832.41 1810.91Z",
      },
      {
        path: "M1822.16 1922.48C1815.2 1922.48 1812 1916.04 1812 1908.28C1812 1900.24 1815.2 1894.04 1822.16 1894.04C1829.12 1894.04 1832.36 1900.24 1832.36 1908.28C1832.36 1916.28 1829.12 1922.48 1822.16 1922.48ZM1822.16 1919.16C1826.48 1919.16 1828.2 1914.6 1828.2 1908.28C1828.2 1901.92 1826.48 1897.36 1822.16 1897.36C1817.88 1897.36 1816.16 1901.92 1816.16 1908.28C1816.16 1914.6 1817.88 1919.16 1822.16 1919.16ZM1846.05 1922H1842.05V1903.24H1835.37V1900.32H1835.73C1840.21 1900.32 1842.49 1899 1842.69 1894.76L1842.73 1894.48H1846.05V1922Z",
      },
    ],
  },
  {
    type: E_GolfSeatType.Regular,
    name: E_GolfBay.LG02,
    svgConfig: [
      {
        path: "M1945.46 1820.06C1947.29 1816.4 1951.18 1814.23 1955.25 1814.59L2032.23 1821.26C2039.15 1821.86 2043.37 1829.18 2040.42 1835.47L1962.43 2001.75C1960.67 2005.51 1956.76 2007.79 1952.62 2007.47L1869.87 2001.14C1862.78 2000.6 1858.5 1993.03 1861.7 1986.68L1945.46 1820.06Z",
      },
      {
        path: "M1937.16 1931.48C1930.2 1931.48 1927 1925.04 1927 1917.28C1927 1909.24 1930.2 1903.04 1937.16 1903.04C1944.12 1903.04 1947.36 1909.24 1947.36 1917.28C1947.36 1925.28 1944.12 1931.48 1937.16 1931.48ZM1937.16 1928.16C1941.48 1928.16 1943.2 1923.6 1943.2 1917.28C1943.2 1910.92 1941.48 1906.36 1937.16 1906.36C1932.88 1906.36 1931.16 1910.92 1931.16 1917.28C1931.16 1923.6 1932.88 1928.16 1937.16 1928.16ZM1969.69 1931H1951.45V1927.6L1954.49 1925.6C1960.89 1921.48 1965.49 1917.48 1965.49 1911.48C1965.49 1908.28 1963.81 1906.36 1960.65 1906.36C1957.61 1906.36 1955.53 1908.28 1955.53 1911.8C1955.53 1912.52 1955.57 1913.12 1955.65 1913.64L1951.61 1913.12C1951.57 1912.72 1951.53 1912.2 1951.53 1911.48C1951.53 1906.24 1955.41 1903.04 1960.69 1903.04C1966.25 1903.04 1969.69 1906 1969.69 1911.28C1969.69 1918.08 1964.69 1922.6 1959.09 1926.16L1956.81 1927.6V1927.64H1969.69V1931Z",
      },
    ],
  },
  {
    type: E_GolfSeatType.Regular,
    name: E_GolfBay.LG03,
    svgConfig: [
      {
        path: "M2057.55 1830.34C2059.32 1826.53 2063.28 1824.24 2067.46 1824.6L2145.53 1831.3C2152.37 1831.89 2156.6 1839.04 2153.82 1845.32L2080.36 2011.04C2078.65 2014.91 2074.68 2017.28 2070.45 2016.96L1988.39 2010.61C1981.43 2010.07 1977.15 2002.75 1980.1 1996.42L2057.55 1830.34Z",
      },
      {
        path: "M2054.16 1940.48C2047.2 1940.48 2044 1934.04 2044 1926.28C2044 1918.24 2047.2 1912.04 2054.16 1912.04C2061.12 1912.04 2064.36 1918.24 2064.36 1926.28C2064.36 1934.28 2061.12 1940.48 2054.16 1940.48ZM2054.16 1937.16C2058.48 1937.16 2060.2 1932.6 2060.2 1926.28C2060.2 1919.92 2058.48 1915.36 2054.16 1915.36C2049.88 1915.36 2048.16 1919.92 2048.16 1926.28C2048.16 1932.6 2049.88 1937.16 2054.16 1937.16ZM2078.09 1940.48C2072.29 1940.48 2068.49 1937.24 2068.17 1931.84L2072.25 1931.2C2072.49 1935.08 2074.77 1937.16 2078.17 1937.16C2081.77 1937.16 2083.77 1935.08 2083.77 1932.12C2083.77 1929.32 2081.89 1927.36 2078.01 1927.36C2076.77 1927.36 2075.89 1927.48 2075.25 1927.52V1924C2075.89 1924.04 2076.73 1924.08 2077.93 1924.08C2081.01 1924.08 2083.09 1922.32 2083.09 1919.56C2083.09 1917.12 2081.33 1915.36 2078.29 1915.36C2074.93 1915.36 2072.85 1917.48 2072.65 1921.04L2068.61 1920.32C2068.85 1915.2 2072.77 1912.04 2078.33 1912.04C2083.57 1912.04 2087.25 1914.72 2087.25 1919.36C2087.25 1922.92 2084.93 1924.64 2082.77 1925.44V1925.52C2085.65 1926.24 2087.93 1928.6 2087.93 1932.16C2087.93 1937.2 2083.97 1940.48 2078.09 1940.48Z",
      },
    ],
  },
  {
    type: E_GolfSeatType.Regular,
    name: E_GolfBay.LG04,
    svgConfig: [
      {
        path: "M2170.61 1840.52C2172.34 1836.62 2176.35 1834.25 2180.61 1834.61L2258.8 1841.32C2265.57 1841.9 2269.8 1848.91 2267.16 1855.17L2197.78 2019.89C2196.12 2023.84 2192.12 2026.29 2187.85 2025.98L2105.68 2020.03C2098.78 2019.53 2094.46 2012.33 2097.26 2006L2170.61 1840.52Z",
      },
      {
        path: "M2171.16 1949.48C2164.2 1949.48 2161 1943.04 2161 1935.28C2161 1927.24 2164.2 1921.04 2171.16 1921.04C2178.12 1921.04 2181.36 1927.24 2181.36 1935.28C2181.36 1943.28 2178.12 1949.48 2171.16 1949.48ZM2171.16 1946.16C2175.48 1946.16 2177.2 1941.6 2177.2 1935.28C2177.2 1928.92 2175.48 1924.36 2171.16 1924.36C2166.88 1924.36 2165.16 1928.92 2165.16 1935.28C2165.16 1941.6 2166.88 1946.16 2171.16 1946.16ZM2201.65 1939.48H2206.13V1942.96H2201.65V1949H2197.65V1942.96H2184.53V1939L2195.57 1921.48H2201.65V1939.48ZM2197.65 1924.76L2188.41 1939.48H2197.65V1924.76Z",
      },
    ],
  },
  {
    type: E_GolfSeatType.Regular,
    name: E_GolfBay.LG05,
    svgConfig: [
      {
        path: "M2282.69 1850.72C2284.36 1846.72 2288.43 1844.25 2292.76 1844.62L2372.13 1851.36C2378.8 1851.93 2383.04 1858.76 2380.59 1864.99L2316.19 2028.66C2314.59 2032.73 2310.53 2035.29 2306.17 2034.98L2222.39 2028.99C2215.56 2028.5 2211.23 2021.47 2213.87 2015.16L2282.69 1850.72Z",
      },
      {
        path: "M2285.16 1958.48C2278.2 1958.48 2275 1952.04 2275 1944.28C2275 1936.24 2278.2 1930.04 2285.16 1930.04C2292.12 1930.04 2295.36 1936.24 2295.36 1944.28C2295.36 1952.28 2292.12 1958.48 2285.16 1958.48ZM2285.16 1955.16C2289.48 1955.16 2291.2 1950.6 2291.2 1944.28C2291.2 1937.92 2289.48 1933.36 2285.16 1933.36C2280.88 1933.36 2279.16 1937.92 2279.16 1944.28C2279.16 1950.6 2280.88 1955.16 2285.16 1955.16ZM2309.85 1939.36C2315.29 1939.36 2318.77 1943.28 2318.77 1948.64C2318.77 1954.28 2314.57 1958.48 2308.85 1958.48C2303.33 1958.48 2299.93 1955.24 2299.09 1950.68L2303.21 1949.88C2303.77 1953.04 2305.69 1955.16 2308.93 1955.16C2312.37 1955.16 2314.57 1952.56 2314.57 1948.72C2314.57 1945.2 2312.65 1942.68 2309.25 1942.68C2306.45 1942.68 2304.53 1944.68 2303.93 1946.24L2300.17 1945.52L2301.25 1930.48H2316.77V1934.08H2304.61L2304.05 1941.6H2304.09C2305.17 1940.48 2307.13 1939.36 2309.85 1939.36Z",
      },
    ],
  },
  {
    type: E_GolfSeatType.Regular,
    name: E_GolfBay.LG06,
    svgConfig: [
      {
        path: "M2395.77 1860.06C2397.38 1855.89 2401.58 1853.29 2406.04 1853.72L2485.52 1861.27C2492.07 1861.89 2496.25 1868.55 2493.95 1874.71L2433.14 2037.9C2431.58 2042.1 2427.42 2044.75 2422.95 2044.38L2340.83 2037.6C2334.17 2037.05 2329.92 2030.25 2332.33 2024.03L2395.77 1860.06Z",
      },
      {
        path: "M2404.16 1967.48C2397.2 1967.48 2394 1961.04 2394 1953.28C2394 1945.24 2397.2 1939.04 2404.16 1939.04C2411.12 1939.04 2414.36 1945.24 2414.36 1953.28C2414.36 1961.28 2411.12 1967.48 2404.16 1967.48ZM2404.16 1964.16C2408.48 1964.16 2410.2 1959.6 2410.2 1953.28C2410.2 1946.92 2408.48 1942.36 2404.16 1942.36C2399.88 1942.36 2398.16 1946.92 2398.16 1953.28C2398.16 1959.6 2399.88 1964.16 2404.16 1964.16ZM2428.61 1967.48C2421.61 1967.48 2418.53 1961.68 2418.53 1953.84C2418.53 1944.24 2422.65 1939.04 2429.09 1939.04C2434.13 1939.04 2436.97 1942.08 2437.69 1946.04L2433.77 1946.8C2433.37 1944.08 2431.77 1942.28 2428.93 1942.28C2424.21 1942.28 2422.49 1947.2 2422.45 1953.04L2422.49 1953.08C2423.45 1951.2 2425.69 1949.32 2429.17 1949.32C2434.69 1949.32 2438.09 1953.16 2438.09 1958.24C2438.09 1963.56 2434.17 1967.48 2428.61 1967.48ZM2428.73 1964.16C2431.77 1964.16 2434.05 1961.96 2434.05 1958.32C2434.05 1954.56 2431.69 1952.64 2428.73 1952.64C2425.57 1952.64 2423.21 1955.08 2423.21 1958.28C2423.21 1961.76 2425.53 1964.16 2428.73 1964.16Z",
      },
    ],
  },
  {
    type: E_GolfSeatType.Regular,
    name: E_GolfBay.LG07,
    svgConfig: [
      {
        path: "M2509.33 1870.21C2510.9 1865.96 2515.14 1863.3 2519.66 1863.73L2598.34 1871.28C2604.79 1871.9 2608.95 1878.4 2606.83 1884.52L2550.52 2046.75C2549.03 2051.03 2544.86 2053.77 2540.35 2053.45L2457.29 2047.46C2450.64 2046.98 2446.31 2040.26 2448.63 2034.01L2509.33 1870.21Z",
      },
      {
        path: "M2518.16 1976.48C2511.2 1976.48 2508 1970.04 2508 1962.28C2508 1954.24 2511.2 1948.04 2518.16 1948.04C2525.12 1948.04 2528.36 1954.24 2528.36 1962.28C2528.36 1970.28 2525.12 1976.48 2518.16 1976.48ZM2518.16 1973.16C2522.48 1973.16 2524.2 1968.6 2524.2 1962.28C2524.2 1955.92 2522.48 1951.36 2518.16 1951.36C2513.88 1951.36 2512.16 1955.92 2512.16 1962.28C2512.16 1968.6 2513.88 1973.16 2518.16 1973.16ZM2531.65 1955.76V1948.48H2551.37V1951.56C2546.81 1959.36 2543.13 1967.68 2541.33 1976H2536.97C2538.81 1967.92 2543.09 1958.8 2547.41 1951.96H2535.53V1955.76H2531.65Z",
      },
    ],
  },
  {
    type: E_GolfSeatType.Regular,
    name: E_GolfBay.LG08,
    svgConfig: [
      {
        path: "M2623.43 1880.32C2624.94 1876.01 2629.18 1873.27 2633.73 1873.66L2712.45 1880.42C2718.87 1880.97 2723.1 1887.35 2721.11 1893.48L2668.42 2055.55C2667 2059.93 2662.78 2062.77 2658.19 2062.44L2575.05 2056.44C2568.47 2055.97 2564.15 2049.38 2566.33 2043.16L2623.43 1880.32Z",
      },
      {
        path: "M2635.16 1985.48C2628.2 1985.48 2625 1979.04 2625 1971.28C2625 1963.24 2628.2 1957.04 2635.16 1957.04C2642.12 1957.04 2645.36 1963.24 2645.36 1971.28C2645.36 1979.28 2642.12 1985.48 2635.16 1985.48ZM2635.16 1982.16C2639.48 1982.16 2641.2 1977.6 2641.2 1971.28C2641.2 1964.92 2639.48 1960.36 2635.16 1960.36C2630.88 1960.36 2629.16 1964.92 2629.16 1971.28C2629.16 1977.6 2630.88 1982.16 2635.16 1982.16ZM2659.33 1985.48C2653.53 1985.48 2649.45 1982.4 2649.45 1977.36C2649.45 1973.16 2652.25 1971.16 2654.53 1970.32V1970.28C2652.85 1969.64 2650.33 1967.64 2650.33 1964.36C2650.33 1959.88 2654.25 1957 2659.33 1957C2664.37 1957 2668.33 1959.88 2668.33 1964.36C2668.33 1967.64 2665.81 1969.64 2664.09 1970.28V1970.32C2666.37 1971.16 2669.17 1973.16 2669.17 1977.36C2669.17 1982.4 2665.13 1985.48 2659.33 1985.48ZM2659.33 1968.96C2662.21 1968.96 2664.25 1967.08 2664.25 1964.6C2664.25 1962.16 2662.29 1960.28 2659.33 1960.28C2656.33 1960.28 2654.37 1962.16 2654.37 1964.6C2654.37 1967.08 2656.45 1968.96 2659.33 1968.96ZM2659.33 1982.16C2662.81 1982.16 2665.09 1980.12 2665.09 1977.12C2665.09 1974.2 2662.81 1972.16 2659.33 1972.16C2655.85 1972.16 2653.53 1974.2 2653.53 1977.12C2653.53 1980.12 2655.85 1982.16 2659.33 1982.16Z",
      },
    ],
  },
  {
    type: E_GolfSeatType.Regular,
    name: E_GolfBay.LG09,
    svgConfig: [
      {
        path: "M2735.57 1890.6C2736.99 1886.15 2741.3 1883.28 2745.95 1883.68L2825.7 1890.45C2832.03 1890.99 2836.26 1897.23 2834.42 1903.32L2785.34 2065.27C2783.98 2069.78 2779.65 2072.72 2774.95 2072.34L2692.53 2065.53C2686.12 2065.01 2681.87 2058.65 2683.83 2052.52L2735.57 1890.6Z",
      },
      {
        path: "M2750.16 1994.48C2743.2 1994.48 2740 1988.04 2740 1980.28C2740 1972.24 2743.2 1966.04 2750.16 1966.04C2757.12 1966.04 2760.36 1972.24 2760.36 1980.28C2760.36 1988.28 2757.12 1994.48 2750.16 1994.48ZM2750.16 1991.16C2754.48 1991.16 2756.2 1986.6 2756.2 1980.28C2756.2 1973.92 2754.48 1969.36 2750.16 1969.36C2745.88 1969.36 2744.16 1973.92 2744.16 1980.28C2744.16 1986.6 2745.88 1991.16 2750.16 1991.16ZM2773.57 1994.48C2768.49 1994.48 2765.65 1991.44 2764.97 1987.48L2768.89 1986.72C2769.29 1989.44 2770.89 1991.24 2773.73 1991.24C2778.45 1991.24 2780.13 1986.32 2780.21 1980.52L2780.17 1980.48C2779.17 1982.32 2776.97 1984.2 2773.49 1984.2C2767.97 1984.2 2764.57 1980.36 2764.57 1975.28C2764.57 1969.96 2768.45 1966.04 2774.05 1966.04C2781.05 1966.04 2784.13 1971.84 2784.13 1979.68C2784.13 1989.28 2780.01 1994.48 2773.57 1994.48ZM2773.93 1980.88C2777.05 1980.88 2779.45 1978.44 2779.45 1975.24C2779.45 1971.8 2777.13 1969.36 2773.93 1969.36C2770.89 1969.36 2768.57 1971.56 2768.57 1975.2C2768.57 1978.96 2770.93 1980.88 2773.93 1980.88Z",
      },
    ],
  },
  {
    type: E_GolfSeatType.Regular,
    name: E_GolfBay.LG10,
    svgConfig: [
      {
        path: "M2849.67 1900.79C2851.03 1896.25 2855.38 1893.29 2860.1 1893.69L2939.99 1900.48C2946.23 1901.01 2950.45 1907.08 2948.78 1913.11L2904.21 2074.03C2902.93 2078.65 2898.54 2081.72 2893.76 2081.33L2810.3 2074.51C2803.97 2073.99 2799.72 2067.76 2801.54 2061.67L2849.67 1900.79Z",
      },
      {
        path: "M2866.56 2003H2862.56V1984.24H2855.88V1981.32H2856.24C2860.72 1981.32 2863 1980 2863.2 1975.76L2863.24 1975.48H2866.56V2003ZM2882.16 2003.48C2875.2 2003.48 2872 1997.04 2872 1989.28C2872 1981.24 2875.2 1975.04 2882.16 1975.04C2889.12 1975.04 2892.36 1981.24 2892.36 1989.28C2892.36 1997.28 2889.12 2003.48 2882.16 2003.48ZM2882.16 2000.16C2886.48 2000.16 2888.2 1995.6 2888.2 1989.28C2888.2 1982.92 2886.48 1978.36 2882.16 1978.36C2877.88 1978.36 2876.16 1982.92 2876.16 1989.28C2876.16 1995.6 2877.88 2000.16 2882.16 2000.16Z",
      },
    ],
  },
  {
    type: E_GolfSeatType.Regular,
    name: E_GolfBay.LG11,
    svgConfig: [
      {
        path: "M2962.81 1911.03C2964.08 1906.38 2968.49 1903.29 2973.29 1903.7L3054.28 1910.51C3060.42 1911.03 3064.64 1916.93 3063.14 1922.91L3023.06 2082.78C3021.87 2087.52 3017.43 2090.71 3012.55 2090.32L2928.01 2083.47C2921.77 2082.97 2917.52 2076.91 2919.17 2070.87L2962.81 1911.03Z",
      },
      {
        path: "M2987.56 2012H2983.56V1993.24H2976.88V1990.32H2977.24C2981.72 1990.32 2984 1989 2984.2 1984.76L2984.24 1984.48H2987.56V2012ZM3002.56 2012H2998.56V1993.24H2991.88V1990.32H2992.24C2996.72 1990.32 2999 1989 2999.2 1984.76L2999.24 1984.48H3002.56V2012Z",
      },
    ],
  },
  {
    type: E_GolfSeatType.Regular,
    name: E_GolfBay.LG12,
    svgConfig: [
      {
        path: "M3077.93 1921.26C3079.12 1916.5 3083.59 1913.31 3088.48 1913.73L3167.47 1920.51C3173.55 1921.03 3177.75 1926.83 3176.35 1932.76L3138.95 2091.69C3137.83 2096.48 3133.39 2099.73 3128.49 2099.37L3046.86 2093.37C3040.67 2092.92 3036.38 2086.99 3037.89 2080.97L3077.93 1921.26Z",
      },
      {
        path: "M3098.56 2021H3094.56V2002.24H3087.88V1999.32H3088.24C3092.72 1999.32 3095 1998 3095.2 1993.76L3095.24 1993.48H3098.56V2021ZM3122.2 2021H3103.96V2017.6L3107 2015.6C3113.4 2011.48 3118 2007.48 3118 2001.48C3118 1998.28 3116.32 1996.36 3113.16 1996.36C3110.12 1996.36 3108.04 1998.28 3108.04 2001.8C3108.04 2002.52 3108.08 2003.12 3108.16 2003.64L3104.12 2003.12C3104.08 2002.72 3104.04 2002.2 3104.04 2001.48C3104.04 1996.24 3107.92 1993.04 3113.2 1993.04C3118.76 1993.04 3122.2 1996 3122.2 2001.28C3122.2 2008.08 3117.2 2012.6 3111.6 2016.16L3109.32 2017.6V2017.64H3122.2V2021Z",
      },
    ],
  },
  {
    type: E_GolfSeatType.Regular,
    name: E_GolfBay.LG13,
    svgConfig: [
      {
        path: "M3191.06 1931.45C3192.17 1926.6 3196.68 1923.31 3201.64 1923.73L3282.74 1930.55C3288.73 1931.06 3292.92 1936.68 3291.69 1942.56L3258.78 2100.44C3257.76 2105.34 3253.27 2108.73 3248.28 2108.38L3163.65 2102.33C3157.53 2101.89 3153.24 2096.1 3154.61 2090.12L3191.06 1931.45Z",
      },
      {
        path: "M3214.56 2030H3210.56V2011.24H3203.88V2008.32H3204.24C3208.72 2008.32 3211 2007 3211.2 2002.76L3211.24 2002.48H3214.56V2030ZM3229.6 2030.48C3223.8 2030.48 3220 2027.24 3219.68 2021.84L3223.76 2021.2C3224 2025.08 3226.28 2027.16 3229.68 2027.16C3233.28 2027.16 3235.28 2025.08 3235.28 2022.12C3235.28 2019.32 3233.4 2017.36 3229.52 2017.36C3228.28 2017.36 3227.4 2017.48 3226.76 2017.52V2014C3227.4 2014.04 3228.24 2014.08 3229.44 2014.08C3232.52 2014.08 3234.6 2012.32 3234.6 2009.56C3234.6 2007.12 3232.84 2005.36 3229.8 2005.36C3226.44 2005.36 3224.36 2007.48 3224.16 2011.04L3220.12 2010.32C3220.36 2005.2 3224.28 2002.04 3229.84 2002.04C3235.08 2002.04 3238.76 2004.72 3238.76 2009.36C3238.76 2012.92 3236.44 2014.64 3234.28 2015.44V2015.52C3237.16 2016.24 3239.44 2018.6 3239.44 2022.16C3239.44 2027.2 3235.48 2030.48 3229.6 2030.48Z",
      },
    ],
  },
  {
    type: E_GolfSeatType.Regular,
    name: E_GolfBay.LG14,
    svgConfig: [
      {
        path: "M3305.2 1940.69C3306.23 1935.73 3310.8 1932.32 3315.84 1932.75L3396 1939.56C3401.89 1940.07 3406.06 1945.54 3404.98 1951.36L3375.63 2109.21C3374.7 2114.22 3370.16 2117.72 3365.08 2117.36L3281.39 2111.32C3275.36 2110.89 3271.09 2105.24 3272.32 2099.32L3305.2 1940.69Z",
      },
      {
        path: "M3329.56 2040H3325.56V2021.24H3318.88V2018.32H3319.24C3323.72 2018.32 3326 2017 3326.2 2012.76L3326.24 2012.48H3329.56V2040ZM3351.16 2030.48H3355.64V2033.96H3351.16V2040H3347.16V2033.96H3334.04V2030L3345.08 2012.48H3351.16V2030.48ZM3347.16 2015.76L3337.92 2030.48H3347.16V2015.76Z",
      },
    ],
  },
  {
    type: E_GolfSeatType.Regular,
    name: E_GolfBay.LG15,
    svgConfig: [
      {
        path: "M3419.41 1951C3420.31 1945.9 3424.95 1942.34 3430.1 1942.77L3510.23 1949.58C3516.04 1950.08 3520.19 1955.41 3519.25 1961.17L3493.48 2118.48C3492.64 2123.59 3488.04 2127.21 3482.88 2126.83L3400.55 2120.81C3394.64 2120.38 3390.4 2114.93 3391.43 2109.09L3419.41 1951Z",
      },
      {
        path: "M3446.56 2048H3442.56V2029.24H3435.88V2026.32H3436.24C3440.72 2026.32 3443 2025 3443.2 2020.76L3443.24 2020.48H3446.56V2048ZM3462.36 2029.36C3467.8 2029.36 3471.28 2033.28 3471.28 2038.64C3471.28 2044.28 3467.08 2048.48 3461.36 2048.48C3455.84 2048.48 3452.44 2045.24 3451.6 2040.68L3455.72 2039.88C3456.28 2043.04 3458.2 2045.16 3461.44 2045.16C3464.88 2045.16 3467.08 2042.56 3467.08 2038.72C3467.08 2035.2 3465.16 2032.68 3461.76 2032.68C3458.96 2032.68 3457.04 2034.68 3456.44 2036.24L3452.68 2035.52L3453.76 2020.48H3469.28V2024.08H3457.12L3456.56 2031.6H3456.6C3457.68 2030.48 3459.64 2029.36 3462.36 2029.36Z",
      },
    ],
  },
  {
    type: E_GolfSeatType.Regular,
    name: E_GolfBay.LG16,
    svgConfig: [
      {
        path: "M3534.03 1961.16C3534.86 1955.99 3539.53 1952.34 3544.74 1952.79L3624.96 1959.6C3630.68 1960.09 3634.82 1965.29 3634.01 1970.97L3611.81 2127.26C3611.07 2132.46 3606.43 2136.21 3601.19 2135.83L3517.88 2129.79C3512.03 2129.36 3507.8 2124.02 3508.73 2118.23L3534.03 1961.16Z",
      },
      {
        path: "M3562.56 2057H3558.56V2038.24H3551.88V2035.32H3552.24C3556.72 2035.32 3559 2034 3559.2 2029.76L3559.24 2029.48H3562.56V2057ZM3578.12 2057.48C3571.12 2057.48 3568.04 2051.68 3568.04 2043.84C3568.04 2034.24 3572.16 2029.04 3578.6 2029.04C3583.64 2029.04 3586.48 2032.08 3587.2 2036.04L3583.28 2036.8C3582.88 2034.08 3581.28 2032.28 3578.44 2032.28C3573.72 2032.28 3572 2037.2 3571.96 2043.04L3572 2043.08C3572.96 2041.2 3575.2 2039.32 3578.68 2039.32C3584.2 2039.32 3587.6 2043.16 3587.6 2048.24C3587.6 2053.56 3583.68 2057.48 3578.12 2057.48ZM3578.24 2054.16C3581.28 2054.16 3583.56 2051.96 3583.56 2048.32C3583.56 2044.56 3581.2 2042.64 3578.24 2042.64C3575.08 2042.64 3572.72 2045.08 3572.72 2048.28C3572.72 2051.76 3575.04 2054.16 3578.24 2054.16Z",
      },
    ],
  },
  {
    type: E_GolfSeatType.Regular,
    name: E_GolfBay.LG17,
    svgConfig: [
      {
        path: "M3647.24 1971.44C3647.95 1966.15 3652.68 1962.35 3657.99 1962.8L3739.18 1969.63C3744.82 1970.11 3748.94 1975.16 3748.27 1980.78L3729.64 2136.93C3729 2142.27 3724.26 2146.15 3718.89 2145.71L3635.49 2138.86C3629.78 2138.39 3625.64 2133.24 3626.39 2127.57L3647.24 1971.44Z",
      },
      {
        path: "M3679.06 2066H3675.06V2047.24H3668.38V2044.32H3668.74C3673.22 2044.32 3675.5 2043 3675.7 2038.76L3675.74 2038.48H3679.06V2066ZM3683.66 2045.76V2038.48H3703.38V2041.56C3698.82 2049.36 3695.14 2057.68 3693.34 2066H3688.98C3690.82 2057.92 3695.1 2048.8 3699.42 2041.96H3687.54V2045.76H3683.66Z",
      },
    ],
  },
  {
    type: E_GolfSeatType.Regular,
    name: E_GolfBay.LG18,
    svgConfig: [
      {
        path: "M3762.37 1981.61C3763 1976.25 3767.77 1972.36 3773.14 1972.81L3854.41 1979.65C3859.96 1980.12 3864.06 1985.04 3863.52 1990.58L3848.45 2145.7C3847.92 2151.14 3843.13 2155.14 3837.69 2154.7L3753.33 2147.84C3747.68 2147.38 3743.55 2142.33 3744.21 2136.71L3762.37 1981.61Z",
      },
      {
        path: "M3795.56 2076H3791.56V2057.24H3784.88V2054.32H3785.24C3789.72 2054.32 3792 2053 3792.2 2048.76L3792.24 2048.48H3795.56V2076ZM3810.84 2076.48C3805.04 2076.48 3800.96 2073.4 3800.96 2068.36C3800.96 2064.16 3803.76 2062.16 3806.04 2061.32V2061.28C3804.36 2060.64 3801.84 2058.64 3801.84 2055.36C3801.84 2050.88 3805.76 2048 3810.84 2048C3815.88 2048 3819.84 2050.88 3819.84 2055.36C3819.84 2058.64 3817.32 2060.64 3815.6 2061.28V2061.32C3817.88 2062.16 3820.68 2064.16 3820.68 2068.36C3820.68 2073.4 3816.64 2076.48 3810.84 2076.48ZM3810.84 2059.96C3813.72 2059.96 3815.76 2058.08 3815.76 2055.6C3815.76 2053.16 3813.8 2051.28 3810.84 2051.28C3807.84 2051.28 3805.88 2053.16 3805.88 2055.6C3805.88 2058.08 3807.96 2059.96 3810.84 2059.96ZM3810.84 2073.16C3814.32 2073.16 3816.6 2071.12 3816.6 2068.12C3816.6 2065.2 3814.32 2063.16 3810.84 2063.16C3807.36 2063.16 3805.04 2065.2 3805.04 2068.12C3805.04 2071.12 3807.36 2073.16 3810.84 2073.16Z",
      },
    ],
  },
  {
    type: E_GolfSeatType.Regular,
    name: E_GolfBay.LG19,
    svgConfig: [
      {
        path: "M3876.57 1991.85C3877.09 1986.39 3881.9 1982.37 3887.36 1982.82L3969.62 1989.68C3975.09 1990.13 3979.17 1994.91 3978.76 2000.39L3967.24 2154.56C3966.83 2160.06 3962.06 2164.18 3956.56 2163.79L3871.21 2157.73C3865.61 2157.33 3861.43 2152.4 3861.96 2146.81L3876.57 1991.85Z",
      },
      {
        path: "M3912.56 2085H3908.56V2066.24H3901.88V2063.32H3902.24C3906.72 2063.32 3909 2062 3909.2 2057.76L3909.24 2057.48H3912.56V2085ZM3927.08 2085.48C3922 2085.48 3919.16 2082.44 3918.48 2078.48L3922.4 2077.72C3922.8 2080.44 3924.4 2082.24 3927.24 2082.24C3931.96 2082.24 3933.64 2077.32 3933.72 2071.52L3933.68 2071.48C3932.68 2073.32 3930.48 2075.2 3927 2075.2C3921.48 2075.2 3918.08 2071.36 3918.08 2066.28C3918.08 2060.96 3921.96 2057.04 3927.56 2057.04C3934.56 2057.04 3937.64 2062.84 3937.64 2070.68C3937.64 2080.28 3933.52 2085.48 3927.08 2085.48ZM3927.44 2071.88C3930.56 2071.88 3932.96 2069.44 3932.96 2066.24C3932.96 2062.8 3930.64 2060.36 3927.44 2060.36C3924.4 2060.36 3922.08 2062.56 3922.08 2066.2C3922.08 2069.96 3924.44 2071.88 3927.44 2071.88Z",
      },
    ],
  },
  {
    type: E_GolfSeatType.Regular,
    name: E_GolfBay.LG20,
    svgConfig: [
      {
        path: "M3990.78 2002.09C3991.17 1996.54 3996.03 1992.38 4001.58 1992.84L4083.84 1999.7C4089.22 2000.14 4093.28 2004.79 4093 2010.18L4085.03 2163.32C4084.74 2168.91 4079.92 2173.17 4074.33 2172.78L3988.98 2166.71C3983.47 2166.32 3979.32 2161.53 3979.72 2156.02L3990.78 2002.09Z",
      },
      {
        path: "M4036.7 2094H4018.46V2090.6L4021.5 2088.6C4027.9 2084.48 4032.5 2080.48 4032.5 2074.48C4032.5 2071.28 4030.82 2069.36 4027.66 2069.36C4024.62 2069.36 4022.54 2071.28 4022.54 2074.8C4022.54 2075.52 4022.58 2076.12 4022.66 2076.64L4018.62 2076.12C4018.58 2075.72 4018.54 2075.2 4018.54 2074.48C4018.54 2069.24 4022.42 2066.04 4027.7 2066.04C4033.26 2066.04 4036.7 2069 4036.7 2074.28C4036.7 2081.08 4031.7 2085.6 4026.1 2089.16L4023.82 2090.6V2090.64H4036.7V2094ZM4051 2094.48C4044.04 2094.48 4040.84 2088.04 4040.84 2080.28C4040.84 2072.24 4044.04 2066.04 4051 2066.04C4057.96 2066.04 4061.2 2072.24 4061.2 2080.28C4061.2 2088.28 4057.96 2094.48 4051 2094.48ZM4051 2091.16C4055.32 2091.16 4057.04 2086.6 4057.04 2080.28C4057.04 2073.92 4055.32 2069.36 4051 2069.36C4046.72 2069.36 4045 2073.92 4045 2080.28C4045 2086.6 4046.72 2091.16 4051 2091.16Z",
      },
    ],
  },
  {
    type: E_GolfSeatType.Regular,
    name: E_GolfBay.LG21,
    svgConfig: [
      {
        path: "M4105.94 2012.27C4106.24 2006.65 4111.14 2002.39 4116.75 2002.85L4199.06 2009.71C4204.35 2010.15 4208.38 2014.66 4208.23 2019.97L4203.8 2172.98C4203.64 2178.7 4198.72 2183.12 4193.01 2182.66L4106.72 2175.78C4101.32 2175.34 4097.24 2170.68 4097.53 2165.26L4105.94 2012.27Z",
      },
      {
        path: "M4153.7 2103H4135.46V2099.6L4138.5 2097.6C4144.9 2093.48 4149.5 2089.48 4149.5 2083.48C4149.5 2080.28 4147.82 2078.36 4144.66 2078.36C4141.62 2078.36 4139.54 2080.28 4139.54 2083.8C4139.54 2084.52 4139.58 2085.12 4139.66 2085.64L4135.62 2085.12C4135.58 2084.72 4135.54 2084.2 4135.54 2083.48C4135.54 2078.24 4139.42 2075.04 4144.7 2075.04C4150.26 2075.04 4153.7 2078 4153.7 2083.28C4153.7 2090.08 4148.7 2094.6 4143.1 2098.16L4140.82 2099.6V2099.64H4153.7V2103ZM4167.4 2103H4163.4V2084.24H4156.72V2081.32H4157.08C4161.56 2081.32 4163.84 2080 4164.04 2075.76L4164.08 2075.48H4167.4V2103Z",
      },
    ],
  },
  {
    type: E_GolfSeatType.Regular,
    name: E_GolfBay.LG22,
    svgConfig: [
      {
        path: "M4221.16 2022.53C4221.35 2016.8 4226.29 2012.4 4232 2012.88L4313.23 2019.72C4318.46 2020.16 4322.45 2024.56 4322.39 2029.8L4320.62 2181.78C4320.56 2187.58 4315.59 2192.1 4309.81 2191.63L4225.49 2184.77C4220.17 2184.34 4216.13 2179.81 4216.3 2174.49L4221.16 2022.53Z",
      },
      {
        path: "M4269.7 2112H4251.46V2108.6L4254.5 2106.6C4260.9 2102.48 4265.5 2098.48 4265.5 2092.48C4265.5 2089.28 4263.82 2087.36 4260.66 2087.36C4257.62 2087.36 4255.54 2089.28 4255.54 2092.8C4255.54 2093.52 4255.58 2094.12 4255.66 2094.64L4251.62 2094.12C4251.58 2093.72 4251.54 2093.2 4251.54 2092.48C4251.54 2087.24 4255.42 2084.04 4260.7 2084.04C4266.26 2084.04 4269.7 2087 4269.7 2092.28C4269.7 2099.08 4264.7 2103.6 4259.1 2107.16L4256.82 2108.6V2108.64H4269.7V2112ZM4292.04 2112H4273.8V2108.6L4276.84 2106.6C4283.24 2102.48 4287.84 2098.48 4287.84 2092.48C4287.84 2089.28 4286.16 2087.36 4283 2087.36C4279.96 2087.36 4277.88 2089.28 4277.88 2092.8C4277.88 2093.52 4277.92 2094.12 4278 2094.64L4273.96 2094.12C4273.92 2093.72 4273.88 2093.2 4273.88 2092.48C4273.88 2087.24 4277.76 2084.04 4283.04 2084.04C4288.6 2084.04 4292.04 2087 4292.04 2092.28C4292.04 2099.08 4287.04 2103.6 4281.44 2107.16L4279.16 2108.6V2108.64H4292.04V2112Z",
      },
    ],
  },
  {
    type: E_GolfSeatType.Regular,
    name: E_GolfBay.LG23,
    svgConfig: [
      {
        path: "M4335.42 2030.72C4335.46 2025.31 4339.81 2020.92 4345.22 2020.81L4427.36 2019.2C4432.94 2019.09 4437.53 2023.56 4437.56 2029.14L4438.44 2181.13C4438.47 2186.6 4434.1 2191.08 4428.63 2191.19L4344.28 2192.8C4338.65 2192.91 4334.04 2188.35 4334.09 2182.72L4335.42 2030.72Z",
      },
      {
        path: "M4385.7 2117H4367.46V2113.6L4370.5 2111.6C4376.9 2107.48 4381.5 2103.48 4381.5 2097.48C4381.5 2094.28 4379.82 2092.36 4376.66 2092.36C4373.62 2092.36 4371.54 2094.28 4371.54 2097.8C4371.54 2098.52 4371.58 2099.12 4371.66 2099.64L4367.62 2099.12C4367.58 2098.72 4367.54 2098.2 4367.54 2097.48C4367.54 2092.24 4371.42 2089.04 4376.7 2089.04C4382.26 2089.04 4385.7 2092 4385.7 2097.28C4385.7 2104.08 4380.7 2108.6 4375.1 2112.16L4372.82 2113.6V2113.64H4385.7V2117ZM4399.44 2117.48C4393.64 2117.48 4389.84 2114.24 4389.52 2108.84L4393.6 2108.2C4393.84 2112.08 4396.12 2114.16 4399.52 2114.16C4403.12 2114.16 4405.12 2112.08 4405.12 2109.12C4405.12 2106.32 4403.24 2104.36 4399.36 2104.36C4398.12 2104.36 4397.24 2104.48 4396.6 2104.52V2101C4397.24 2101.04 4398.08 2101.08 4399.28 2101.08C4402.36 2101.08 4404.44 2099.32 4404.44 2096.56C4404.44 2094.12 4402.68 2092.36 4399.64 2092.36C4396.28 2092.36 4394.2 2094.48 4394 2098.04L4389.96 2097.32C4390.2 2092.2 4394.12 2089.04 4399.68 2089.04C4404.92 2089.04 4408.6 2091.72 4408.6 2096.36C4408.6 2099.92 4406.28 2101.64 4404.12 2102.44V2102.52C4407 2103.24 4409.28 2105.6 4409.28 2109.16C4409.28 2114.2 4405.32 2117.48 4399.44 2117.48Z",
      },
    ],
  },
  {
    type: E_GolfSeatType.Regular,
    name: E_GolfBay.LG24,
    svgConfig: [
      {
        path: "M4450.59 2028.89C4450.54 2023.41 4454.91 2018.91 4460.39 2018.81L4542.54 2017.2C4548.05 2017.09 4552.6 2021.45 4552.73 2026.96L4556.27 2179.87C4556.39 2185.44 4551.94 2190.04 4546.36 2190.1L4462.01 2190.9C4456.48 2190.96 4451.96 2186.52 4451.91 2180.99L4450.59 2028.89Z",
      },
      {
        path: "M4502.7 2115H4484.46V2111.6L4487.5 2109.6C4493.9 2105.48 4498.5 2101.48 4498.5 2095.48C4498.5 2092.28 4496.82 2090.36 4493.66 2090.36C4490.62 2090.36 4488.54 2092.28 4488.54 2095.8C4488.54 2096.52 4488.58 2097.12 4488.66 2097.64L4484.62 2097.12C4484.58 2096.72 4484.54 2096.2 4484.54 2095.48C4484.54 2090.24 4488.42 2087.04 4493.7 2087.04C4499.26 2087.04 4502.7 2090 4502.7 2095.28C4502.7 2102.08 4497.7 2106.6 4492.1 2110.16L4489.82 2111.6V2111.64H4502.7V2115ZM4522.69 2105.48H4527.17V2108.96H4522.69V2115H4518.69V2108.96H4505.57V2105L4516.61 2087.48H4522.69V2105.48ZM4518.69 2090.76L4509.45 2105.48H4518.69V2090.76Z",
      },
    ],
  },
  {
    type: E_GolfSeatType.Regular,
    name: E_GolfBay.LG25,
    svgConfig: [
      {
        path: "M4565.2 2027.01C4565.09 2021.48 4569.48 2016.91 4575.01 2016.8L4657.21 2015.19C4662.65 2015.09 4667.18 2019.35 4667.4 2024.79L4673.58 2177.69C4673.81 2183.33 4669.33 2188.04 4663.69 2188.1L4578.39 2188.91C4572.91 2188.96 4568.41 2184.59 4568.3 2179.11L4565.2 2027.01Z",
      },
      {
        path: "M4617.2 2113H4598.96V2109.6L4602 2107.6C4608.4 2103.48 4613 2099.48 4613 2093.48C4613 2090.28 4611.32 2088.36 4608.16 2088.36C4605.12 2088.36 4603.04 2090.28 4603.04 2093.8C4603.04 2094.52 4603.08 2095.12 4603.16 2095.64L4599.12 2095.12C4599.08 2094.72 4599.04 2094.2 4599.04 2093.48C4599.04 2088.24 4602.92 2085.04 4608.2 2085.04C4613.76 2085.04 4617.2 2088 4617.2 2093.28C4617.2 2100.08 4612.2 2104.6 4606.6 2108.16L4604.32 2109.6V2109.64H4617.2V2113ZM4631.7 2094.36C4637.14 2094.36 4640.62 2098.28 4640.62 2103.64C4640.62 2109.28 4636.42 2113.48 4630.7 2113.48C4625.18 2113.48 4621.78 2110.24 4620.94 2105.68L4625.06 2104.88C4625.62 2108.04 4627.54 2110.16 4630.78 2110.16C4634.22 2110.16 4636.42 2107.56 4636.42 2103.72C4636.42 2100.2 4634.5 2097.68 4631.1 2097.68C4628.3 2097.68 4626.38 2099.68 4625.78 2101.24L4622.02 2100.52L4623.1 2085.48H4638.62V2089.08H4626.46L4625.9 2096.6H4625.94C4627.02 2095.48 4628.98 2094.36 4631.7 2094.36Z",
      },
    ],
  },
  {
    type: E_GolfSeatType.Regular,
    name: E_GolfBay.LG26,
    svgConfig: [
      {
        path: "M4679.38 2025.17C4679.17 2019.58 4683.59 2014.91 4689.18 2014.8L4771.38 2013.19C4776.75 2013.08 4781.25 2017.24 4781.56 2022.61L4790.4 2175.62C4790.73 2181.29 4786.28 2186.09 4780.61 2186.2L4695.31 2187.81C4689.86 2187.92 4685.34 2183.64 4685.13 2178.19L4679.38 2025.17Z",
      },
      {
        path: "M4734.2 2111H4715.96V2107.6L4719 2105.6C4725.4 2101.48 4730 2097.48 4730 2091.48C4730 2088.28 4728.32 2086.36 4725.16 2086.36C4722.12 2086.36 4720.04 2088.28 4720.04 2091.8C4720.04 2092.52 4720.08 2093.12 4720.16 2093.64L4716.12 2093.12C4716.08 2092.72 4716.04 2092.2 4716.04 2091.48C4716.04 2086.24 4719.92 2083.04 4725.2 2083.04C4730.76 2083.04 4734.2 2086 4734.2 2091.28C4734.2 2098.08 4729.2 2102.6 4723.6 2106.16L4721.32 2107.6V2107.64H4734.2V2111ZM4748.46 2111.48C4741.46 2111.48 4738.38 2105.68 4738.38 2097.84C4738.38 2088.24 4742.5 2083.04 4748.94 2083.04C4753.98 2083.04 4756.82 2086.08 4757.54 2090.04L4753.62 2090.8C4753.22 2088.08 4751.62 2086.28 4748.78 2086.28C4744.06 2086.28 4742.34 2091.2 4742.3 2097.04L4742.34 2097.08C4743.3 2095.2 4745.54 2093.32 4749.02 2093.32C4754.54 2093.32 4757.94 2097.16 4757.94 2102.24C4757.94 2107.56 4754.02 2111.48 4748.46 2111.48ZM4748.58 2108.16C4751.62 2108.16 4753.9 2105.96 4753.9 2102.32C4753.9 2098.56 4751.54 2096.64 4748.58 2096.64C4745.42 2096.64 4743.06 2099.08 4743.06 2102.28C4743.06 2105.76 4745.38 2108.16 4748.58 2108.16Z",
      },
    ],
  },
  {
    type: E_GolfSeatType.Regular,
    name: E_GolfBay.LG27,
    svgConfig: [
      {
        path: "M4794.57 2023.34C4794.26 2017.69 4798.7 2012.91 4804.36 2012.8L4886.54 2011.19C4891.85 2011.08 4896.31 2015.14 4896.71 2020.43L4908.21 2173.45C4908.64 2179.18 4904.17 2184.09 4898.43 2184.2L4813.15 2185.82C4807.76 2185.92 4803.27 2181.74 4802.97 2176.37L4794.57 2023.34Z",
      },
      {
        path: "M4850.2 2109H4831.96V2105.6L4835 2103.6C4841.4 2099.48 4846 2095.48 4846 2089.48C4846 2086.28 4844.32 2084.36 4841.16 2084.36C4838.12 2084.36 4836.04 2086.28 4836.04 2089.8C4836.04 2090.52 4836.08 2091.12 4836.16 2091.64L4832.12 2091.12C4832.08 2090.72 4832.04 2090.2 4832.04 2089.48C4832.04 2084.24 4835.92 2081.04 4841.2 2081.04C4846.76 2081.04 4850.2 2084 4850.2 2089.28C4850.2 2096.08 4845.2 2100.6 4839.6 2104.16L4837.32 2105.6V2105.64H4850.2V2109ZM4853.5 2088.76V2081.48H4873.22V2084.56C4868.66 2092.36 4864.98 2100.68 4863.18 2109H4858.82C4860.66 2100.92 4864.94 2091.8 4869.26 2084.96H4857.38V2088.76H4853.5Z",
      },
    ],
  },
  {
    type: E_GolfSeatType.Regular,
    name: E_GolfBay.LG28,
    svgConfig: [
      {
        path: "M4908.76 2021.51C4908.35 2015.8 4912.81 2010.91 4918.54 2010.8L5001.7 2009.18C5006.94 2009.08 5011.37 2013.04 5011.85 2018.26L5026.01 2172.19C5026.54 2178.01 5021.99 2183.05 5016.14 2183.1L4929.89 2183.91C4924.61 2183.96 4920.2 2179.9 4919.82 2174.63L4908.76 2021.51Z",
      },
      {
        path: "M4967.2 2108H4948.96V2104.6L4952 2102.6C4958.4 2098.48 4963 2094.48 4963 2088.48C4963 2085.28 4961.32 2083.36 4958.16 2083.36C4955.12 2083.36 4953.04 2085.28 4953.04 2088.8C4953.04 2089.52 4953.08 2090.12 4953.16 2090.64L4949.12 2090.12C4949.08 2089.72 4949.04 2089.2 4949.04 2088.48C4949.04 2083.24 4952.92 2080.04 4958.2 2080.04C4963.76 2080.04 4967.2 2083 4967.2 2088.28C4967.2 2095.08 4962.2 2099.6 4956.6 2103.16L4954.32 2104.6V2104.64H4967.2V2108ZM4981.18 2108.48C4975.38 2108.48 4971.3 2105.4 4971.3 2100.36C4971.3 2096.16 4974.1 2094.16 4976.38 2093.32V2093.28C4974.7 2092.64 4972.18 2090.64 4972.18 2087.36C4972.18 2082.88 4976.1 2080 4981.18 2080C4986.22 2080 4990.18 2082.88 4990.18 2087.36C4990.18 2090.64 4987.66 2092.64 4985.94 2093.28V2093.32C4988.22 2094.16 4991.02 2096.16 4991.02 2100.36C4991.02 2105.4 4986.98 2108.48 4981.18 2108.48ZM4981.18 2091.96C4984.06 2091.96 4986.1 2090.08 4986.1 2087.6C4986.1 2085.16 4984.14 2083.28 4981.18 2083.28C4978.18 2083.28 4976.22 2085.16 4976.22 2087.6C4976.22 2090.08 4978.3 2091.96 4981.18 2091.96ZM4981.18 2105.16C4984.66 2105.16 4986.94 2103.12 4986.94 2100.12C4986.94 2097.2 4984.66 2095.16 4981.18 2095.16C4977.7 2095.16 4975.38 2097.2 4975.38 2100.12C4975.38 2103.12 4977.7 2105.16 4981.18 2105.16Z",
      },
    ],
  },
  {
    type: E_GolfSeatType.Regular,
    name: E_GolfBay.LG29,
    svgConfig: [
      {
        path: "M5023.02 2019.73C5022.47 2013.94 5026.96 2008.9 5032.78 2008.79L5116.86 2007.18C5122.03 2007.08 5126.43 2010.94 5126.99 2016.09L5143.81 2170.12C5144.45 2175.96 5139.94 2181.1 5134.06 2181.21L5047.77 2182.83C5042.54 2182.92 5038.12 2178.98 5037.62 2173.77L5023.02 2019.73Z",
      },
      {
        path: "M5081.2 2107H5062.96V2103.6L5066 2101.6C5072.4 2097.48 5077 2093.48 5077 2087.48C5077 2084.28 5075.32 2082.36 5072.16 2082.36C5069.12 2082.36 5067.04 2084.28 5067.04 2087.8C5067.04 2088.52 5067.08 2089.12 5067.16 2089.64L5063.12 2089.12C5063.08 2088.72 5063.04 2088.2 5063.04 2087.48C5063.04 2082.24 5066.92 2079.04 5072.2 2079.04C5077.76 2079.04 5081.2 2082 5081.2 2087.28C5081.2 2094.08 5076.2 2098.6 5070.6 2102.16L5068.32 2103.6V2103.64H5081.2V2107ZM5094.42 2107.48C5089.34 2107.48 5086.5 2104.44 5085.82 2100.48L5089.74 2099.72C5090.14 2102.44 5091.74 2104.24 5094.58 2104.24C5099.3 2104.24 5100.98 2099.32 5101.06 2093.52L5101.02 2093.48C5100.02 2095.32 5097.82 2097.2 5094.34 2097.2C5088.82 2097.2 5085.42 2093.36 5085.42 2088.28C5085.42 2082.96 5089.3 2079.04 5094.9 2079.04C5101.9 2079.04 5104.98 2084.84 5104.98 2092.68C5104.98 2102.28 5100.86 2107.48 5094.42 2107.48ZM5094.78 2093.88C5097.9 2093.88 5100.3 2091.44 5100.3 2088.24C5100.3 2084.8 5097.98 2082.36 5094.78 2082.36C5091.74 2082.36 5089.42 2084.56 5089.42 2088.2C5089.42 2091.96 5091.78 2093.88 5094.78 2093.88Z",
      },
    ],
  },
  {
    type: E_GolfSeatType.Regular,
    name: E_GolfBay.LG30,
    svgConfig: [
      {
        path: "M5139.22 2017.89C5138.57 2012.05 5143.08 2006.9 5148.96 2006.78L5230.01 2005.18C5235.12 2005.08 5239.49 2008.85 5240.13 2013.92L5259.6 2167.96C5260.35 2173.86 5255.82 2179.1 5249.88 2179.21L5166.62 2180.82C5161.45 2180.92 5157.06 2177.07 5156.48 2171.94L5139.22 2017.89Z",
      },
      {
        path: "M5189.6 2105.48C5183.8 2105.48 5180 2102.24 5179.68 2096.84L5183.76 2096.2C5184 2100.08 5186.28 2102.16 5189.68 2102.16C5193.28 2102.16 5195.28 2100.08 5195.28 2097.12C5195.28 2094.32 5193.4 2092.36 5189.52 2092.36C5188.28 2092.36 5187.4 2092.48 5186.76 2092.52V2089C5187.4 2089.04 5188.24 2089.08 5189.44 2089.08C5192.52 2089.08 5194.6 2087.32 5194.6 2084.56C5194.6 2082.12 5192.84 2080.36 5189.8 2080.36C5186.44 2080.36 5184.36 2082.48 5184.16 2086.04L5180.12 2085.32C5180.36 2080.2 5184.28 2077.04 5189.84 2077.04C5195.08 2077.04 5198.76 2079.72 5198.76 2084.36C5198.76 2087.92 5196.44 2089.64 5194.28 2090.44V2090.52C5197.16 2091.24 5199.44 2093.6 5199.44 2097.16C5199.44 2102.2 5195.48 2105.48 5189.6 2105.48ZM5213.64 2105.48C5206.68 2105.48 5203.48 2099.04 5203.48 2091.28C5203.48 2083.24 5206.68 2077.04 5213.64 2077.04C5220.6 2077.04 5223.84 2083.24 5223.84 2091.28C5223.84 2099.28 5220.6 2105.48 5213.64 2105.48ZM5213.64 2102.16C5217.96 2102.16 5219.68 2097.6 5219.68 2091.28C5219.68 2084.92 5217.96 2080.36 5213.64 2080.36C5209.36 2080.36 5207.64 2084.92 5207.64 2091.28C5207.64 2097.6 5209.36 2102.16 5213.64 2102.16Z",
      },
    ],
  },
  {
    type: E_GolfSeatType.Regular,
    name: E_GolfBay.LG31,
    svgConfig: [
      {
        path: "M5253.43 2016.06C5252.67 2010.16 5257.2 2004.9 5263.15 2004.78L5345.21 2003.17C5350.23 2003.07 5354.55 2006.73 5355.29 2011.7L5378.31 2166.64C5379.2 2172.64 5374.59 2178.05 5368.52 2178.11L5283.37 2178.92C5278.31 2178.96 5274.01 2175.22 5273.36 2170.2L5253.43 2016.06Z",
      },
      {
        path: "M5304.6 2103.48C5298.8 2103.48 5295 2100.24 5294.68 2094.84L5298.76 2094.2C5299 2098.08 5301.28 2100.16 5304.68 2100.16C5308.28 2100.16 5310.28 2098.08 5310.28 2095.12C5310.28 2092.32 5308.4 2090.36 5304.52 2090.36C5303.28 2090.36 5302.4 2090.48 5301.76 2090.52V2087C5302.4 2087.04 5303.24 2087.08 5304.44 2087.08C5307.52 2087.08 5309.6 2085.32 5309.6 2082.56C5309.6 2080.12 5307.84 2078.36 5304.8 2078.36C5301.44 2078.36 5299.36 2080.48 5299.16 2084.04L5295.12 2083.32C5295.36 2078.2 5299.28 2075.04 5304.84 2075.04C5310.08 2075.04 5313.76 2077.72 5313.76 2082.36C5313.76 2085.92 5311.44 2087.64 5309.28 2088.44V2088.52C5312.16 2089.24 5314.44 2091.6 5314.44 2095.16C5314.44 2100.2 5310.48 2103.48 5304.6 2103.48ZM5328.04 2103H5324.04V2084.24H5317.36V2081.32H5317.72C5322.2 2081.32 5324.48 2080 5324.68 2075.76L5324.72 2075.48H5328.04V2103Z",
      },
    ],
  },
  {
    type: E_GolfSeatType.Regular,
    name: E_GolfBay.LG32,
    svgConfig: [
      {
        path: "M5367.65 2014.23C5366.77 2008.27 5371.32 2002.9 5377.34 2002.78L5459.35 2001.17C5464.32 2001.07 5468.6 2004.63 5469.41 2009.53L5495.09 2164.48C5496.09 2170.53 5491.46 2176.05 5485.32 2176.11L5400.22 2176.92C5395.23 2176.96 5390.96 2173.31 5390.23 2168.37L5367.65 2014.23Z",
      },
      {
        path: "M5422.6 2101.48C5416.8 2101.48 5413 2098.24 5412.68 2092.84L5416.76 2092.2C5417 2096.08 5419.28 2098.16 5422.68 2098.16C5426.28 2098.16 5428.28 2096.08 5428.28 2093.12C5428.28 2090.32 5426.4 2088.36 5422.52 2088.36C5421.28 2088.36 5420.4 2088.48 5419.76 2088.52V2085C5420.4 2085.04 5421.24 2085.08 5422.44 2085.08C5425.52 2085.08 5427.6 2083.32 5427.6 2080.56C5427.6 2078.12 5425.84 2076.36 5422.8 2076.36C5419.44 2076.36 5417.36 2078.48 5417.16 2082.04L5413.12 2081.32C5413.36 2076.2 5417.28 2073.04 5422.84 2073.04C5428.08 2073.04 5431.76 2075.72 5431.76 2080.36C5431.76 2083.92 5429.44 2085.64 5427.28 2086.44V2086.52C5430.16 2087.24 5432.44 2089.6 5432.44 2093.16C5432.44 2098.2 5428.48 2101.48 5422.6 2101.48ZM5454.68 2101H5436.44V2097.6L5439.48 2095.6C5445.88 2091.48 5450.48 2087.48 5450.48 2081.48C5450.48 2078.28 5448.8 2076.36 5445.64 2076.36C5442.6 2076.36 5440.52 2078.28 5440.52 2081.8C5440.52 2082.52 5440.56 2083.12 5440.64 2083.64L5436.6 2083.12C5436.56 2082.72 5436.52 2082.2 5436.52 2081.48C5436.52 2076.24 5440.4 2073.04 5445.68 2073.04C5451.24 2073.04 5454.68 2076 5454.68 2081.28C5454.68 2088.08 5449.68 2092.6 5444.08 2096.16L5441.8 2097.6V2097.64H5454.68V2101Z",
      },
    ],
  },
  {
    type: E_GolfSeatType.Regular,
    name: E_GolfBay.LG33,
    svgConfig: [
      {
        path: "M5481.93 2012.43C5480.91 2006.41 5485.49 2000.89 5491.59 2000.77L5573.54 1999.17C5578.43 1999.07 5582.66 2002.51 5583.57 2007.31L5612.81 2162.37C5613.95 2168.45 5609.36 2174.11 5603.17 2174.22L5518.12 2175.84C5513.17 2175.93 5508.89 2172.38 5508.07 2167.5L5481.93 2012.43Z",
      },
      {
        path: "M5538.6 2099.48C5532.8 2099.48 5529 2096.24 5528.68 2090.84L5532.76 2090.2C5533 2094.08 5535.28 2096.16 5538.68 2096.16C5542.28 2096.16 5544.28 2094.08 5544.28 2091.12C5544.28 2088.32 5542.4 2086.36 5538.52 2086.36C5537.28 2086.36 5536.4 2086.48 5535.76 2086.52V2083C5536.4 2083.04 5537.24 2083.08 5538.44 2083.08C5541.52 2083.08 5543.6 2081.32 5543.6 2078.56C5543.6 2076.12 5541.84 2074.36 5538.8 2074.36C5535.44 2074.36 5533.36 2076.48 5533.16 2080.04L5529.12 2079.32C5529.36 2074.2 5533.28 2071.04 5538.84 2071.04C5544.08 2071.04 5547.76 2073.72 5547.76 2078.36C5547.76 2081.92 5545.44 2083.64 5543.28 2084.44V2084.52C5546.16 2085.24 5548.44 2087.6 5548.44 2091.16C5548.44 2096.2 5544.48 2099.48 5538.6 2099.48ZM5562.08 2099.48C5556.28 2099.48 5552.48 2096.24 5552.16 2090.84L5556.24 2090.2C5556.48 2094.08 5558.76 2096.16 5562.16 2096.16C5565.76 2096.16 5567.76 2094.08 5567.76 2091.12C5567.76 2088.32 5565.88 2086.36 5562 2086.36C5560.76 2086.36 5559.88 2086.48 5559.24 2086.52V2083C5559.88 2083.04 5560.72 2083.08 5561.92 2083.08C5565 2083.08 5567.08 2081.32 5567.08 2078.56C5567.08 2076.12 5565.32 2074.36 5562.28 2074.36C5558.92 2074.36 5556.84 2076.48 5556.64 2080.04L5552.6 2079.32C5552.84 2074.2 5556.76 2071.04 5562.32 2071.04C5567.56 2071.04 5571.24 2073.72 5571.24 2078.36C5571.24 2081.92 5568.92 2083.64 5566.76 2084.44V2084.52C5569.64 2085.24 5571.92 2087.6 5571.92 2091.16C5571.92 2096.2 5567.96 2099.48 5562.08 2099.48Z",
      },
    ],
  },
  {
    type: E_GolfSeatType.Regular,
    name: E_GolfBay.LG34,
    svgConfig: [
      {
        path: "M5597.23 2010.64C5596.06 2004.56 5600.66 1998.89 5606.85 1998.77L5687.68 1997.16C5692.5 1997.07 5696.7 2000.43 5697.68 2005.15L5729.58 2160.22C5730.84 2166.35 5726.23 2172.11 5719.97 2172.23L5636.93 2173.84C5632.06 2173.93 5627.83 2170.5 5626.91 2165.72L5597.23 2010.64Z",
      },
      {
        path: "M5655.6 2097.48C5649.8 2097.48 5646 2094.24 5645.68 2088.84L5649.76 2088.2C5650 2092.08 5652.28 2094.16 5655.68 2094.16C5659.28 2094.16 5661.28 2092.08 5661.28 2089.12C5661.28 2086.32 5659.4 2084.36 5655.52 2084.36C5654.28 2084.36 5653.4 2084.48 5652.76 2084.52V2081C5653.4 2081.04 5654.24 2081.08 5655.44 2081.08C5658.52 2081.08 5660.6 2079.32 5660.6 2076.56C5660.6 2074.12 5658.84 2072.36 5655.8 2072.36C5652.44 2072.36 5650.36 2074.48 5650.16 2078.04L5646.12 2077.32C5646.36 2072.2 5650.28 2069.04 5655.84 2069.04C5661.08 2069.04 5664.76 2071.72 5664.76 2076.36C5664.76 2079.92 5662.44 2081.64 5660.28 2082.44V2082.52C5663.16 2083.24 5665.44 2085.6 5665.44 2089.16C5665.44 2094.2 5661.48 2097.48 5655.6 2097.48ZM5685.64 2087.48H5690.12V2090.96H5685.64V2097H5681.64V2090.96H5668.52V2087L5679.56 2069.48H5685.64V2087.48ZM5681.64 2072.76L5672.4 2087.48H5681.64V2072.76Z",
      },
    ],
  },
  {
    type: E_GolfSeatType.Regular,
    name: E_GolfBay.LG35,
    svgConfig: [
      {
        path: "M5711.46 2008.8C5710.18 2002.67 5714.79 1996.89 5721.05 1996.76L5801.95 1995.16C5806.65 1995.07 5810.78 1998.26 5811.87 2002.84L5849.09 2158.8C5850.58 2165.04 5845.88 2171.06 5839.45 2171.12L5753.71 2171.92C5748.94 2171.97 5744.8 2168.64 5743.82 2163.97L5711.46 2008.8Z",
      },
      {
        path: "M5770.6 2096.48C5764.8 2096.48 5761 2093.24 5760.68 2087.84L5764.76 2087.2C5765 2091.08 5767.28 2093.16 5770.68 2093.16C5774.28 2093.16 5776.28 2091.08 5776.28 2088.12C5776.28 2085.32 5774.4 2083.36 5770.52 2083.36C5769.28 2083.36 5768.4 2083.48 5767.76 2083.52V2080C5768.4 2080.04 5769.24 2080.08 5770.44 2080.08C5773.52 2080.08 5775.6 2078.32 5775.6 2075.56C5775.6 2073.12 5773.84 2071.36 5770.8 2071.36C5767.44 2071.36 5765.36 2073.48 5765.16 2077.04L5761.12 2076.32C5761.36 2071.2 5765.28 2068.04 5770.84 2068.04C5776.08 2068.04 5779.76 2070.72 5779.76 2075.36C5779.76 2078.92 5777.44 2080.64 5775.28 2081.44V2081.52C5778.16 2082.24 5780.44 2084.6 5780.44 2088.16C5780.44 2093.2 5776.48 2096.48 5770.6 2096.48ZM5794.84 2077.36C5800.28 2077.36 5803.76 2081.28 5803.76 2086.64C5803.76 2092.28 5799.56 2096.48 5793.84 2096.48C5788.32 2096.48 5784.92 2093.24 5784.08 2088.68L5788.2 2087.88C5788.76 2091.04 5790.68 2093.16 5793.92 2093.16C5797.36 2093.16 5799.56 2090.56 5799.56 2086.72C5799.56 2083.2 5797.64 2080.68 5794.24 2080.68C5791.44 2080.68 5789.52 2082.68 5788.92 2084.24L5785.16 2083.52L5786.24 2068.48H5801.76V2072.08H5789.6L5789.04 2079.6H5789.08C5790.16 2078.48 5792.12 2077.36 5794.84 2077.36Z",
      },
    ],
  },
  {
    type: E_GolfSeatType.Regular,
    name: E_GolfBay.LG36,
    svgConfig: [
      {
        path: "M5825.76 2007C5824.34 2000.81 5828.96 1994.88 5835.31 1994.76L5916.08 1993.16C5920.72 1993.06 5924.81 1996.18 5925.96 2000.68L5965.87 2156.76C5967.47 2163.01 5962.82 2169.12 5956.37 2169.24L5871.61 2170.85C5866.89 2170.94 5862.74 2167.7 5861.68 2163.09L5825.76 2007Z",
      },
      {
        path: "M5886.6 2094.48C5880.8 2094.48 5877 2091.24 5876.68 2085.84L5880.76 2085.2C5881 2089.08 5883.28 2091.16 5886.68 2091.16C5890.28 2091.16 5892.28 2089.08 5892.28 2086.12C5892.28 2083.32 5890.4 2081.36 5886.52 2081.36C5885.28 2081.36 5884.4 2081.48 5883.76 2081.52V2078C5884.4 2078.04 5885.24 2078.08 5886.44 2078.08C5889.52 2078.08 5891.6 2076.32 5891.6 2073.56C5891.6 2071.12 5889.84 2069.36 5886.8 2069.36C5883.44 2069.36 5881.36 2071.48 5881.16 2075.04L5877.12 2074.32C5877.36 2069.2 5881.28 2066.04 5886.84 2066.04C5892.08 2066.04 5895.76 2068.72 5895.76 2073.36C5895.76 2076.92 5893.44 2078.64 5891.28 2079.44V2079.52C5894.16 2080.24 5896.44 2082.6 5896.44 2086.16C5896.44 2091.2 5892.48 2094.48 5886.6 2094.48ZM5910.6 2094.48C5903.6 2094.48 5900.52 2088.68 5900.52 2080.84C5900.52 2071.24 5904.64 2066.04 5911.08 2066.04C5916.12 2066.04 5918.96 2069.08 5919.68 2073.04L5915.76 2073.8C5915.36 2071.08 5913.76 2069.28 5910.92 2069.28C5906.2 2069.28 5904.48 2074.2 5904.44 2080.04L5904.48 2080.08C5905.44 2078.2 5907.68 2076.32 5911.16 2076.32C5916.68 2076.32 5920.08 2080.16 5920.08 2085.24C5920.08 2090.56 5916.16 2094.48 5910.6 2094.48ZM5910.72 2091.16C5913.76 2091.16 5916.04 2088.96 5916.04 2085.32C5916.04 2081.56 5913.68 2079.64 5910.72 2079.64C5907.56 2079.64 5905.2 2082.08 5905.2 2085.28C5905.2 2088.76 5907.52 2091.16 5910.72 2091.16Z",
      },
    ],
  },
  {
    type: E_GolfSeatType.Regular,
    name: E_GolfBay.LG37,
    svgConfig: [
      {
        path: "M5940.12 2005.33C5938.53 1999.05 5943.24 1992.94 5949.71 1992.88L6031.3 1992.08C6035.84 1992.03 6039.84 1995.05 6041.04 1999.43L6083.6 2154.6C6085.32 2160.89 6080.66 2167.12 6074.14 2167.24L5989.44 2168.85C5984.79 2168.94 5980.69 2165.81 5979.55 2161.3L5940.12 2005.33Z",
      },
      {
        path: "M6002.6 2092.48C5996.8 2092.48 5993 2089.24 5992.68 2083.84L5996.76 2083.2C5997 2087.08 5999.28 2089.16 6002.68 2089.16C6006.28 2089.16 6008.28 2087.08 6008.28 2084.12C6008.28 2081.32 6006.4 2079.36 6002.52 2079.36C6001.28 2079.36 6000.4 2079.48 5999.76 2079.52V2076C6000.4 2076.04 6001.24 2076.08 6002.44 2076.08C6005.52 2076.08 6007.6 2074.32 6007.6 2071.56C6007.6 2069.12 6005.84 2067.36 6002.8 2067.36C5999.44 2067.36 5997.36 2069.48 5997.16 2073.04L5993.12 2072.32C5993.36 2067.2 5997.28 2064.04 6002.84 2064.04C6008.08 2064.04 6011.76 2066.72 6011.76 2071.36C6011.76 2074.92 6009.44 2076.64 6007.28 2077.44V2077.52C6010.16 2078.24 6012.44 2080.6 6012.44 2084.16C6012.44 2089.2 6008.48 2092.48 6002.6 2092.48ZM6015.64 2071.76V2064.48H6035.36V2067.56C6030.8 2075.36 6027.12 2083.68 6025.32 2092H6020.96C6022.8 2083.92 6027.08 2074.8 6031.4 2067.96H6019.52V2071.76H6015.64Z",
      },
    ],
  },
  {
    type: E_GolfSeatType.Regular,
    name: E_GolfBay.LG38,
    svgConfig: [
      {
        path: "M6054.54 2003.58C6052.75 1997.24 6057.48 1990.94 6064.07 1990.87L6145.46 1990.07C6149.92 1990.03 6153.88 1992.95 6155.15 1997.23L6201.26 2152.4C6203.14 2158.74 6198.47 2165.12 6191.86 2165.25L6108.23 2166.85C6103.68 2166.94 6099.64 2163.94 6098.41 2159.56L6054.54 2003.58Z",
      },
      {
        path: "M6119.6 2090.48C6113.8 2090.48 6110 2087.24 6109.68 2081.84L6113.76 2081.2C6114 2085.08 6116.28 2087.16 6119.68 2087.16C6123.28 2087.16 6125.28 2085.08 6125.28 2082.12C6125.28 2079.32 6123.4 2077.36 6119.52 2077.36C6118.28 2077.36 6117.4 2077.48 6116.76 2077.52V2074C6117.4 2074.04 6118.24 2074.08 6119.44 2074.08C6122.52 2074.08 6124.6 2072.32 6124.6 2069.56C6124.6 2067.12 6122.84 2065.36 6119.8 2065.36C6116.44 2065.36 6114.36 2067.48 6114.16 2071.04L6110.12 2070.32C6110.36 2065.2 6114.28 2062.04 6119.84 2062.04C6125.08 2062.04 6128.76 2064.72 6128.76 2069.36C6128.76 2072.92 6126.44 2074.64 6124.28 2075.44V2075.52C6127.16 2076.24 6129.44 2078.6 6129.44 2082.16C6129.44 2087.2 6125.48 2090.48 6119.6 2090.48ZM6143.32 2090.48C6137.52 2090.48 6133.44 2087.4 6133.44 2082.36C6133.44 2078.16 6136.24 2076.16 6138.52 2075.32V2075.28C6136.84 2074.64 6134.32 2072.64 6134.32 2069.36C6134.32 2064.88 6138.24 2062 6143.32 2062C6148.36 2062 6152.32 2064.88 6152.32 2069.36C6152.32 2072.64 6149.8 2074.64 6148.08 2075.28V2075.32C6150.36 2076.16 6153.16 2078.16 6153.16 2082.36C6153.16 2087.4 6149.12 2090.48 6143.32 2090.48ZM6143.32 2073.96C6146.2 2073.96 6148.24 2072.08 6148.24 2069.6C6148.24 2067.16 6146.28 2065.28 6143.32 2065.28C6140.32 2065.28 6138.36 2067.16 6138.36 2069.6C6138.36 2072.08 6140.44 2073.96 6143.32 2073.96ZM6143.32 2087.16C6146.8 2087.16 6149.08 2085.12 6149.08 2082.12C6149.08 2079.2 6146.8 2077.16 6143.32 2077.16C6139.84 2077.16 6137.52 2079.2 6137.52 2082.12C6137.52 2085.12 6139.84 2087.16 6143.32 2087.16Z",
      },
    ],
  },
  {
    type: E_GolfSeatType.Regular,
    name: E_GolfBay.LG39,
    svgConfig: [
      {
        path: "M6168.73 2001.58C6166.85 1995.25 6171.52 1988.87 6178.12 1988.74L6259.56 1987.15C6263.98 1987.06 6267.93 1989.88 6269.28 1994.08L6319.87 2151.19C6321.93 2157.57 6317.25 2164.13 6310.54 2164.25L6225.11 2165.86C6220.61 2165.94 6216.61 2163.01 6215.34 2158.7L6168.73 2001.58Z",
      },
      {
        path: "M6234.6 2089.48C6228.8 2089.48 6225 2086.24 6224.68 2080.84L6228.76 2080.2C6229 2084.08 6231.28 2086.16 6234.68 2086.16C6238.28 2086.16 6240.28 2084.08 6240.28 2081.12C6240.28 2078.32 6238.4 2076.36 6234.52 2076.36C6233.28 2076.36 6232.4 2076.48 6231.76 2076.52V2073C6232.4 2073.04 6233.24 2073.08 6234.44 2073.08C6237.52 2073.08 6239.6 2071.32 6239.6 2068.56C6239.6 2066.12 6237.84 2064.36 6234.8 2064.36C6231.44 2064.36 6229.36 2066.48 6229.16 2070.04L6225.12 2069.32C6225.36 2064.2 6229.28 2061.04 6234.84 2061.04C6240.08 2061.04 6243.76 2063.72 6243.76 2068.36C6243.76 2071.92 6241.44 2073.64 6239.28 2074.44V2074.52C6242.16 2075.24 6244.44 2077.6 6244.44 2081.16C6244.44 2086.2 6240.48 2089.48 6234.6 2089.48ZM6257.56 2089.48C6252.48 2089.48 6249.64 2086.44 6248.96 2082.48L6252.88 2081.72C6253.28 2084.44 6254.88 2086.24 6257.72 2086.24C6262.44 2086.24 6264.12 2081.32 6264.2 2075.52L6264.16 2075.48C6263.16 2077.32 6260.96 2079.2 6257.48 2079.2C6251.96 2079.2 6248.56 2075.36 6248.56 2070.28C6248.56 2064.96 6252.44 2061.04 6258.04 2061.04C6265.04 2061.04 6268.12 2066.84 6268.12 2074.68C6268.12 2084.28 6264 2089.48 6257.56 2089.48ZM6257.92 2075.88C6261.04 2075.88 6263.44 2073.44 6263.44 2070.24C6263.44 2066.8 6261.12 2064.36 6257.92 2064.36C6254.88 2064.36 6252.56 2066.56 6252.56 2070.2C6252.56 2073.96 6254.92 2075.88 6257.92 2075.88Z",
      },
    ],
  },
  //openBay
  {
    type: E_GolfSeatType.Open,
    name: E_GolfBay.OB01,
    svgConfig: [
      {
        path: "M332.824 1633.76C334.841 1631.69 337.659 1630.59 340.548 1630.75L431.469 1635.83C439.885 1636.3 443.981 1646.33 438.296 1652.55L303.2 1800.5C301.175 1802.71 298.26 1803.9 295.261 1803.74L195.927 1798.22C187.361 1797.74 183.332 1787.41 189.315 1781.26L332.824 1633.76Z",
      },
      {
        path: "M288.16 1744.48C281.2 1744.48 278 1738.04 278 1730.28C278 1722.24 281.2 1716.04 288.16 1716.04C295.12 1716.04 298.36 1722.24 298.36 1730.28C298.36 1738.28 295.12 1744.48 288.16 1744.48ZM288.16 1741.16C292.48 1741.16 294.2 1736.6 294.2 1730.28C294.2 1723.92 292.48 1719.36 288.16 1719.36C283.88 1719.36 282.16 1723.92 282.16 1730.28C282.16 1736.6 283.88 1741.16 288.16 1741.16ZM312.052 1744H308.052V1725.24H301.372V1722.32H301.732C306.212 1722.32 308.492 1721 308.692 1716.76L308.732 1716.48H312.052V1744Z",
      },
      {
        path: "M322.2 1707.48C314.04 1707.48 308.76 1701.28 308.76 1693.24C308.76 1685.2 314.04 1679 322.2 1679C330.36 1679 335.64 1685.2 335.64 1693.24C335.64 1701.28 330.36 1707.48 322.2 1707.48ZM322.2 1704.08C328 1704.08 331.44 1699.32 331.44 1693.24C331.44 1687.16 327.96 1682.4 322.2 1682.4C316.44 1682.4 312.96 1687.16 312.96 1693.24C312.96 1699.32 316.44 1704.08 322.2 1704.08ZM351.991 1707H340.711V1679.48H351.591C356.871 1679.48 360.311 1681.84 360.311 1686.56C360.311 1689.68 358.271 1691.76 356.431 1692.48V1692.52C358.391 1693 361.151 1694.92 361.151 1699.04C361.151 1703.72 357.951 1707 351.991 1707ZM344.791 1682.96V1691.04H351.431C354.431 1691.04 356.231 1689.72 356.231 1686.8C356.231 1684 354.271 1682.96 351.591 1682.96H344.791ZM344.791 1694.4V1703.52H351.911C355.391 1703.52 357.071 1701.8 357.071 1698.88C357.071 1696.04 355.311 1694.4 351.791 1694.4H344.791Z",
      },
    ],
  },
  {
    type: E_GolfSeatType.Open,
    name: E_GolfBay.OB02,
    svgConfig: [
      {
        path: "M485.804 1643.55C487.825 1641.3 490.752 1640.09 493.768 1640.26L590.378 1645.42C598.635 1645.87 602.819 1655.57 597.474 1661.87L472.21 1809.71C470.188 1812.1 467.166 1813.4 464.042 1813.23L359.904 1807.63C351.509 1807.17 347.385 1797.19 353.012 1790.95L485.804 1643.55Z",
      },
      {
        path: "M480.2 1714.48C472.04 1714.48 466.76 1708.28 466.76 1700.24C466.76 1692.2 472.04 1686 480.2 1686C488.36 1686 493.64 1692.2 493.64 1700.24C493.64 1708.28 488.36 1714.48 480.2 1714.48ZM480.2 1711.08C486 1711.08 489.44 1706.32 489.44 1700.24C489.44 1694.16 485.96 1689.4 480.2 1689.4C474.44 1689.4 470.96 1694.16 470.96 1700.24C470.96 1706.32 474.44 1711.08 480.2 1711.08ZM509.991 1714H498.711V1686.48H509.591C514.871 1686.48 518.311 1688.84 518.311 1693.56C518.311 1696.68 516.271 1698.76 514.431 1699.48V1699.52C516.391 1700 519.151 1701.92 519.151 1706.04C519.151 1710.72 515.951 1714 509.991 1714ZM502.791 1689.96V1698.04H509.431C512.431 1698.04 514.231 1696.72 514.231 1693.8C514.231 1691 512.271 1689.96 509.591 1689.96H502.791ZM502.791 1701.4V1710.52H509.911C513.391 1710.52 515.071 1708.8 515.071 1705.88C515.071 1703.04 513.311 1701.4 509.791 1701.4H502.791Z",
      },
      {
        path: "M450.16 1753.98C443.2 1753.98 440 1747.54 440 1739.78C440 1731.74 443.2 1725.54 450.16 1725.54C457.12 1725.54 460.36 1731.74 460.36 1739.78C460.36 1747.78 457.12 1753.98 450.16 1753.98ZM450.16 1750.66C454.48 1750.66 456.2 1746.1 456.2 1739.78C456.2 1733.42 454.48 1728.86 450.16 1728.86C445.88 1728.86 444.16 1733.42 444.16 1739.78C444.16 1746.1 445.88 1750.66 450.16 1750.66ZM482.692 1753.5H464.452V1750.1L467.492 1748.1C473.892 1743.98 478.492 1739.98 478.492 1733.98C478.492 1730.78 476.812 1728.86 473.652 1728.86C470.612 1728.86 468.532 1730.78 468.532 1734.3C468.532 1735.02 468.572 1735.62 468.652 1736.14L464.612 1735.62C464.572 1735.22 464.532 1734.7 464.532 1733.98C464.532 1728.74 468.412 1725.54 473.692 1725.54C479.252 1725.54 482.692 1728.5 482.692 1733.78C482.692 1740.58 477.692 1745.1 472.092 1748.66L469.812 1750.1V1750.14H482.692V1753.5Z",
      },
    ],
  },
  {
    type: E_GolfSeatType.Open,
    name: E_GolfBay.OB03,
    svgConfig: [
      {
        path: "M654.791 1652.76C656.813 1650.39 659.826 1649.1 662.938 1649.27L758.741 1654.43C766.93 1654.88 771.135 1664.44 765.923 1670.78L644.22 1818.59C642.196 1821.05 639.121 1822.39 635.941 1822.22L536.137 1816.63C527.879 1816.17 523.72 1806.45 529.088 1800.15L654.791 1652.76Z",
      },
      {
        path: "M651.2 1723.48C643.04 1723.48 637.76 1717.28 637.76 1709.24C637.76 1701.2 643.04 1695 651.2 1695C659.36 1695 664.64 1701.2 664.64 1709.24C664.64 1717.28 659.36 1723.48 651.2 1723.48ZM651.2 1720.08C657 1720.08 660.44 1715.32 660.44 1709.24C660.44 1703.16 656.96 1698.4 651.2 1698.4C645.44 1698.4 641.96 1703.16 641.96 1709.24C641.96 1715.32 645.44 1720.08 651.2 1720.08ZM680.991 1723H669.711V1695.48H680.591C685.871 1695.48 689.311 1697.84 689.311 1702.56C689.311 1705.68 687.271 1707.76 685.431 1708.48V1708.52C687.391 1709 690.151 1710.92 690.151 1715.04C690.151 1719.72 686.951 1723 680.991 1723ZM673.791 1698.96V1707.04H680.431C683.431 1707.04 685.231 1705.72 685.231 1702.8C685.231 1700 683.271 1698.96 680.591 1698.96H673.791ZM673.791 1710.4V1719.52H680.911C684.391 1719.52 686.071 1717.8 686.071 1714.88C686.071 1712.04 684.311 1710.4 680.791 1710.4H673.791Z",
      },
      {
        path: "M620.16 1762.98C613.2 1762.98 610 1756.54 610 1748.78C610 1740.74 613.2 1734.54 620.16 1734.54C627.12 1734.54 630.36 1740.74 630.36 1748.78C630.36 1756.78 627.12 1762.98 620.16 1762.98ZM620.16 1759.66C624.48 1759.66 626.2 1755.1 626.2 1748.78C626.2 1742.42 624.48 1737.86 620.16 1737.86C615.88 1737.86 614.16 1742.42 614.16 1748.78C614.16 1755.1 615.88 1759.66 620.16 1759.66ZM644.092 1762.98C638.292 1762.98 634.492 1759.74 634.172 1754.34L638.252 1753.7C638.492 1757.58 640.772 1759.66 644.172 1759.66C647.772 1759.66 649.772 1757.58 649.772 1754.62C649.772 1751.82 647.892 1749.86 644.012 1749.86C642.772 1749.86 641.892 1749.98 641.252 1750.02V1746.5C641.892 1746.54 642.732 1746.58 643.932 1746.58C647.012 1746.58 649.092 1744.82 649.092 1742.06C649.092 1739.62 647.332 1737.86 644.292 1737.86C640.932 1737.86 638.852 1739.98 638.652 1743.54L634.612 1742.82C634.852 1737.7 638.772 1734.54 644.332 1734.54C649.572 1734.54 653.252 1737.22 653.252 1741.86C653.252 1745.42 650.932 1747.14 648.772 1747.94V1748.02C651.652 1748.74 653.932 1751.1 653.932 1754.66C653.932 1759.7 649.972 1762.98 644.092 1762.98Z",
      },
    ],
  },
  {
    type: E_GolfSeatType.Open,
    name: E_GolfBay.OB04,
    svgConfig: [
      {
        path: "M818.823 1662.98C820.819 1660.48 823.89 1659.08 827.085 1659.23L925.395 1663.64C933.461 1664 937.794 1673.28 932.895 1679.7L820.181 1827.33C818.189 1829.94 815.043 1831.41 811.764 1831.25L708.577 1826.42C700.42 1826.04 696.135 1816.57 701.232 1810.19L818.823 1662.98Z",
      },
      {
        path: "M821.2 1733.48C813.04 1733.48 807.76 1727.28 807.76 1719.24C807.76 1711.2 813.04 1705 821.2 1705C829.36 1705 834.64 1711.2 834.64 1719.24C834.64 1727.28 829.36 1733.48 821.2 1733.48ZM821.2 1730.08C827 1730.08 830.44 1725.32 830.44 1719.24C830.44 1713.16 826.96 1708.4 821.2 1708.4C815.44 1708.4 811.96 1713.16 811.96 1719.24C811.96 1725.32 815.44 1730.08 821.2 1730.08ZM850.991 1733H839.711V1705.48H850.591C855.871 1705.48 859.311 1707.84 859.311 1712.56C859.311 1715.68 857.271 1717.76 855.431 1718.48V1718.52C857.391 1719 860.151 1720.92 860.151 1725.04C860.151 1729.72 856.951 1733 850.991 1733ZM843.791 1708.96V1717.04H850.431C853.431 1717.04 855.231 1715.72 855.231 1712.8C855.231 1710 853.271 1708.96 850.591 1708.96H843.791ZM843.791 1720.4V1729.52H850.911C854.391 1729.52 856.071 1727.8 856.071 1724.88C856.071 1722.04 854.311 1720.4 850.791 1720.4H843.791Z",
      },
      {
        path: "M792.16 1772.98C785.2 1772.98 782 1766.54 782 1758.78C782 1750.74 785.2 1744.54 792.16 1744.54C799.12 1744.54 802.36 1750.74 802.36 1758.78C802.36 1766.78 799.12 1772.98 792.16 1772.98ZM792.16 1769.66C796.48 1769.66 798.2 1765.1 798.2 1758.78C798.2 1752.42 796.48 1747.86 792.16 1747.86C787.88 1747.86 786.16 1752.42 786.16 1758.78C786.16 1765.1 787.88 1769.66 792.16 1769.66ZM822.652 1762.98H827.132V1766.46H822.652V1772.5H818.652V1766.46H805.532V1762.5L816.572 1744.98H822.652V1762.98ZM818.652 1748.26L809.412 1762.98H818.652V1748.26Z",
      },
    ],
  },
  {
    type: E_GolfSeatType.Open,
    name: E_GolfBay.OB05,
    svgConfig: [
      {
        path: "M983.792 1671.2C985.799 1668.57 988.975 1667.11 992.276 1667.28L1089.44 1672.53C1097.3 1672.95 1101.61 1681.85 1097.08 1688.28L992.187 1836.98C990.204 1839.79 986.914 1841.39 983.478 1841.2L877.901 1835.52C869.877 1835.09 865.614 1825.85 870.493 1819.46L983.792 1671.2Z",
      },
      {
        path: "M987.2 1741.48C979.04 1741.48 973.76 1735.28 973.76 1727.24C973.76 1719.2 979.04 1713 987.2 1713C995.36 1713 1000.64 1719.2 1000.64 1727.24C1000.64 1735.28 995.36 1741.48 987.2 1741.48ZM987.2 1738.08C993 1738.08 996.44 1733.32 996.44 1727.24C996.44 1721.16 992.96 1716.4 987.2 1716.4C981.44 1716.4 977.96 1721.16 977.96 1727.24C977.96 1733.32 981.44 1738.08 987.2 1738.08ZM1016.99 1741H1005.71V1713.48H1016.59C1021.87 1713.48 1025.31 1715.84 1025.31 1720.56C1025.31 1723.68 1023.27 1725.76 1021.43 1726.48V1726.52C1023.39 1727 1026.15 1728.92 1026.15 1733.04C1026.15 1737.72 1022.95 1741 1016.99 1741ZM1009.79 1716.96V1725.04H1016.43C1019.43 1725.04 1021.23 1723.72 1021.23 1720.8C1021.23 1718 1019.27 1716.96 1016.59 1716.96H1009.79ZM1009.79 1728.4V1737.52H1016.91C1020.39 1737.52 1022.07 1735.8 1022.07 1732.88C1022.07 1730.04 1020.31 1728.4 1016.79 1728.4H1009.79Z",
      },
      {
        path: "M960.16 1782.98C953.2 1782.98 950 1776.54 950 1768.78C950 1760.74 953.2 1754.54 960.16 1754.54C967.12 1754.54 970.36 1760.74 970.36 1768.78C970.36 1776.78 967.12 1782.98 960.16 1782.98ZM960.16 1779.66C964.48 1779.66 966.2 1775.1 966.2 1768.78C966.2 1762.42 964.48 1757.86 960.16 1757.86C955.88 1757.86 954.16 1762.42 954.16 1768.78C954.16 1775.1 955.88 1779.66 960.16 1779.66ZM984.852 1763.86C990.292 1763.86 993.772 1767.78 993.772 1773.14C993.772 1778.78 989.572 1782.98 983.852 1782.98C978.332 1782.98 974.932 1779.74 974.092 1775.18L978.212 1774.38C978.772 1777.54 980.692 1779.66 983.932 1779.66C987.372 1779.66 989.572 1777.06 989.572 1773.22C989.572 1769.7 987.652 1767.18 984.252 1767.18C981.452 1767.18 979.532 1769.18 978.932 1770.74L975.172 1770.02L976.252 1754.98H991.772V1758.58H979.612L979.052 1766.1H979.092C980.172 1764.98 982.132 1763.86 984.852 1763.86Z",
      },
    ],
  },
  {
    type: E_GolfSeatType.Open,
    name: E_GolfBay.OB06,
    svgConfig: [
      {
        path: "M1147.85 1680.55C1149.81 1677.72 1153.09 1676.1 1156.53 1676.25L1253.91 1680.7C1261.67 1681.05 1266.08 1689.72 1261.81 1696.19L1163.17 1845.7C1161.21 1848.67 1157.81 1850.38 1154.26 1850.18L1052.83 1844.5C1045.03 1844.06 1040.72 1835.25 1045.17 1828.82L1147.85 1680.55Z",
      },
      {
        path: "M1155.2 1750.48C1147.04 1750.48 1141.76 1744.28 1141.76 1736.24C1141.76 1728.2 1147.04 1722 1155.2 1722C1163.36 1722 1168.64 1728.2 1168.64 1736.24C1168.64 1744.28 1163.36 1750.48 1155.2 1750.48ZM1155.2 1747.08C1161 1747.08 1164.44 1742.32 1164.44 1736.24C1164.44 1730.16 1160.96 1725.4 1155.2 1725.4C1149.44 1725.4 1145.96 1730.16 1145.96 1736.24C1145.96 1742.32 1149.44 1747.08 1155.2 1747.08ZM1184.99 1750H1173.71V1722.48H1184.59C1189.87 1722.48 1193.31 1724.84 1193.31 1729.56C1193.31 1732.68 1191.27 1734.76 1189.43 1735.48V1735.52C1191.39 1736 1194.15 1737.92 1194.15 1742.04C1194.15 1746.72 1190.95 1750 1184.99 1750ZM1177.79 1725.96V1734.04H1184.43C1187.43 1734.04 1189.23 1732.72 1189.23 1729.8C1189.23 1727 1187.27 1725.96 1184.59 1725.96H1177.79ZM1177.79 1737.4V1746.52H1184.91C1188.39 1746.52 1190.07 1744.8 1190.07 1741.88C1190.07 1739.04 1188.31 1737.4 1184.79 1737.4H1177.79Z",
      },
      {
        path: "M1129.16 1792.98C1122.2 1792.98 1119 1786.54 1119 1778.78C1119 1770.74 1122.2 1764.54 1129.16 1764.54C1136.12 1764.54 1139.36 1770.74 1139.36 1778.78C1139.36 1786.78 1136.12 1792.98 1129.16 1792.98ZM1129.16 1789.66C1133.48 1789.66 1135.2 1785.1 1135.2 1778.78C1135.2 1772.42 1133.48 1767.86 1129.16 1767.86C1124.88 1767.86 1123.16 1772.42 1123.16 1778.78C1123.16 1785.1 1124.88 1789.66 1129.16 1789.66ZM1153.61 1792.98C1146.61 1792.98 1143.53 1787.18 1143.53 1779.34C1143.53 1769.74 1147.65 1764.54 1154.09 1764.54C1159.13 1764.54 1161.97 1767.58 1162.69 1771.54L1158.77 1772.3C1158.37 1769.58 1156.77 1767.78 1153.93 1767.78C1149.21 1767.78 1147.49 1772.7 1147.45 1778.54L1147.49 1778.58C1148.45 1776.7 1150.69 1774.82 1154.17 1774.82C1159.69 1774.82 1163.09 1778.66 1163.09 1783.74C1163.09 1789.06 1159.17 1792.98 1153.61 1792.98ZM1153.73 1789.66C1156.77 1789.66 1159.05 1787.46 1159.05 1783.82C1159.05 1780.06 1156.69 1778.14 1153.73 1778.14C1150.57 1778.14 1148.21 1780.58 1148.21 1783.78C1148.21 1787.26 1150.53 1789.66 1153.73 1789.66Z",
      },
    ],
  },
  {
    type: E_GolfSeatType.Open,
    name: E_GolfBay.OB07,
    svgConfig: [
      {
        path: "M1315.85 1689.83C1317.8 1686.84 1321.19 1685.12 1324.76 1685.31L1422.59 1690.59C1430.19 1691 1434.56 1699.41 1430.53 1705.87L1337.13 1855.48C1335.2 1858.58 1331.74 1860.37 1328.09 1860.17L1226.27 1854.47C1218.59 1854.04 1214.25 1845.46 1218.46 1839.02L1315.85 1689.83Z",
      },
      {
        path: "M1327.2 1757.48C1319.04 1757.48 1313.76 1751.28 1313.76 1743.24C1313.76 1735.2 1319.04 1729 1327.2 1729C1335.36 1729 1340.64 1735.2 1340.64 1743.24C1340.64 1751.28 1335.36 1757.48 1327.2 1757.48ZM1327.2 1754.08C1333 1754.08 1336.44 1749.32 1336.44 1743.24C1336.44 1737.16 1332.96 1732.4 1327.2 1732.4C1321.44 1732.4 1317.96 1737.16 1317.96 1743.24C1317.96 1749.32 1321.44 1754.08 1327.2 1754.08ZM1356.99 1757H1345.71V1729.48H1356.59C1361.87 1729.48 1365.31 1731.84 1365.31 1736.56C1365.31 1739.68 1363.27 1741.76 1361.43 1742.48V1742.52C1363.39 1743 1366.15 1744.92 1366.15 1749.04C1366.15 1753.72 1362.95 1757 1356.99 1757ZM1349.79 1732.96V1741.04H1356.43C1359.43 1741.04 1361.23 1739.72 1361.23 1736.8C1361.23 1734 1359.27 1732.96 1356.59 1732.96H1349.79ZM1349.79 1744.4V1753.52H1356.91C1360.39 1753.52 1362.07 1751.8 1362.07 1748.88C1362.07 1746.04 1360.31 1744.4 1356.79 1744.4H1349.79Z",
      },
      {
        path: "M1301.16 1802.48C1294.2 1802.48 1291 1796.04 1291 1788.28C1291 1780.24 1294.2 1774.04 1301.16 1774.04C1308.12 1774.04 1311.36 1780.24 1311.36 1788.28C1311.36 1796.28 1308.12 1802.48 1301.16 1802.48ZM1301.16 1799.16C1305.48 1799.16 1307.2 1794.6 1307.2 1788.28C1307.2 1781.92 1305.48 1777.36 1301.16 1777.36C1296.88 1777.36 1295.16 1781.92 1295.16 1788.28C1295.16 1794.6 1296.88 1799.16 1301.16 1799.16ZM1314.65 1781.76V1774.48H1334.37V1777.56C1329.81 1785.36 1326.13 1793.68 1324.33 1802H1319.97C1321.81 1793.92 1326.09 1784.8 1330.41 1777.96H1318.53V1781.76H1314.65Z",
      },
    ],
  },
  {
    type: E_GolfSeatType.Deluxe,
    name: E_GolfBay.V02,
    svgConfig: [
      {
        path: "M4698.29 1702.18C4698.13 1696.59 4702.59 1691.96 4708.18 1691.9L4842.56 1690.59C4847.86 1690.54 4852.28 1694.63 4852.64 1699.92L4863.29 1857.02C4863.68 1862.72 4859.21 1867.59 4853.5 1867.7L4712.9 1870.32C4707.42 1870.42 4702.88 1866.08 4702.72 1860.6L4698.29 1702.18Z",
      },
      {
        path: "M4757.42 1793L4765.38 1770.48H4769.74L4759.62 1798H4755.22L4745.1 1770.48H4749.46L4757.42 1793ZM4781.86 1798.48C4774.9 1798.48 4771.7 1792.04 4771.7 1784.28C4771.7 1776.24 4774.9 1770.04 4781.86 1770.04C4788.82 1770.04 4792.06 1776.24 4792.06 1784.28C4792.06 1792.28 4788.82 1798.48 4781.86 1798.48ZM4781.86 1795.16C4786.18 1795.16 4787.9 1790.6 4787.9 1784.28C4787.9 1777.92 4786.18 1773.36 4781.86 1773.36C4777.58 1773.36 4775.86 1777.92 4775.86 1784.28C4775.86 1790.6 4777.58 1795.16 4781.86 1795.16ZM4814.39 1798H4796.15V1794.6L4799.19 1792.6C4805.59 1788.48 4810.19 1784.48 4810.19 1778.48C4810.19 1775.28 4808.51 1773.36 4805.35 1773.36C4802.31 1773.36 4800.23 1775.28 4800.23 1778.8C4800.23 1779.52 4800.27 1780.12 4800.35 1780.64L4796.31 1780.12C4796.27 1779.72 4796.23 1779.2 4796.23 1778.48C4796.23 1773.24 4800.11 1770.04 4805.39 1770.04C4810.95 1770.04 4814.39 1773 4814.39 1778.28C4814.39 1785.08 4809.39 1789.6 4803.79 1793.16L4801.51 1794.6V1794.64H4814.39V1798Z",
      },
    ],
  },
  {
    type: E_GolfSeatType.Deluxe,
    name: E_GolfBay.V03,
    svgConfig: [
      {
        path: "M4866.71 1700.57C4866.33 1694.84 4870.85 1689.95 4876.6 1689.9L5016.94 1688.58C5022.08 1688.54 5026.42 1692.4 5026.97 1697.51L5043.83 1853.62C5044.46 1859.47 5039.94 1864.59 5034.06 1864.7L4887.51 1867.33C4882.19 1867.42 4877.72 1863.32 4877.36 1858.01L4866.71 1700.57Z",
      },
      {
        path: "M4930.36 1790L4938.32 1767.48H4942.68L4932.56 1795H4928.16L4918.04 1767.48H4922.4L4930.36 1790ZM4954.79 1795.48C4947.83 1795.48 4944.63 1789.04 4944.63 1781.28C4944.63 1773.24 4947.83 1767.04 4954.79 1767.04C4961.75 1767.04 4964.99 1773.24 4964.99 1781.28C4964.99 1789.28 4961.75 1795.48 4954.79 1795.48ZM4954.79 1792.16C4959.11 1792.16 4960.83 1787.6 4960.83 1781.28C4960.83 1774.92 4959.11 1770.36 4954.79 1770.36C4950.51 1770.36 4948.79 1774.92 4948.79 1781.28C4948.79 1787.6 4950.51 1792.16 4954.79 1792.16ZM4978.73 1795.48C4972.93 1795.48 4969.13 1792.24 4968.81 1786.84L4972.89 1786.2C4973.13 1790.08 4975.41 1792.16 4978.81 1792.16C4982.41 1792.16 4984.41 1790.08 4984.41 1787.12C4984.41 1784.32 4982.53 1782.36 4978.65 1782.36C4977.4 1782.36 4976.53 1782.48 4975.89 1782.52V1779C4976.53 1779.04 4977.37 1779.08 4978.57 1779.08C4981.65 1779.08 4983.73 1777.32 4983.73 1774.56C4983.73 1772.12 4981.97 1770.36 4978.93 1770.36C4975.57 1770.36 4973.49 1772.48 4973.29 1776.04L4969.25 1775.32C4969.49 1770.2 4973.41 1767.04 4978.97 1767.04C4984.21 1767.04 4987.89 1769.72 4987.89 1774.36C4987.89 1777.92 4985.57 1779.64 4983.41 1780.44V1780.52C4986.29 1781.24 4988.57 1783.6 4988.57 1787.16C4988.57 1792.2 4984.61 1795.48 4978.73 1795.48Z",
      },
    ],
  },
  {
    type: E_GolfSeatType.Deluxe,
    name: E_GolfBay.V04,
    svgConfig: [
      {
        path: "M5042.17 1698.9C5041.54 1693.05 5046.08 1687.92 5051.96 1687.83L5194.28 1685.63C5199.28 1685.56 5203.56 1689.18 5204.32 1694.12L5228.26 1850.19C5229.18 1856.17 5224.61 1861.6 5218.56 1861.7L5069.14 1864.34C5063.97 1864.43 5059.58 1860.56 5059.02 1855.41L5042.17 1698.9Z",
      },
      {
        path: "M5110.2 1788L5118.16 1765.48H5122.52L5112.4 1793H5108L5097.88 1765.48H5102.24L5110.2 1788ZM5134.64 1793.48C5127.68 1793.48 5124.48 1787.04 5124.48 1779.28C5124.48 1771.24 5127.68 1765.04 5134.64 1765.04C5141.6 1765.04 5144.84 1771.24 5144.84 1779.28C5144.84 1787.28 5141.6 1793.48 5134.64 1793.48ZM5134.64 1790.16C5138.96 1790.16 5140.68 1785.6 5140.68 1779.28C5140.68 1772.92 5138.96 1768.36 5134.64 1768.36C5130.36 1768.36 5128.64 1772.92 5128.64 1779.28C5128.64 1785.6 5130.36 1790.16 5134.64 1790.16ZM5165.13 1783.48H5169.61V1786.96H5165.13V1793H5161.13V1786.96H5148.01V1783L5159.05 1765.48H5165.13V1783.48ZM5161.13 1768.76L5151.89 1783.48H5161.13V1768.76Z",
      },
    ],
  },
  {
    type: E_GolfSeatType.Deluxe,
    name: E_GolfBay.V05,
    svgConfig: [
      {
        path: "M5219.74 1697.34C5218.82 1691.34 5223.4 1685.91 5229.47 1685.82L5365.67 1683.63C5370.49 1683.56 5374.69 1686.94 5375.63 1691.67L5406.65 1846.76C5407.87 1852.87 5403.26 1858.6 5397.03 1858.72L5253.74 1861.34C5248.73 1861.43 5244.43 1857.81 5243.67 1852.86L5219.74 1697.34Z",
      },
      {
        path: "M5289.17 1785L5297.13 1762.48H5301.49L5291.37 1790H5286.97L5276.85 1762.48H5281.21L5289.17 1785ZM5313.61 1790.48C5306.65 1790.48 5303.45 1784.04 5303.45 1776.28C5303.45 1768.24 5306.65 1762.04 5313.61 1762.04C5320.57 1762.04 5323.81 1768.24 5323.81 1776.28C5323.81 1784.28 5320.57 1790.48 5313.61 1790.48ZM5313.61 1787.16C5317.93 1787.16 5319.65 1782.6 5319.65 1776.28C5319.65 1769.92 5317.93 1765.36 5313.61 1765.36C5309.33 1765.36 5307.61 1769.92 5307.61 1776.28C5307.61 1782.6 5309.33 1787.16 5313.61 1787.16ZM5338.3 1771.36C5343.74 1771.36 5347.22 1775.28 5347.22 1780.64C5347.22 1786.28 5343.02 1790.48 5337.3 1790.48C5331.78 1790.48 5328.38 1787.24 5327.54 1782.68L5331.66 1781.88C5332.22 1785.04 5334.14 1787.16 5337.38 1787.16C5340.82 1787.16 5343.02 1784.56 5343.02 1780.72C5343.02 1777.2 5341.1 1774.68 5337.7 1774.68C5334.9 1774.68 5332.98 1776.68 5332.38 1778.24L5328.62 1777.52L5329.7 1762.48H5345.22V1766.08H5333.06L5332.5 1773.6H5332.54C5333.62 1772.48 5335.58 1771.36 5338.3 1771.36Z",
      },
    ],
  },
  {
    type: E_GolfSeatType.Deluxe,
    name: E_GolfBay.V06,
    svgConfig: [
      {
        path: "M5392.36 1695.78C5391.13 1689.65 5395.77 1683.91 5402.02 1683.82L5547.09 1681.62C5551.73 1681.55 5555.8 1684.67 5556.94 1689.17L5595.91 1843.27C5597.49 1849.52 5592.83 1855.61 5586.38 1855.72L5433.34 1858.36C5428.51 1858.44 5424.31 1855.06 5423.36 1850.32L5392.36 1695.78Z",
      },
      {
        path: "M5469.28 1782L5477.24 1759.48H5481.6L5471.48 1787H5467.08L5456.96 1759.48H5461.32L5469.28 1782ZM5493.71 1787.48C5486.75 1787.48 5483.55 1781.04 5483.55 1773.28C5483.55 1765.24 5486.75 1759.04 5493.71 1759.04C5500.67 1759.04 5503.91 1765.24 5503.91 1773.28C5503.91 1781.28 5500.67 1787.48 5493.71 1787.48ZM5493.71 1784.16C5498.03 1784.16 5499.75 1779.6 5499.75 1773.28C5499.75 1766.92 5498.03 1762.36 5493.71 1762.36C5489.43 1762.36 5487.71 1766.92 5487.71 1773.28C5487.71 1779.6 5489.43 1784.16 5493.71 1784.16ZM5518.17 1787.48C5511.17 1787.48 5508.09 1781.68 5508.09 1773.84C5508.09 1764.24 5512.21 1759.04 5518.65 1759.04C5523.69 1759.04 5526.53 1762.08 5527.25 1766.04L5523.33 1766.8C5522.93 1764.08 5521.33 1762.28 5518.49 1762.28C5513.77 1762.28 5512.05 1767.2 5512.01 1773.04L5512.05 1773.08C5513.01 1771.2 5515.25 1769.32 5518.73 1769.32C5524.25 1769.32 5527.65 1773.16 5527.65 1778.24C5527.65 1783.56 5523.73 1787.48 5518.17 1787.48ZM5518.29 1784.16C5521.33 1784.16 5523.61 1781.96 5523.61 1778.32C5523.61 1774.56 5521.25 1772.64 5518.29 1772.64C5515.13 1772.64 5512.77 1775.08 5512.77 1778.28C5512.77 1781.76 5515.09 1784.16 5518.29 1784.16Z",
      },
    ],
  },
  {
    type: E_GolfSeatType.Deluxe,
    name: E_GolfBay.V07,
    svgConfig: [
      {
        path: "M5572.11 1694.26C5570.52 1688 5575.19 1681.9 5581.65 1681.8L5723.44 1679.62C5727.91 1679.55 5731.89 1682.45 5733.18 1686.74L5779.2 1839.86C5781.11 1846.2 5776.43 1852.62 5769.8 1852.73L5620.92 1855.36C5616.28 1855.44 5612.19 1852.32 5611.05 1847.82L5572.11 1694.26Z",
      },
      {
        path: "M5652.28 1779L5660.24 1756.48H5664.6L5654.48 1784H5650.08L5639.96 1756.48H5644.32L5652.28 1779ZM5676.71 1784.48C5669.75 1784.48 5666.55 1778.04 5666.55 1770.28C5666.55 1762.24 5669.75 1756.04 5676.71 1756.04C5683.67 1756.04 5686.91 1762.24 5686.91 1770.28C5686.91 1778.28 5683.67 1784.48 5676.71 1784.48ZM5676.71 1781.16C5681.03 1781.16 5682.75 1776.6 5682.75 1770.28C5682.75 1763.92 5681.03 1759.36 5676.71 1759.36C5672.43 1759.36 5670.71 1763.92 5670.71 1770.28C5670.71 1776.6 5672.43 1781.16 5676.71 1781.16ZM5690.2 1763.76V1756.48H5709.92V1759.56C5705.36 1767.36 5701.68 1775.68 5699.88 1784H5695.52C5697.36 1775.92 5701.64 1766.8 5705.96 1759.96H5694.08V1763.76H5690.2Z",
      },
    ],
  },
  {
    type: E_GolfSeatType.Deluxe,
    name: E_GolfBay.V08,
    svgConfig: [
      {
        path: "M5747.82 1692.67C5745.9 1686.32 5750.6 1679.89 5757.24 1679.79L5893.78 1677.62C5898.09 1677.55 5901.96 1680.25 5903.38 1684.32L5956.45 1836.46C5958.69 1842.89 5954 1849.63 5947.19 1849.75L5803.57 1852.36C5799.09 1852.44 5795.11 1849.54 5793.81 1845.25L5747.82 1692.67Z",
      },
      {
        path: "M5829.26 1776L5837.22 1753.48H5841.58L5831.46 1781H5827.06L5816.94 1753.48H5821.3L5829.26 1776ZM5853.7 1781.48C5846.74 1781.48 5843.54 1775.04 5843.54 1767.28C5843.54 1759.24 5846.74 1753.04 5853.7 1753.04C5860.66 1753.04 5863.9 1759.24 5863.9 1767.28C5863.9 1775.28 5860.66 1781.48 5853.7 1781.48ZM5853.7 1778.16C5858.02 1778.16 5859.74 1773.6 5859.74 1767.28C5859.74 1760.92 5858.02 1756.36 5853.7 1756.36C5849.42 1756.36 5847.7 1760.92 5847.7 1767.28C5847.7 1773.6 5849.42 1778.16 5853.7 1778.16ZM5877.87 1781.48C5872.07 1781.48 5867.99 1778.4 5867.99 1773.36C5867.99 1769.16 5870.79 1767.16 5873.07 1766.32V1766.28C5871.39 1765.64 5868.87 1763.64 5868.87 1760.36C5868.87 1755.88 5872.79 1753 5877.87 1753C5882.91 1753 5886.87 1755.88 5886.87 1760.36C5886.87 1763.64 5884.35 1765.64 5882.63 1766.28V1766.32C5884.91 1767.16 5887.71 1769.16 5887.71 1773.36C5887.71 1778.4 5883.67 1781.48 5877.87 1781.48ZM5877.87 1764.96C5880.75 1764.96 5882.79 1763.08 5882.79 1760.6C5882.79 1758.16 5880.83 1756.28 5877.87 1756.28C5874.87 1756.28 5872.91 1758.16 5872.91 1760.6C5872.91 1763.08 5874.99 1764.96 5877.87 1764.96ZM5877.87 1778.16C5881.35 1778.16 5883.63 1776.12 5883.63 1773.12C5883.63 1770.2 5881.35 1768.16 5877.87 1768.16C5874.39 1768.16 5872.07 1770.2 5872.07 1773.12C5872.07 1776.12 5874.39 1778.16 5877.87 1778.16Z",
      },
    ],
  },

  //premiumBay
  {
    type: E_GolfSeatType.Premium,
    name: E_GolfBay.V01,
    svgConfig: [
      {
        path: "M4339.84 1705.72C4339.93 1700.31 4344.31 1695.95 4349.72 1695.89L4669.66 1692.12C4675.12 1692.05 4679.62 1696.38 4679.78 1701.84L4684.22 1860.4C4684.37 1865.96 4679.96 1870.58 4674.39 1870.67L4347.34 1876.32C4341.69 1876.42 4337.08 1871.81 4337.17 1866.16L4339.84 1705.72Z",
      },
      {
        path: "M4492.1 1798L4500.06 1775.48H4504.42L4494.3 1803H4489.9L4479.78 1775.48H4484.14L4492.1 1798ZM4516.53 1803.48C4509.57 1803.48 4506.37 1797.04 4506.37 1789.28C4506.37 1781.24 4509.57 1775.04 4516.53 1775.04C4523.49 1775.04 4526.73 1781.24 4526.73 1789.28C4526.73 1797.28 4523.49 1803.48 4516.53 1803.48ZM4516.53 1800.16C4520.85 1800.16 4522.57 1795.6 4522.57 1789.28C4522.57 1782.92 4520.85 1778.36 4516.53 1778.36C4512.25 1778.36 4510.53 1782.92 4510.53 1789.28C4510.53 1795.6 4512.25 1800.16 4516.53 1800.16ZM4540.42 1803H4536.42V1784.24H4529.74V1781.32H4530.1C4534.58 1781.32 4536.86 1780 4537.06 1775.76L4537.1 1775.48H4540.42V1803Z",
      },
    ],
  },
  {
    type: E_GolfSeatType.Premium,
    name: E_GolfBay.V09,
    svgConfig: [
      {
        path: "M5918.74 1691.22C5916.42 1684.75 5921.17 1677.92 5928.04 1677.84L6263.71 1674.08C6267.81 1674.03 6271.52 1676.49 6273.08 1680.28L6334.44 1829.95C6337.11 1836.46 6332.4 1843.63 6325.36 1843.74L5982.66 1849.38C5978.38 1849.45 5974.53 1846.79 5973.08 1842.76L5918.74 1691.22Z",
      },
      {
        path: "M6102.28 1771L6110.24 1748.48H6114.6L6104.48 1776H6100.08L6089.96 1748.48H6094.32L6102.28 1771ZM6126.71 1776.48C6119.75 1776.48 6116.55 1770.04 6116.55 1762.28C6116.55 1754.24 6119.75 1748.04 6126.71 1748.04C6133.67 1748.04 6136.91 1754.24 6136.91 1762.28C6136.91 1770.28 6133.67 1776.48 6126.71 1776.48ZM6126.71 1773.16C6131.03 1773.16 6132.75 1768.6 6132.75 1762.28C6132.75 1755.92 6131.03 1751.36 6126.71 1751.36C6122.43 1751.36 6120.71 1755.92 6120.71 1762.28C6120.71 1768.6 6122.43 1773.16 6126.71 1773.16ZM6150.13 1776.48C6145.05 1776.48 6142.21 1773.44 6141.53 1769.48L6145.45 1768.72C6145.85 1771.44 6147.45 1773.24 6150.29 1773.24C6155.01 1773.24 6156.69 1768.32 6156.77 1762.52L6156.73 1762.48C6155.73 1764.32 6153.53 1766.2 6150.05 1766.2C6144.53 1766.2 6141.13 1762.36 6141.13 1757.28C6141.13 1751.96 6145.01 1748.04 6150.61 1748.04C6157.61 1748.04 6160.69 1753.84 6160.69 1761.68C6160.69 1771.28 6156.57 1776.48 6150.13 1776.48ZM6150.49 1762.88C6153.61 1762.88 6156.01 1760.44 6156.01 1757.24C6156.01 1753.8 6153.69 1751.36 6150.49 1751.36C6147.45 1751.36 6145.13 1753.56 6145.13 1757.2C6145.13 1760.96 6147.49 1762.88 6150.49 1762.88Z",
      },
    ],
  },
];

export default golfLayoutConfig;

// <g key={"G22_Round"}>
//                   <path
//                     d="M4220.71 1697.59C4220.87 1691.84 4225.82 1687.41 4231.55 1687.9L4311.75 1694.71C4316.96 1695.16 4320.96 1699.55 4320.9 1704.79L4319.12 1866.26C4319.05 1872.06 4314.08 1876.59 4308.3 1876.12L4225.43 1869.28C4220.14 1868.84 4216.11 1864.35 4216.26 1859.04L4220.71 1697.59Z"
//                     fill="white"
//                     stroke="#8ED8F8"
//                     strokeWidth="10"
//                     strokeLinejoin="round"
//                   />
//                 </g>
