import { AxiosError } from "axios";
import { GlobalContextStore } from "common/context/providers/globalProvider";
import {
  appearGlobalError,
  appearGlobalLoading,
  disappearGlobalLoading,
} from "common/context/requests/globalRequest";
import { IGlobalProviderContext } from "common/models/Common";
import { getLocalisedString } from "common/utils/stringHelper";
import { useContext } from "react";
import _ from "lodash";
import { useTranslation } from "react-i18next";

interface IFetcherProps {
  api: any;
  skipErrorHandle?: boolean;
  skipLoading?: boolean;
}

export const useCommonFetcher = () => {
  const { t } = useTranslation("common");
  const { globalDispatch } = useContext<IGlobalProviderContext>(
    GlobalContextStore as any
  );

  const fetcher = async ({
    api,
    skipErrorHandle = false,
    skipLoading = false,
  }: IFetcherProps) => {
    try {
      !skipLoading && appearGlobalLoading(globalDispatch);
      const res = await api();
      !skipLoading && disappearGlobalLoading(globalDispatch);

      return res;
    } catch (e) {
      !skipLoading && disappearGlobalLoading(globalDispatch);

      if (e instanceof AxiosError) {
        const errorMsg = getLocalisedString(
          e?.response?.data?.error?.localizedMessage?.en,
          e?.response?.data?.error?.localizedMessage?.zh
        );

        !skipErrorHandle &&
          appearGlobalError(
            globalDispatch,
            !_.isEmpty(errorMsg) ? errorMsg : t("error.internalServerError")
          );

        return e?.response?.data;
      }
    }
  };

  return { fetcher };
};
