import Typography from "components/common/Typography";
import { Timeslot } from "models/Facility";
import { useTranslation } from "react-i18next";
import Styled from "./Styled";
import theme from "utils/theme";
import IconStar from "../../assets/ic-facility-peakStar.svg";
import dayjs from "dayjs";
import * as isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import { UserViewModelContext } from "common/viewModels/UserViewModel";
import { useContext } from "react";
import { IUserViewModelContext } from "../../@types/common";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Asia/Hong_Kong");
dayjs.extend(isSameOrAfter.default);

interface TimeSelectProps {
  timeslots: Timeslot[];
  selectedTimeslots: Map<string, Timeslot>;
  onPressTimeslot: (time: Timeslot) => void;
  dateSelected: string;
  shouldUseGolfMapLayout?: boolean;
}

const TimeSelectTable = ({
  timeslots,
  selectedTimeslots,
  onPressTimeslot,
  dateSelected,
  shouldUseGolfMapLayout,
}: TimeSelectProps) => {
  const { t } = useTranslation("booking");
  const { currentUser } =
    useContext<IUserViewModelContext>(UserViewModelContext);

  const isTodayAndPassed = (item: Timeslot) => {
    //TODO graceful minute before booking
    const startHour = dayjs.tz(dateSelected + " " + item.start_time);
    const currentTime = dayjs.tz();
    return startHour.isBefore(currentTime);
  };

  return (
    <div>
      <div className="mb-[15px] flex flex-col gap-[15px] md:flex-row md:gap-0 md:justify-between">
        <Typography fontWeight="500" fontSize="16px">
          {t("facilityDetail.time")}
        </Typography>
        <div className="flex items-center">
          <img src={IconStar} className="mr-[5px]" />
          <Typography fontSize="14px">
            {t("facilityDetail.peakHour")}
          </Typography>
        </div>
      </div>
      <div className="grid grid-cols-2 gap-5 md:flex md:flex-wrap">
        {timeslots?.length > 0 ? (
          timeslots?.map((time, idx) => {
            const selected = !!selectedTimeslots.get(time.getDisplayTimeslot());
            const disabled =
              time.bookingUnavailable() || isTodayAndPassed(time);
            return (
              <Styled.TimeLabel
                key={idx}
                disabled={disabled}
                selected={selected}
                isGuest={!currentUser}
                onClick={() =>
                  disabled || !currentUser ? null : onPressTimeslot(time)
                }
              >
                <Typography
                  whiteSpace="nowrap"
                  color={disabled || selected ? theme.white : theme.black}
                >
                  {time.getDisplayTimeslot()}
                </Typography>
                {time.isPeakHour && <Styled.ImageStar src={IconStar} />}
              </Styled.TimeLabel>
            );
          })
        ) : (
          <Typography color={theme.orange}>
            {shouldUseGolfMapLayout
              ? t("facilityDetail.noGolfVenueSelected")
              : t("facilityDetail.noAvailableTimeslot")}
          </Typography>
        )}
      </div>
    </div>
  );
};

export default TimeSelectTable;
