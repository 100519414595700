import FacilityBanner from "assets/bg-facility-banner.png";
import { GlobalContextStore } from "common/context/providers/globalProvider";
import {
  Facility,
  FacilityPurpose,
  IFacilityPurpose,
} from "common/models/Facility";
import { Venue } from "models/Facility";
import { getLocalisedString } from "common/utils/stringHelper";
import { useGolfMapViewModel } from "common/viewModels/FacilityBooking/GolfMapViewModel";
import { UserViewModelContext } from "common/viewModels/UserViewModel";
import GeneralButton from "components/common/Button";
import { DateCalendarPicker } from "components/common/DatePicker";
import HTMLTypography from "components/common/HTMLTypography";
import PageBackButton from "components/common/PageBackButton";
import PageContainer from "components/common/PageContainer";
import DropdownSelect from "components/common/Select/DropdownSelect";
import Space from "components/common/Space";
import Typography from "components/common/Typography";
import config from "config";
import dayjs from "common/utils/dayjs";
import _ from "lodash";
import { IGolfMapViewModel } from "models/Facility";
import AttendessSelect from "pageComponents/booking/AttendessSelect";
import BaySlider from "pageComponents/facility/BaySlider";
import ColorBannerCard from "pageComponents/facility/ColorBannerCard";
import FacilityRuleCheckbox from "pageComponents/facility/FacilityRuleCheckbox";
import TimeSelectTable from "pageComponents/facility/TimeSelectTable";
import TimeSlotPriceTable from "pageComponents/facility/TimeSlotPriceTable";
import TncCheckbox from "components/common/checkbox/TncCheckbox";
import VenuePicker from "pageComponents/facility/VenuePicker";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import theme from "utils/theme";
import {
  IGlobalProviderContext,
  IUserViewModelContext,
} from "../../@types/common";
import IconPeople from "../../assets/ic-facilityPeople.svg";
import {
  facilityDetailContext,
  IFacilityDetailContextType,
} from "./FacilityDetailProvider";
import { IFacilityContextType } from "./FacilityScreen";
import GolfMap from "pageComponents/facility/GolfMap";
import { removeHtmlTag } from "utils/common";
import { Helmet } from "react-helmet";
import { formatDateToString } from "common/utils/DateHelper";
import { getLocalizedPageTitleWithPrefix } from "utils/seo";
import { on } from "events";

type VenueViewRenderConfig = {
  PurposeSelect: boolean;
  VenueSelect: boolean;
  DatePicker: boolean;
  TimeSelectTable: boolean;
  NumOfAttendee: boolean;
  RuleTncSection: boolean;
  BookNowButton: boolean;
};

const FacilityDetailPage = () => {
  const {
    setFacility,
    setVenue,
    setSelectedAttendees,
    setSelectedDate,
    setSelectedTimeslots,
    setBookingIds,
    setSelectedPurposeId,
  } = useOutletContext<IFacilityContextType>();
  const { globalDispatch } = useContext<IGlobalProviderContext>(
    GlobalContextStore as any
  );

  const { currentUser, refreshCurrentUser } =
    useContext<IUserViewModelContext>(UserViewModelContext);
  const { facilityId } = useParams();

  const { facilityDetailViewModel } = useContext(
    facilityDetailContext
  ) as IFacilityDetailContextType;

  const {
    dateSelected,
    timetable,
    timeslots,
    selectedTimeslots,
    selectedVenue,
    filter,
    definedBays,
    onSelectFilter,
    onSelectVenue,
    venueList,
    venueOptions,
    shouldDisplayVenuePicker,
    numOfAttendees,
    maxAttendeesPerBooking,
    remainingQuota,
    selectedPurpose,
    facility: facilityDetail,
    onlyOneVenue,
    onSelectPurpose,
    validateForm,
    onPressAddAttendees,
    onPressSubtractAttendees,
    onDateSelected,
    onPressTimeslot,
    reserveTimeslot,
    getVenueTimeslotByDate,
    getSubtotalPrice,
  } = facilityDetailViewModel;

  const { golfMaps } = useGolfMapViewModel({ facilityId }) as IGolfMapViewModel;

  const { t, i18n } = useTranslation("booking");
  const navigate = useNavigate();
  const [openCalendar, setOpenCalendar] = useState<HTMLButtonElement | null>(
    null
  );
  const [acceptTnc, setAcceptTnc] = useState<boolean>(false);
  const [acceptRules, setAcceptRules] = useState<boolean>(false);
  const [isReservingTimeslot, setIsReservingTimeslot] = useState(false);
  const [isOpenRemark, setIsOpenRemark] = useState<boolean>(false);
  const isVenuePickerShown =
    facilityDetail && facilityDetail.isGolf()
      ? facilityDetail.shouldUseGolfMapLayout
        ? true
        : !onlyOneVenue
      : !onlyOneVenue;

  const venueViewRenderConfig: VenueViewRenderConfig = {
    PurposeSelect:
      venueList.length > 0 &&
      !_.isEmpty(facilityDetail?.purpose) &&
      facilityDetail?.isMultiVenueFacility,
    VenueSelect:
      facilityDetail &&
      facilityDetail.isGolf() &&
      facilityDetail.shouldUseGolfMapLayout
        ? true
        : venueList.length > 0,

    DatePicker: venueList.length > 0,
    TimeSelectTable: venueList.length > 0,
    NumOfAttendee:
      venueList.length > 0 &&
      facilityDetail?.isHeadCount &&
      facilityDetail?.isHeadCount() &&
      selectedTimeslots.size > 0,
    RuleTncSection: venueList.length > 0 && currentUser,
    BookNowButton: venueList.length > 0 && currentUser,
  };

  useEffect(() => {
    // now always go back on refresh
    onDateSelected(formatDateToString(dayjs(), "YYYY-MM-DD"));
  }, [facilityDetail]);

  useEffect(() => {
    if (venueList && venueList.length === 1) {
      onSelectVenue(venueList?.[0]?._id || "");
    }
  }, [venueList]);

  const handleCloseCalendar = () => {
    setOpenCalendar(null);
  };

  const goToBookingDetail = async () => {
    setIsReservingTimeslot(true);
    const userChecker = await refreshCurrentUser({
      checkIsNewUser: true,
    });

    if (userChecker?.success && userChecker?.isNewUser === false) {
      try {
        const result = (await reserveTimeslot({
          dispatch: globalDispatch,
        })) as {
          bookingIds: string[];
          venue: Venue;
        };
        const { bookingIds: mongoInsertedId, venue } = result;
        // these info should update to use api result
        setSelectedAttendees(numOfAttendees);
        setSelectedDate(dateSelected);
        setSelectedTimeslots(selectedTimeslots);
        setSelectedPurposeId(selectedPurpose);
        setFacility(facilityDetail);
        setVenue(venue);

        setBookingIds(mongoInsertedId);
        console.log(
          "🚀 ~ goToBookingDetail ~ mongoInsertedId",
          mongoInsertedId
        );
        navigate(`../../venue/${venue._id}/booking`);
      } catch (err) {
        console.log("🚀 ~ goToBookingDetail ~ err:", err);
        // Note: ViewModel handled show error
      }
    }
    setIsReservingTimeslot(false);
  };

  if (isOpenRemark) {
    return (
      <GolfMap
        facilityDetail={facilityDetail}
        handleCloseMap={() => setIsOpenRemark(false)}
      />
    );
  }

  const getMetaDescription = () => {
    const facilityDesc =
      i18n.language === "en"
        ? facilityDetail?.desc_en
        : facilityDetail?.desc_tc ?? "";
    return removeHtmlTag(facilityDesc);
  };
  const _renderSelectOptionsCard = () => {
    return (
      <ColorBannerCard title={t("facilityDetail.selectOptions")}>
        <Typography>{t("facilityDetail.bookingfeeDesc")}</Typography>

        <hr className="my-[30px]" />

        {!_.isEmpty(facilityDetail?.purpose) &&
          facilityDetail?.isMultiVenueFacility && (
            <div>
              <Typography fontWeight="500">
                {t("facilityDetail.purpose")}
              </Typography>
              <Space size="10px" />
              <DropdownSelect
                options={(facilityDetail?.purpose as FacilityPurpose[]).map(
                  (item: IFacilityPurpose) => ({
                    label: getLocalisedString(
                      item?.purpose_en,
                      item?.purpose_tc
                    ),
                    value: item?._id,
                  })
                )}
                placeholder={t("facilityDetail.purposePickerPlaceholder")}
                value={selectedPurpose}
                onChange={onSelectPurpose}
              />
              <hr className="my-[30px]" />
            </div>
          )}
        {isVenuePickerShown && (
          <>
            <VenuePicker
              venueList={venueList}
              facility={facilityDetail}
              selectedVenueId={selectedVenue}
              selectedBay={filter}
              setSelectedVenue={onSelectVenue}
              onClickRemark={() => setIsOpenRemark(true)}
              venueOptions={venueOptions}
              onSelectBayFilter={onSelectFilter}
              definedBays={definedBays}
              shouldDisplayVenuePicker={shouldDisplayVenuePicker}
            />
            <hr className="my-[30px]" />
          </>
        )}
        {venueList.length > 0 && (
          <>
            <DateCalendarPicker
              id="dateSelected"
              name="dateSelected"
              onChange={(e) => {
                onDateSelected(e);
                handleCloseCalendar();
              }}
              style={{ margin: 0 }}
              defaultValue={dayjs.tz(Date.now())}
              minDate={dayjs.tz(Date.now())} // Default as today
              maxDate={dayjs
                .tz(Date.now())
                .add(config.FACILITY_AVAILBLE_DAY - 1, "day")} //Dafault 14 days
            />
            {/* </Popover> */}
            <TimeSelectTable
              timeslots={timeslots}
              selectedTimeslots={selectedTimeslots}
              onPressTimeslot={onPressTimeslot}
              dateSelected={dateSelected.toString()}
              shouldUseGolfMapLayout={facilityDetail.shouldUseGolfMapLayout}
            />

            {facilityDetail?.isHeadCount &&
              facilityDetail?.isHeadCount() &&
              selectedTimeslots.size > 0 && (
                <>
                  <hr className="my-[30px]" />
                  <div className="bg-[#D1D1D1] p-[15px] md:p-[25px] rounded-[5px]">
                    <AttendessSelect
                      quantity={numOfAttendees}
                      handleAdd={onPressAddAttendees}
                      handleSub={onPressSubtractAttendees}
                      maxQuota={maxAttendeesPerBooking}
                      remainingQuota={remainingQuota}
                    />
                    <hr className="my-[15px]" />
                    <Typography
                      color={theme.black}
                      className="flex justify-end"
                    >{`HK$ ${getSubtotalPrice()}`}</Typography>
                  </div>
                </>
              )}

            <hr className="my-[30px]" />

            {currentUser && (
              <>
                <TncCheckbox
                  id="tnc"
                  name="tnc"
                  value={acceptTnc}
                  data={facilityDetail?.tandc}
                  onChange={() => setAcceptTnc((prev) => !prev)}
                />
                <Space size="10px" />
                <FacilityRuleCheckbox
                  id="ageLimit"
                  name="ageLimit"
                  value={acceptRules}
                  text={t("facilityDetail.termsConditionsStatement")}
                  clickableText={t("facilityRule.facilityRulePressable")}
                  onChange={() => setAcceptRules((prev) => !prev)}
                />
                <div className="flex justify-end">
                  <GeneralButton
                    disabled={
                      !acceptRules ||
                      (!acceptTnc && !_.isEmpty(facilityDetail?.tandc)) ||
                      isReservingTimeslot ||
                      !validateForm()
                    }
                    onClick={goToBookingDetail}
                  >
                    {t("facility.btnBkNow")}
                  </GeneralButton>
                </div>
              </>
            )}
          </>
        )}
      </ColorBannerCard>
    );
  };

  return (
    <>
      <Helmet>
        <title>
          {getLocalizedPageTitleWithPrefix({
            en: facilityDetail?.name_en,
            tc: facilityDetail?.name_tc,
          })}
        </title>
        <meta name="description" content={getMetaDescription()} />
      </Helmet>
      <PageContainer
        title={facilityDetail?.getName() ?? t("headerTitle.facilityBooking")}
        banner={facilityDetail?.thumbnail_photo_ref ?? FacilityBanner}
        screenHeader={`${t("headerTitle.facilityBooking")} (${
          getLocalisedString(
            facilityDetail?.name_en,
            facilityDetail?.name_tc
          ) ?? t("headerTitle.facilityBooking")
        })`}
      >
        <Typography
          fontSize="30px"
          fontWeight="500"
          color={theme.black}
          className="mb-[50px]"
        >
          {facilityDetail?.getName()}
        </Typography>
        <HTMLTypography content={facilityDetail?.getDesc()} />

        <div className="flex items-center my-[35px]">
          {/* <div className="flex items-center mr-[20px]">
          <img src={IconLocation} className="mr-[10px]" />
          <Typography>
            {t("venue.zone", { zone: facilityDetail?.zone ?? "A" })}
          </Typography>
        </div> */}
          <div className="flex items-center">
            <img src={IconPeople} className="mr-[10px]" />
            <Typography>
              {t("venue.capacity_people", {
                capacity: facilityDetail?.venue_capacity,
              })}
            </Typography>
          </div>
        </div>

        <ColorBannerCard title={t("facility.headerTitle")}>
          <TimeSlotPriceTable timetable={timetable} />
        </ColorBannerCard>

        <Space size="40px" />

        {/**TODO: render the selectOptions with VenueViewRenderConfig */}
        {venueList.length > 0
          ? _renderSelectOptionsCard()
          : facilityDetail &&
            facilityDetail?.isGolf() &&
            facilityDetail.shouldUseGolfMapLayout &&
            _renderSelectOptionsCard()}
      </PageContainer>
    </>
  );
};

export default FacilityDetailPage;
