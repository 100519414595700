type SemVer = {
  major: number,
  minor: number,
  patch: number
}
export const parseSemVer = (version: string): SemVer => {
  const [major, minor, patch] = version.split('.').map(Number);
  return { major, minor, patch };
}


export const isSemVerGreater = (version1: SemVer, version2: SemVer): boolean => {
  if (version1.major > version2.major) {
    return true;
  } else if (version1.major === version2.major) {
    if (version1.minor > version2.minor) {
      return true;
    } else if (version1.minor === version2.minor) {
      if (version1.patch > version2.patch) {
        return true;
      }
    }
  }
  return false;
}


export const semVerToString = (version: SemVer): string => {
  return `${version.major}.${version.minor}.${version.patch}`;
}

