"use client";

import React, { useEffect, useState, useRef } from "react";

import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

import golfLayoutConfig, {
  T_GolfListAPIDataArray,
  defaultGolfSvgConfig,
  supportedGolfBayList,
  T_GolfSeatData,
  T_GolfBay,
  validateGolfListAPI,
  I_ActiveGolfSeatConfig,
} from "./golfLayoutConfig";

export default function GolfLayoutComponent({
  golfSeatDataProps,
  selectedFunc,
}: {
  golfSeatDataProps?: T_GolfListAPIDataArray;
  selectedFunc?({
    selectedSeat,
    data,
  }: {
    selectedSeat: string;
    data?: any;
  }): void;
}) {
  const [golfSeatData, setGolfSeatData] = useState<T_GolfSeatData | null>(null);

  const [golfSeatDataKeyList, setGolfSeatDataKeyList] = useState<string[]>([]);

  const transformWrapperRef = useRef<HTMLDivElement | null>(null);

  const [containerDimensions, setContainerDimensions] = useState({
    width: 0,
    height: 0,
  });

  const [selectedSeat, setSelectedSeat] = useState<string | null>(null);

  const [activeGolfSeatType, setActiveGolfSeatType] =
    useState<I_ActiveGolfSeatConfig>({
      Regular: false,
      Deluxe: false,
      Premium: false,
      Open: false,
    });

  useEffect(() => {
    const initHanlder = async () => {
      if (!golfSeatDataProps) {
        console.error(
          "golfSeatDataProps is required and the value should be an array of object and the object should have a key of 'name_en' and 'status'"
        );
        throw new Error(
          "golfSeatDataProps is required and the value should be an array of object and the object should have a key of 'name_en' and 'status'"
        );
      } else {
        const validateGolfListAPIResult =
          await validateGolfListAPI(golfSeatDataProps);

        if (validateGolfListAPIResult.result === false) {
          console.error(validateGolfListAPIResult.err);
          throw new Error(validateGolfListAPIResult.err);
        }
      }
    };

    initHanlder();
  }, []);

  useEffect(() => {
    if (transformWrapperRef.current) {
      setContainerDimensions({
        width: transformWrapperRef?.current?.offsetWidth,
        height: transformWrapperRef?.current?.offsetHeight,
      });
    }

    const windowResizeFunc = () => {
      if (transformWrapperRef.current) {
        setContainerDimensions({
          width: transformWrapperRef?.current?.offsetWidth,
          height: transformWrapperRef?.current?.offsetHeight,
        });
      }
    };

    window?.addEventListener("resize", windowResizeFunc);

    return () => {
      window?.removeEventListener("resize", windowResizeFunc);
    };
  }, []);

  useEffect(() => {
    if (golfSeatData) {
      setGolfSeatDataKeyList(Object.keys(golfSeatData));
    } else {
      setGolfSeatDataKeyList([]);
    }
  }, [golfSeatData]);

  const golfListAPIDataHandler = (
    golfSeatDataProps: T_GolfListAPIDataArray
  ): T_GolfSeatData | null => {
    if (golfSeatDataProps && golfSeatDataProps.length > 0) {
      const tmpGolfSeatData: T_GolfSeatData = {} as T_GolfSeatData;
      golfSeatDataProps.map((data) => {
        if (
          supportedGolfBayList.includes(String(data.name_en)) === true &&
          data?.status &&
          String(data.status).toLowerCase() === "active"
        ) {
          tmpGolfSeatData[String(data.name_en) as T_GolfBay] = {
            name: String(data.name_en) as T_GolfBay,
            active: true,
            data: data,
          };
        }
      });
      return tmpGolfSeatData;
    } else {
      return null;
    }
  };

  const checkActiveGolfSeatType = (golfSeatData: T_GolfSeatData) => {
    if (golfSeatData) {
      const tmpActiveGolfSeatType: I_ActiveGolfSeatConfig = {
        Regular: false,
        Deluxe: false,
        Premium: false,
        Open: false,
      };

      const checkedActiveGolfSeatType: string[] = [];

      golfLayoutConfig.map((golfSeatConfig) => {
        if (golfSeatData[golfSeatConfig.name]?.active === true) {
          if (
            checkedActiveGolfSeatType.includes(golfSeatConfig.type) === false
          ) {
            checkedActiveGolfSeatType.push(golfSeatConfig.type);
          }
        }
      });

      checkedActiveGolfSeatType.map((type) => {
        tmpActiveGolfSeatType[type as keyof I_ActiveGolfSeatConfig] = true;
      });

      return tmpActiveGolfSeatType;
    } else {
      return {
        Regular: false,
        Deluxe: false,
        Premium: false,
        Open: false,
      };
    }
  };

  useEffect(() => {
    const excuteGolfListAPIDataHandler = async () => {
      if (golfSeatDataProps) {
        const convertedGolfSeatData =
          await golfListAPIDataHandler(golfSeatDataProps);
        if (convertedGolfSeatData) {
          const checkedActiveGolfSeatType = checkActiveGolfSeatType(
            convertedGolfSeatData
          );
          setGolfSeatData(convertedGolfSeatData);
          setActiveGolfSeatType(checkedActiveGolfSeatType);
        } else {
          setGolfSeatData(null);
        }
      }
    };
    excuteGolfListAPIDataHandler();
  }, [golfSeatDataProps]);

  return (
    // <div className="w-[80%] h-[1720px] bg-slate-900">
    <>
      {/* <div className=" text-white">{JSON.stringify(containerDimensions)}</div> */}
      {/* <div className=" text-white">{JSON.stringify(golfSeatData)}</div>
      <div className=" text-white">{JSON.stringify(golfSeatDataKeyList)}</div> */}
      {/* <div className=" text-white">{JSON.stringify(golfSeatDataProps)}</div> */}
      <div className="w-full h-full flex items-center justify-center">
        <div
          ref={transformWrapperRef}
          className="w-full aspect-[4/3] bg-[#EAE1D3] flex items-center justify-center"
        >
          <TransformWrapper
            initialScale={1}
            // initialPositionX={containerDimensions.width * 0.5}
            // initialPositionY={containerDimensions.height * 0.8}
            //  minScale={0.5}
            minScale={1}
            maxScale={3}
            doubleClick={{ disabled: true }}
            alignmentAnimation={{ sizeX: 0, sizeY: 0 }}
            limitToBounds={true}
            centerZoomedOut={true}
          >
            <TransformComponent wrapperClass="w-full" contentClass="w-full">
              <svg
                width={containerDimensions.width}
                height={containerDimensions.height}
                // height={containerDimensions.width / (6756 / 4736)}
                viewBox="0 0 6756 2368"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="6756" height="2368" fill="#EAE1D3" />
                <path
                  d="M2782.49 173.174C2134.92 169.414 2168.35 311.729 2553.2 387.587C2938.04 463.445 2676.2 562.52 2323.2 650.174C1844.4 769.071 1509.15 842.574 1297.62 895.731C637.393 1061.64 98.0613 1507.86 425.57 1590.18C753.078 1672.5 3020.04 1693.14 3613.38 1693.14C6043.22 1693.14 6289 1710.85 6289 1670.4C6289 1629.95 6073.53 1142.21 5263.13 981.492C4492.09 828.583 4406.43 805.351 4231.51 678.384C4056.59 551.418 4536.52 401.539 4656.36 353.841C4776.2 306.142 5012.28 157.34 4040.18 173.174C3225.13 186.449 3273.75 176.026 2782.49 173.174Z"
                  fill="#6BAA44"
                />
                <path
                  d="M1664.39 1438.48C1674.43 1422.82 1692.33 1414.02 1710.86 1415.64L4327.71 1645L6378.59 1618.4C6397.64 1618.16 6415.18 1628.76 6423.81 1645.75L6513.44 1822.12C6530.19 1855.07 6506.62 1894.18 6469.65 1894.76L4330.71 1928.5L1583.49 1700.86C1545.94 1697.75 1525.19 1655.79 1545.52 1624.06L1664.39 1438.48Z"
                  fill="url(#paint0_linear_2113_525)"
                />
                <g id={"Title_G"}>
                  <path
                    d="M6387.91 1784.6C6364.87 1784.6 6351.27 1772.2 6351.27 1755.08C6351.27 1738.84 6363.59 1725.4 6390.15 1725.4C6401.35 1725.4 6409.83 1727.8 6415.91 1730.52V1746.68C6410.79 1744.28 6403.35 1742.36 6394.63 1742.36C6377.83 1742.36 6372.15 1748.84 6372.15 1755.88C6372.15 1763.56 6378.47 1768.52 6388.95 1768.52C6396.07 1768.52 6400.87 1766.36 6402.79 1762.28H6379.67L6383.03 1748.92H6420.95V1756.6C6420.95 1775.24 6407.91 1784.6 6387.91 1784.6Z"
                    fill={String(
                      activeGolfSeatType.Regular === true ||
                        activeGolfSeatType.Deluxe === true ||
                        activeGolfSeatType.Premium === true
                        ? defaultGolfSvgConfig.title.activeColor
                        : defaultGolfSvgConfig.title.inactiveColor
                    )}
                  />
                  <path
                    d="M1690.91 1596.6C1667.87 1596.6 1654.27 1584.2 1654.27 1567.08C1654.27 1550.84 1666.59 1537.4 1693.15 1537.4C1704.35 1537.4 1712.83 1539.8 1718.91 1542.52V1558.68C1713.79 1556.28 1706.35 1554.36 1697.63 1554.36C1680.83 1554.36 1675.15 1560.84 1675.15 1567.88C1675.15 1575.56 1681.47 1580.52 1691.95 1580.52C1699.07 1580.52 1703.87 1578.36 1705.79 1574.28H1682.67L1686.03 1560.92H1723.95V1568.6C1723.95 1587.24 1710.91 1596.6 1690.91 1596.6Z"
                    fill={String(
                      activeGolfSeatType.Regular === true ||
                        activeGolfSeatType.Deluxe === true ||
                        activeGolfSeatType.Premium === true
                        ? defaultGolfSvgConfig.title.activeColor
                        : defaultGolfSvgConfig.title.inactiveColor
                    )}
                  />
                </g>
                <path
                  d="M1585.57 1762.39C1594.71 1744.05 1614.12 1733.14 1634.55 1734.87L4337.71 1964L6438.44 1927.54C6457.57 1927.21 6475.22 1937.83 6483.88 1954.88L6573.43 2131.09C6590.18 2164.05 6566.59 2203.17 6529.62 2203.74L4340.71 2237.5L1534.65 2018.76C1499.3 2016.01 1477.98 1978.35 1493.79 1946.62L1585.57 1762.39Z"
                  fill="url(#paint1_linear_2113_525)"
                />
                <g id={"Title_LG"}>
                  <path
                    d="M1609.99 1904V1848H1629.99V1888.24H1661.43V1904H1609.99ZM1700.4 1905.6C1677.36 1905.6 1663.76 1893.2 1663.76 1876.08C1663.76 1859.84 1676.08 1846.4 1702.64 1846.4C1713.84 1846.4 1722.32 1848.8 1728.4 1851.52V1867.68C1723.28 1865.28 1715.84 1863.36 1707.12 1863.36C1690.32 1863.36 1684.64 1869.84 1684.64 1876.88C1684.64 1884.56 1690.96 1889.52 1701.44 1889.52C1708.56 1889.52 1713.36 1887.36 1715.28 1883.28H1692.16L1695.52 1869.92H1733.44V1877.6C1733.44 1896.24 1720.4 1905.6 1700.4 1905.6Z"
                    fill={String(
                      activeGolfSeatType.Regular === true
                        ? defaultGolfSvgConfig.title.activeColor
                        : defaultGolfSvgConfig.title.inactiveColor
                    )}
                  />
                  <path
                    d="M6349.99 2090V2034H6369.99V2074.24H6401.43V2090H6349.99ZM6440.4 2091.6C6417.36 2091.6 6403.76 2079.2 6403.76 2062.08C6403.76 2045.84 6416.08 2032.4 6442.64 2032.4C6453.84 2032.4 6462.32 2034.8 6468.4 2037.52V2053.68C6463.28 2051.28 6455.84 2049.36 6447.12 2049.36C6430.32 2049.36 6424.64 2055.84 6424.64 2062.88C6424.64 2070.56 6430.96 2075.52 6441.44 2075.52C6448.56 2075.52 6453.36 2073.36 6455.28 2069.28H6432.16L6435.52 2055.92H6473.44V2063.6C6473.44 2082.24 6460.4 2091.6 6440.4 2091.6Z"
                    fill={String(
                      activeGolfSeatType.Regular === true
                        ? defaultGolfSvgConfig.title.activeColor
                        : defaultGolfSvgConfig.title.inactiveColor
                    )}
                  />
                </g>
                <g id={"Title_OpenBay_01"}>
                  <path
                    d="M504.04 1958.6C481.64 1958.6 467.56 1945.32 467.56 1928.68C467.56 1911.64 481.64 1899.4 504.12 1899.4C526.36 1899.4 540.52 1911.64 540.52 1928.68C540.52 1945.4 526.36 1958.6 504.04 1958.6ZM504.04 1942.36C513.16 1942.36 519 1936.92 519 1928.84C519 1920.84 513.16 1915.64 504.04 1915.64C494.84 1915.64 489 1920.84 489 1928.84C489 1936.92 494.84 1942.36 504.04 1942.36ZM548.327 1957V1901H587.047C601.847 1901 609.287 1909.24 609.287 1921.56C609.287 1933.72 600.727 1942.44 583.927 1942.44H568.327V1957H548.327ZM578.727 1929.72C585.287 1929.72 588.807 1926.84 588.807 1922.12C588.807 1917.56 585.607 1914.76 579.847 1914.76H568.327V1929.72H578.727ZM635.983 1942.76H668.143V1957H615.983V1901H667.903V1915.24H635.983V1921.88H666.303V1935.48H635.983V1942.76ZM677.311 1957V1901H703.631L722.591 1935.8V1901H740.271V1957H714.751L695.071 1921.64V1957H677.311ZM771.764 1957V1901H808.324C823.124 1901 830.564 1907.16 830.564 1915.16C830.564 1920.68 827.284 1924.6 820.964 1926.12C828.964 1927.88 832.724 1933.08 832.724 1940.04C832.724 1949.72 825.044 1957 807.204 1957H771.764ZM802.964 1943.96C808.644 1943.96 811.204 1942.12 811.204 1938.44C811.204 1935.24 808.804 1933.88 803.524 1933.88H791.364V1943.96H802.964ZM791.364 1923H802.884C807.204 1923 809.284 1921.72 809.284 1918.92C809.284 1915.64 807.364 1914.04 802.724 1914.04H791.364V1923ZM833.283 1957L853.043 1901H881.683L901.283 1957H880.963L877.523 1946.28H856.483L853.043 1957H833.283ZM860.163 1935.08H873.923L867.043 1913.72L860.163 1935.08ZM916.734 1957V1938.36L892.574 1901H914.974L927.454 1921.56L940.174 1901H962.654L938.334 1938.36V1957H916.734Z"
                    fill={String(
                      activeGolfSeatType.Open === true
                        ? defaultGolfSvgConfig.title.activeColor
                        : defaultGolfSvgConfig.title.inactiveColor
                    )}
                  />
                </g>
                {golfLayoutConfig ? (
                  <>
                    {golfLayoutConfig.map((golfSeatConfig) => {
                      return (
                        <g
                          id={golfSeatConfig.name}
                          key={golfSeatConfig.name}
                          onClick={() => {
                            if (
                              golfSeatData?.[golfSeatConfig.name]?.active ===
                              true
                            ) {
                              if (selectedSeat !== golfSeatConfig.name) {
                                setSelectedSeat(golfSeatConfig.name);
                              }
                              if (selectedFunc) {
                                selectedFunc({
                                  selectedSeat: golfSeatConfig.name,
                                  data: golfSeatData[golfSeatConfig.name].data,
                                });
                              }
                            }
                          }}
                        >
                          {golfSeatConfig.svgConfig.map((config, index) => {
                            return (
                              <React.Fragment
                                key={`${golfSeatConfig.name}_${index}`}
                              >
                                {config?.activeStroke &&
                                golfSeatDataKeyList.length > 0 &&
                                golfSeatDataKeyList.includes(
                                  golfSeatConfig.name
                                ) === true &&
                                golfSeatData &&
                                golfSeatData[golfSeatConfig.name]?.active ===
                                  true ? (
                                  <path
                                    d={config.path}
                                    fill={String(
                                      golfSeatDataKeyList.length > 0 &&
                                        golfSeatDataKeyList.includes(
                                          golfSeatConfig.name
                                        ) === true
                                        ? golfSeatData &&
                                          golfSeatData[golfSeatConfig.name]
                                            .active === true
                                          ? golfSeatConfig.name === selectedSeat
                                            ? config?.selectedColor ??
                                              defaultGolfSvgConfig[
                                                golfSeatConfig.type
                                              ].selectedColor[index]
                                            : config?.activeColor ??
                                              defaultGolfSvgConfig[
                                                golfSeatConfig.type
                                              ].activeColor[index]
                                          : config?.inactiveColor ??
                                            defaultGolfSvgConfig[
                                              golfSeatConfig.type
                                            ].inactiveColor[index]
                                        : config?.inactiveColor ??
                                            defaultGolfSvgConfig[
                                              golfSeatConfig.type
                                            ].inactiveColor[index]
                                    )}
                                    stroke={config.activeStroke.color}
                                    strokeWidth={config.activeStroke.width}
                                    strokeLinejoin={
                                      config.activeStroke.lineJoin as
                                        | "miter"
                                        | "round"
                                        | "bevel"
                                        | "inherit"
                                    }
                                  ></path>
                                ) : (
                                  <path
                                    d={config.path}
                                    fill={String(
                                      golfSeatDataKeyList.length > 0 &&
                                        golfSeatDataKeyList.includes(
                                          golfSeatConfig.name
                                        ) === true
                                        ? golfSeatData &&
                                          golfSeatData[golfSeatConfig.name]
                                            ?.active === true
                                          ? golfSeatConfig.name === selectedSeat
                                            ? config?.selectedColor ??
                                              defaultGolfSvgConfig[
                                                golfSeatConfig.type
                                              ].selectedColor[index]
                                            : config?.activeColor ??
                                              defaultGolfSvgConfig[
                                                golfSeatConfig.type
                                              ].activeColor[index]
                                          : config?.inactiveColor ??
                                            defaultGolfSvgConfig[
                                              golfSeatConfig.type
                                            ].inactiveColor[index]
                                        : config?.inactiveColor ??
                                            defaultGolfSvgConfig[
                                              golfSeatConfig.type
                                            ].inactiveColor[index]
                                    )}
                                  ></path>
                                )}
                              </React.Fragment>
                            );
                          })}
                        </g>
                      );
                    })}
                  </>
                ) : (
                  <></>
                )}
                <defs>
                  <linearGradient
                    id="paint0_linear_2113_525"
                    x1="4025.21"
                    y1="1413"
                    x2="4025.21"
                    y2="1928.5"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#B3B3B3" />
                    <stop offset="1" stopColor="#D9D9D9" />
                  </linearGradient>
                  <linearGradient
                    id="paint1_linear_2113_525"
                    x1="4035.21"
                    y1="1732"
                    x2="4035.21"
                    y2="2237.5"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#B3B3B3" />
                    <stop offset="1" stopColor="#D9D9D9" />
                  </linearGradient>
                </defs>
              </svg>
            </TransformComponent>
          </TransformWrapper>
        </div>
      </div>
    </>
  );
}
