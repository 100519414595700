import { instance } from ".";
import config from "../../config";

export async function requestPhoneNumberOTP({ mobile, lang }) {
  console.log(mobile, lang);
  const res = await instance.post(
    "/v1/app/otp/mobile",
    {
      mobile: mobile,
      lang: lang,
    },
    {
      headers: {
        "x-api-key": config.X_API_KEY,
      },
    }
  );
  console.log("Request phone number otp: ", res);
  return res.data;
}

export async function verifyPhoneNumberOTP({ mobile, otp }) {
  const res = await instance.post("/v1/app/otp/mobile/verify", {
    mobile: mobile,
    otp: otp,
  });

  return res.data;
}
