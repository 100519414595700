import { Grid } from "@mui/material";
import { GolfBay, IFacility } from "common/models/Facility";
import DropdownSelect from "components/common/Select/DropdownSelect";
import Space from "components/common/Space";
import Typography from "components/common/Typography";
import Styled from "pages/FacilityScreen/Styled";

import IconRemark from "assets/ic-orangeCircleRemark.svg";
import theme from "utils/theme";
import { useTranslation } from "react-i18next";
import GolfLayoutComponent from "./golfmap/GolfLayoutComponent";
import { useMemo } from "react";

type GenericVenuePickerProps = {
  facility: IFacility;
  venueList?: any;
  onClickRemark: (isOpened: boolean) => void;
  selectedVenueId?: string;
  setSelectedVenue: (venue: string) => void;
  venueOptions: { label: string; value: string }[];
};

type GolfVenuePickerProps = {
  definedBays: GolfBay[];
  selectedBay: string;
  onClickRemark: (isOpened: boolean) => void;
  onSelectBayFilter: (refNum: string) => void;
  shouldDisplayVenuePicker: boolean;
};

type VenuePickerProps = GenericVenuePickerProps & GolfVenuePickerProps;

type VenuePickerPropsByType<IsGolf extends boolean> = IsGolf extends true
  ? GenericVenuePickerProps & GolfVenuePickerProps
  : GenericVenuePickerProps;

type GolfVenuePickerProps2 = VenuePickerPropsByType<true>;
type OtherVenuePickerProps = VenuePickerPropsByType<false>;

/**
 * if the facility is golf with some specified name, it will render the golf bay map
 *
 * otherwise will show the dropdown select
 * @param props
 * @returns
 */
const VenuePicker = (props: VenuePickerProps) => {
  const {
    venueList,
    facility,
    selectedBay,
    onClickRemark,
    selectedVenueId: selectedVenue,
    setSelectedVenue,
    venueOptions,
    onSelectBayFilter: onSelectFilter,
    definedBays,
    shouldDisplayVenuePicker,
  } = props;
  const { t } = useTranslation("booking");
  const isGolf = facility?.isGolf && facility.isGolf();

  const onBayFilterClick = (bay: GolfBay) => {
    onSelectFilter(bay._id);
  };

  // useEffect(() => {
  //   if (isGolf) {
  //     if (definedBays?.length > 0 && facility.isShowRefNum) {
  //       getVenueListWithRefNumber(definedBays[0]?._id);
  //       setFilter(bayOptions.find((item) => item._id === definedBays[0]?._id));
  //     } else {
  //       getVenueList();
  //     }
  //   }
  // }, []);

  const _renderGolfBayBySelect = () => {
    if (!facility?.isShowRefNum) {
      return null;
    }
    return (
      <div className="flex mt-[20px]">
        <div className="flex flex-1 gap-2 flex-wrap">
          {definedBays?.map((bay, idx) => (
            <Grid item xs="auto" key={idx}>
              <Styled.RedBordedLabel
                onClick={() => onBayFilterClick(bay)}
                selected={bay?._id === selectedBay}
              >
                <Typography
                  color={bay?._id === selectedBay ? theme.white : theme.orange}
                >
                  {bay?.name}
                </Typography>
              </Styled.RedBordedLabel>
            </Grid>
          ))}
        </div>
        <img
          src={IconRemark}
          onClick={() => onClickRemark(true)}
          className="hidden md:block cursor-pointer"
        />
      </div>
    );
  };

  const _renderGolfBayByMap = () => {
    return (
      <div className="w-full h-full mt-[20px] aspect-[4/3] overflow-hidden">
        <GolfLayoutComponent
          golfSeatDataProps={venueList}
          selectedFunc={({ selectedSeat, data }) => {
            setSelectedVenue(data?._id);
          }}
        />
      </div>
    );
  };

  const shouldShowDropdown = useMemo(() => {
    const isGolfOldLayout = isGolf && !facility?.shouldUseGolfMapLayout;
    const defaultLayout = !isGolf;
    return shouldDisplayVenuePicker && (isGolfOldLayout || defaultLayout);
  }, [isGolf, facility, shouldDisplayVenuePicker]);

  return (
    <>
      <Typography fontWeight="500" className="flex-1">
        {facility?.shouldUseGolfMapLayout
          ? t("facilityDetail.selectGolfCourtInstruction")
          : t("facilityDetail.selectCourtInstruction")}
      </Typography>

      {isGolf &&
        (facility?.shouldUseGolfMapLayout
          ? _renderGolfBayByMap()
          : _renderGolfBayBySelect())}

      <Space size="20px" />

      {shouldShowDropdown && (
        <DropdownSelect
          options={venueOptions}
          value={selectedVenue ?? ""}
          placeholder={t("common:dropdownPlaceholder")}
          onChange={(newItem) => setSelectedVenue(newItem)}
          className="max-w-[420px]"
        />
      )}
    </>
  );
};
export default VenuePicker;
